import { createSelector } from "reselect";

const selectCalendar = state => state.calendar;

export const selectCalendarActivities = createSelector(
  [selectCalendar],
  calendar => calendar.activities
);

export const selectIsCalendarFetching = createSelector(
  [selectCalendar],
  calendar => calendar.isFetching
);

export const selectIsCalenderLoaded = createSelector(
  [selectCalendar],
  calendar => {
    return !!calendar.activities;
  }
);
