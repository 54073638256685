import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { createStructuredSelector } from "reselect";

import Card from "react-bootstrap/Card";
import StaffOnActivityTypeForWeekForm from "../../components/forms/reports/staff-on-activity-type-for-week.component";

import {
  selectErrorMessage,
  selectMessage
} from "../../redux/message/message.selectors";
import { clearMessage } from "../../redux/message/message.actions";

class ReportsPage extends Component {
  componentDidUpdate() {
    const { message, errorMessage, clearMessage } = this.props;
    if (message) {
      toast.success(message);
      clearMessage();
    }

    if (errorMessage) {
      toast.success(errorMessage);
      clearMessage();
    }
  }
  render() {
    return (
      <Card border="primary" style={{ width: "22em" }}>
        <Card.Body>
          <Card.Title>Rostered Staff Report</Card.Title>
          <Card.Text>
            This report will email all addresses in the Recipients field a table
            of all staff working on the selected activity types for the week of
            the selected date.
          </Card.Text>
          <StaffOnActivityTypeForWeekForm initialValues={{ types: [] }} />
        </Card.Body>
      </Card>
    );
  }
}

const mapStatToProps = createStructuredSelector({
  message: selectMessage,
  errorMessage: selectErrorMessage
});

const mapDispatchToProps = dispatch => ({
  clearMessage: () => dispatch(clearMessage())
});

export default connect(mapStatToProps, mapDispatchToProps)(ReportsPage);
