import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import TextInput from "../../form-inputs/text-input.component";
import Alert from "react-bootstrap/Alert";

import {
  selectErrorMessage,
  selectMessage
} from "../../../redux/user/user.selectors";

class ResetPasswordForm extends Component {
  render() {
    const { handleSubmit, error, message } = this.props;
    return (
      <Fragment>
        {error ? <Alert variant="danger">{error}</Alert> : null}
        {message ? (
          <Alert variant="success">
            {message}. <Alert.Link href="/signin">Click here</Alert.Link> To
            login
          </Alert>
        ) : null}
        <Form onSubmit={handleSubmit}>
          <Field
            name="new_password"
            component={TextInput}
            type="password"
            placeholder="New Password"
          />
          <Field
            name="conf_password"
            component={TextInput}
            type="password"
            placeholder="Confirm password"
          />

          <Button type="submit" label="submit">
            Submit
          </Button>
        </Form>
      </Fragment>
    );
  }
}

const validate = formValues => {
  const errors = {};
  const { new_password, conf_password } = formValues;

  const requiredFields = ["new_password", "conf_password"];
  requiredFields.forEach(field => {
    if (!formValues[field]) {
      errors[field] = "Required";
    }
  });

  if (new_password !== conf_password) {
    errors.conf_password = "Does not match";
  }

  return errors;
};

const mapStateToProps = createStructuredSelector({
  error: selectErrorMessage,
  message: selectMessage
});

ResetPasswordForm = reduxForm({
  form: "forgotPassword",
  validate
})(ResetPasswordForm);

export default connect(mapStateToProps)(ResetPasswordForm);
