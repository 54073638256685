import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { getShortFormattedDate } from "../../utils/date.utils";
import { createStructuredSelector } from "reselect";

import SignInForm from "../../components/forms/sign-in-form/sign-in-form.component";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";

import { signInAsync } from "../../redux/user/user.actions";
import {
  selectCurrentUser,
  selectToken
} from "../../redux/user/user.selectors";
import { selectErrorMessage } from "../../redux/message/message.selectors";

const SignIn = ({ currentUser, signInAsync, errorMessage, token }) => {
  const submit = values => {
    signInAsync(values);
  };

  if (currentUser.id) {
    if (currentUser.defaultPage === 1) {
      return <Redirect to={`/dashboard/${currentUser.companyStaffId}`} />;
    } else {
      return (
        <Redirect
          to={`/roster/${getShortFormattedDate(new Date())}/${
            currentUser.companyId
          }`}
        />
      );
    }
  }
  return (
    <Container>
      <h1>Login</h1>
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <SignInForm onSubmit={submit} />
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  errorMessage: selectErrorMessage,
  token: selectToken
});

const mapDispatchToProps = dispatch => ({
  signInAsync: values => dispatch(signInAsync(values))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
