import styled, { css } from "styled-components";

const igniteSectionStyles = css`
  margin-top: 15px;
  background: rgba(230, 230, 230, 0.7);
  border-left-width: 3px;
  border-left-style: solid;
`;

const getIgniteStyles = () => {
  return igniteSectionStyles;
};

export const StyledSection = styled.section`
  padding: 8px 5px;
  margin: 0px;
  width: 100%;
  font-size: 12px;
  position: relative;
  text-align: center;
  pointer-events: none;
  ${getIgniteStyles}
`;
