import { listDataActionTypes } from './list-data.types';
import { getShortFormattedDate } from '../../utils/date.utils';

const INITIAL_STATE = {
  currentDate: getShortFormattedDate(new Date()),
  staff: {},
  activityTypes: {},
  clients: {},
  kits: {},
  licenses: {},
  trucks: {},
  fullStaffList: {},
  roles: {},
  venues: {},
  togs: {},
  defaultPages: [
    { id: 0, name: 'Roster' },
    { id: 1, name: 'Dashboard' },
  ],
  isFetching: false,
  refresh: false,
};

const listDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case listDataActionTypes.SET_REFRESH_FALSE:
      return { ...state, refresh: false };

    case listDataActionTypes.FETCH_LIST_DATA_START:
      return { ...state, isFetching: true };

    case listDataActionTypes.FETCH_LIST_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      };

    case listDataActionTypes.FETCH_LIST_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case listDataActionTypes.ADD_STAFF_MEMBER_START:
      return { ...state, isFetching: true };

    case listDataActionTypes.ADD_STAFF_MEMBER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        refresh: true,
        staff: action.payload,
      };

    case listDataActionTypes.ADD_STAFF_MEMBER_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case listDataActionTypes.ADD_ACTIVITY_TYPE_START:
      return { ...state, isFetching: true };

    case listDataActionTypes.ADD_ACTIVITY_TYPE_SUCCESS:
      return {
        ...state,
        refresh: true,
        isFetching: false,
        activityTypes: action.payload,
      };

    case listDataActionTypes.ADD_ACTIVITY_TYPE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case listDataActionTypes.ADD_ACTIVITY_ROLE_START:
      return { ...state, isFetching: true };

    case listDataActionTypes.ADD_ACTIVITY_ROLE_SUCCESS:
      return {
        ...state,
        refresh: true,
        isFetching: false,
        roles: action.payload,
      };

    case listDataActionTypes.ADD_ACTIVITY_ROLE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case listDataActionTypes.ADD_CLIENT_START:
      return { ...state, isFetching: true };

    case listDataActionTypes.ADD_CLIENT_SUCCESS:
      return {
        ...state,
        refresh: true,
        isFetching: false,
        clients: action.payload,
      };

    case listDataActionTypes.ADD_CLIENT_FAILURE:
      return {
        ...state,
        refresh: true,
        isFetching: false,
      };

    case listDataActionTypes.ADD_KIT_START:
      return { ...state, isFetching: true };

    case listDataActionTypes.ADD_KIT_SUCCESS:
      return {
        ...state,
        refresh: true,
        isFetching: false,
        kits: action.payload,
      };

    case listDataActionTypes.ADD_KIT_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case listDataActionTypes.ADD_LICENSE_START:
      return { ...state, isFetching: true };

    case listDataActionTypes.ADD_LICENSE_SUCCESS:
      return {
        ...state,
        refresh: true,
        isFetching: false,
        licenses: action.payload,
      };

    case listDataActionTypes.ADD_LICENSE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case listDataActionTypes.ADD_TRUCK_START:
      return { ...state, isFetching: true };

    case listDataActionTypes.ADD_TRUCK_SUCCESS:
      return {
        ...state,
        refresh: true,
        isFetching: false,
        trucks: action.payload,
      };

    case listDataActionTypes.ADD_TRUCK_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case listDataActionTypes.ADD_VENUE_START:
      return { ...state, isFetching: true };

    case listDataActionTypes.ADD_VENUE_SUCCESS:
      return {
        ...state,
        refresh: true,
        isFetching: false,
        venues: action.payload,
      };

    case listDataActionTypes.ADD_VENUE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case listDataActionTypes.CHANGE_CURRENT_DATE:
      return { ...state, currentDate: action.payload };

    case listDataActionTypes.GET_ALL_TOGS_START:
      return { ...state, isFetching: true };

    case listDataActionTypes.GET_ALL_TOGS_SUCCESS:
      return {
        ...state,
        refresh: true,
        isFetching: false,
        togs: action.payload,
      };

    case listDataActionTypes.GET_ALL_TOGS_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
};

export default listDataReducer;
