import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../redux/user/user.selectors";

const PrivateRoute = ({ component, currentUser, ...rest }) => {
  let ComponentToRender = component;

  return (
    <Route
      {...rest}
      render={props =>
        currentUser.id ? (
          <ComponentToRender {...props} />
        ) : (
          <Redirect to={{ pathname: "/" }} />
        )
      }
    />
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

export default withRouter(connect(mapStateToProps)(PrivateRoute));
