import React from "react";
import ReactDOM from "react-dom";

import Modal from "react-bootstrap/Modal";
import TogBookingForm from "../../forms/tog-booking-form/tog-booking-form.component";

const TogBookingModal = props => {
  const { tog } = props;
  return ReactDOM.createPortal(
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Tog {tog.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TogBookingForm onHide={props.onHide} initialValues={tog} />
      </Modal.Body>
    </Modal>,
    document.querySelector("#modal")
  );
};

export default TogBookingModal;
