import React, { Component, Fragment } from 'react';
import uuid from 'uuid';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import { timeFromSeconds } from '../../../utils/date.utils';

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import TogBookingModal from '../../modals/tog/tog-booking.component';

import {
  selectStaff,
  selectTogs,
  selectRefresh,
} from '../../../redux/list-data/list-data.selectors';
import {
  selectErrorMessage,
  selectMessage,
} from '../../../redux/message/message.selectors';
import { setRefreshFalse } from '../../../redux/list-data/list-data.actions';
import { clearMessage } from '../../../redux/message/message.actions';

class TogBookingTable extends Component {
  modalClose = () => this.setState({ modalShow: false });
  constructor() {
    super();
    this.state = {
      modalShow: false,
      togs: [],
      tog: {},
    };
  }

  componentDidMount() {
    const { togs } = this.props;
    this.setState({ togs: togs });
  }

  componentDidUpdate() {
    const {
      needsRefresh,
      setRefreshFalse,
      togs,
      message,
      errorMessage,
      clearMessage,
    } = this.props;

    if (needsRefresh) {
      this.setState({
        togs: togs,
      });
      setRefreshFalse();
    }
    if (message) {
      toast.success(message);
      clearMessage();
    }
    if (errorMessage) {
      toast.error(errorMessage);
      clearMessage();
    }
  }

  renderTogBookingModal = () => (
    <TogBookingModal
      show={this.state.modalShow}
      onHide={this.modalClose}
      tog={this.state.tog}
    />
  );
  render() {
    const { staff } = this.props;
    const { togs } = this.state;

    return (
      <Fragment>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>In Use by</th>
              <th>Reserved until</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Object.values(togs).map(tog => {
              return (
                <tr key={uuid.v4()}>
                  <td>{tog.name}</td>
                  <td>
                    {tog.in_use_by
                      ? `${staff[tog.in_use_by].first_name} ${
                          staff[tog.in_use_by].last_name
                        }`
                      : 'Available'}
                  </td>
                  <td>
                    {tog.in_use_to
                      ? timeFromSeconds(tog.in_use_to)
                      : 'Not Booked'}
                  </td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() =>
                        this.setState({
                          modalShow: true,
                          tog: tog,
                        })
                      }
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {this.renderTogBookingModal()}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  togs: selectTogs,
  staff: selectStaff,
  needsRefresh: selectRefresh,
  message: selectMessage,
  errorMessage: selectErrorMessage,
});

const mapDispatchToProps = dispatch => ({
  setRefreshFalse: () => dispatch(setRefreshFalse()),
  clearMessage: () => dispatch(clearMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TogBookingTable);
