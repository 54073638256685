import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Switch, Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-virtualized/styles.css';

import Container from 'react-bootstrap/Container';
import SignIn from './pages/sign-in/sign-in.component';
import RosterPage from './pages/roster/roster.component';
import Header from './components/header/header.component';
import EditActivityPage from './pages/edit-activity-page/edit-activity-page.component';
import AddActivityPage from './pages/add-activity-page/add-activity-page.component';
import CopyActivityPage from './pages/copy-activity-page/copy-activity-page.component';
import DashboardPage from './pages/dashboard/dashboard-page.component';
import StaffListPage from './pages/staff-list/staff-list.component';
import ActivityTypesListPage from './pages/activity-types-list/activity-types-list.component';
import ActivityRolesListPage from './pages/activity-roles-list/activity-roles-list.component';
import ClientsListPage from './pages/clients-list/clients-list.component';
import KitsListPage from './pages/kits-list/kits-list.component';
import LicensesListPage from './pages/license-list/license-list.component';
import TrucksListPage from './pages/truck-list/truck-list.component';
import PrivateRoute from './components/routes/private-route.component';
import AdminRoute from './components/routes/admin-route.component';
import FourOhFour from './pages/404/404-page.component';
import ForgotPasswordPage from './pages/forgot-password-page/forgot-password-page.component';
import ResetPasswordPage from './pages/reset-password-page/reset-password-page.component';
import ConfirmEmailPage from './pages/confirm-email-page/confirm-email-page.component';
import VenueListPage from './pages/venue-list/venue-list.component';
import ReportsPage from './pages/reports/perorts-page.component';
import TogUsagePage from './pages/tog-usage-page/tog-usage-page.component';

import { selectCurrentUser, selectToken } from './redux/user/user.selectors';

import {
  fetchListDataStartAsync,
  updateStaff,
} from './redux/list-data/list-data.actions';
import { selectIsListDataLoaded } from './redux/list-data/list-data.selectors';
import { signOutAsync } from './redux/user/user.actions';

toast.configure({ autoClose: 8000, draggable: false });

class App extends Component {
  componentDidMount = () => {
    const {
      fetchListDataStartAsync,
      currentUser,
      isListDataLoaded,
      updateStaff,
    } = this.props;
    const parts = window.location.pathname.split('/');
    if (
      window.location.pathname !== '/' &&
      window.location.pathname !== '/forgot_password' &&
      parts[1] !== 'reset_password'
    ) {
      fetchListDataStartAsync(currentUser.companyId);
    }
    updateStaff(currentUser.companyId);
  };

  render() {
    return (
      <Container fluid>
        <Header />

        <Switch>
          <Route exact path='/' component={SignIn} />
          <Route component={SignIn} path='/signin' exact />
          <Route component={ForgotPasswordPage} path='/forgot_password' exact />
          <Route
            component={ResetPasswordPage}
            path='/reset_password/:token'
            exact
          />
          <PrivateRoute
            component={ConfirmEmailPage}
            path='/confirmation/:token'
            exact
          />
          <PrivateRoute
            component={RosterPage}
            path='/roster/:selectedDate/:companyId'
            exact
          />
          <PrivateRoute
            component={DashboardPage}
            path='/dashboard/:company_staff_id/:date'
            exact
          />

          <PrivateRoute
            component={EditActivityPage}
            path='/edit_activity/:activityId/:from/:dashId'
            exact
          />
          <PrivateRoute component={TogUsagePage} path='/togs' exact />
          <AdminRoute
            component={AddActivityPage}
            path='/add_activity/:date/:company_staff_id'
            exact
          />
          <AdminRoute
            component={CopyActivityPage}
            path='/copy_activity/:activityId'
            exact
          />

          <AdminRoute
            component={ActivityTypesListPage}
            path='/activity_types'
            exact
          />
          <AdminRoute
            component={ActivityRolesListPage}
            path='/activity_roles'
            exact
          />
          <AdminRoute component={StaffListPage} path='/staff_list' exact />
          <AdminRoute component={ClientsListPage} path='/clients_list' exact />
          <AdminRoute component={KitsListPage} path='/kits_list' exact />
          <AdminRoute
            component={LicensesListPage}
            path='/licenses_list'
            exact
          />
          <AdminRoute component={TrucksListPage} path='/trucks_list' exact />
          <AdminRoute component={VenueListPage} path='/venues_list' exact />
          <AdminRoute component={ReportsPage} path='/reports' exact />

          <Route component={FourOhFour} />
        </Switch>
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  token: selectToken,
  isListDataLoaded: selectIsListDataLoaded,
});

const mapDispatchToProps = (dispatch) => ({
  fetchListDataStartAsync: (companyId) =>
    dispatch(fetchListDataStartAsync(companyId)),
  signOut: () => dispatch(signOutAsync()),
  updateStaff: (companyId) => dispatch(updateStaff(companyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
