import { editActivityActionTypes } from "./edit-activity-types";

const INITIAL_STATE = {
  confirmed: false,
  staff: [],
  isFetching: false,
  files: [],
  localFiles: []
};

const editActivityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case editActivityActionTypes.FETCH_EDIT_ACTIVITY_START:
      return { ...state, isFetching: true };

    case editActivityActionTypes.FETCH_EDIT_ACTIVITY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.payload
      };

    case editActivityActionTypes.FETCH_EDIT_ACTIVITY_FAILURE:
      return {
        ...state,
        isFetching: false
      };

    case editActivityActionTypes.HANDLE_ERROR:
      return {
        ...state,
        isFetching: false
      };

    case editActivityActionTypes.DELETE_ACTIVITY_STAFF_MEMBER:
      return {
        ...state,
        isFetching: false,
        staff: action.payload
      };

    case editActivityActionTypes.REPLACE_ACTIVITY_STAFF_MEMBER:
      return {
        ...state,
        isFetching: false,
        staff: action.payload
      };

    case editActivityActionTypes.TOGGLE_STAFF_CONFIRMED:
      return {
        ...state,
        isFetching: false,
        staff: action.payload
      };

    case editActivityActionTypes.UPDATE_ALL_STAFF_DIL_LENGTH:
      return {
        ...state,
        isFetching: false,
        staff: action.payload
      };

    case editActivityActionTypes.TOGGLE_ACTIVITY_CONFIRMED:
      return {
        ...state,
        confirmed: action.payload
      };

    case editActivityActionTypes.ADD_STAFF_MEMBER_TO_ACTIVITY:
      return {
        ...state,
        staff: action.payload
      };

    case editActivityActionTypes.UPDATE_ACTIVITY_START:
      return {
        ...state,
        isFetching: true
      };

    case editActivityActionTypes.UPDATE_ACTIVITY_SUCCESS:
      return { ...state, isFetching: false, message: action.payload.message };

    case editActivityActionTypes.UPDATE_ACTIVITY_FAILURE:
      return {
        ...state,
        isFetching: false
      };

    case editActivityActionTypes.SETUP_ADD_ACTIVITY:
      return action.payload;

    case editActivityActionTypes.UPDATE_FILES:
      return { ...state, files: action.payload };

    default:
      return state;
  }
};

export default editActivityReducer;
