import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import _ from "lodash";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import TextInput from "../../form-inputs/text-input.component";
import SelectInput from "../../form-inputs/select-input.component";
import MultiSelectInput from "../../form-inputs/multi-select-input.component";
import TextareaInput from "../../form-inputs/textarea-input.component";

import {
  selectActivityTypes,
  selectClients
} from "../../../redux/list-data/list-data.selectors";
import { sendRosteredStaffReport } from "../../../redux/reports/reports.actions";

class StaffOnActivityTypeForWeekForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDirty: false,
      history: []
    };
  }

  onsubmit = formValues => {
    const { sendRosteredStaffReport } = this.props;
    sendRosteredStaffReport(formValues);
  };

  render() {
    const { types, clients, handleSubmit } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.onsubmit)}>
        <Form.Row>
          <Col>
            <Field
              name="client"
              label="Client"
              placeholder="Select Client"
              component={SelectInput}
              options={Object.values(clients)}
              onChange={() => this.setState({ isDirty: true })}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Field
              name="types"
              label="Activity Type(s)"
              type="select-multi"
              component={MultiSelectInput}
              options={_.sortBy(Object.values(types), ["name"])}
              onChange={() => this.setState({ isDirty: true })}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Field
              name="date"
              label="Select Week for Report"
              type="date"
              component={TextInput}
              onChange={() => this.setState({ isDirty: true })}
              TextareaInput
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Field
              name="to"
              label="Recipients"
              component={TextareaInput}
              onChange={() => this.setState({ isDirty: true })}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Button type="submit">Send Report</Button>
          </Col>
        </Form.Row>
      </Form>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  types: selectActivityTypes,
  clients: selectClients
});

const mapDispatchToProps = dispatch => ({
  sendRosteredStaffReport: formValues =>
    dispatch(sendRosteredStaffReport(formValues))
});

const validate = formValues => {
  const errors = {};
  const requiredFields = ["client", "types", "date", "to"];
  requiredFields.forEach(field => {
    if (!formValues[field]) {
      errors[field] = "Required";
    }
  });
  if (formValues["types"]) {
    if (formValues["types"].length < 1) {
      errors["types"] = "Required";
    }
  }
  return errors;
};

const formWrapper = reduxForm({
  form: "StaffOnActivityTypeForWeekForm",
  validate,
  touchOnChange: true
})(StaffOnActivityTypeForWeekForm);

export default connect(mapStateToProps, mapDispatchToProps)(formWrapper);
