import axios from 'axios';

const rootData = JSON.parse(localStorage.getItem('persist:root'));
const userData = JSON.parse(rootData.user);
const { token } = userData.currentUser;

let baseURL = process.env.REACT_APP_DEV_API_URL; // "http://192.168.48.131:5000/api/";
if (process.env.NODE_ENV === 'production') {
  baseURL = process.env.REACT_APP_PROD_API_URL; // "https://roster.nz.ae.live/api";
}

let instance = axios.create({
  baseURL,
  responseType: 'blob',
  withCredentials: true,
  crossdomain: true,
});

// Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
  config.headers.Authorization = token ? token : '';
  return config;
});

export default instance;
