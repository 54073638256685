import React, { Component } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import TextInput from "../../form-inputs/text-input.component";
import SelectInput from "../../form-inputs/select-input.component";
import GravatarImage from "../../gravatar/gravatar.component";

import {
  selectActive,
  selectRosterRoles,
  selectPositions
} from "../../../redux/list-data/list-data.selectors";
import {
  addStaffMemberStartAsync,
  updateStaffMemberAsync
} from "../../../redux/list-data/list-data.actions";

class StaffListForm extends Component {
  onSubmit = formValues => {
    const {
      addStaffMemberStartAsync,
      onHide,
      initialValues,
      updateStaffMemberAsync
    } = this.props;

    if (
      Object.entries(initialValues).length === 0 &&
      initialValues.constructor === Object
    ) {
      addStaffMemberStartAsync(formValues);
    } else {
      updateStaffMemberAsync(formValues);
    }
    onHide();
  };

  render() {
    const {
      handleSubmit,
      active,
      rosterRoles,
      positions,
      currentFormValues,
      onHide,
      initialValues
    } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          label={
            Object.entries(initialValues).length === 0 &&
            initialValues.constructor === Object
              ? null
              : "First Name"
          }
          name="first_name"
          component={TextInput}
          type="text"
          placeholder="First Name"
        />
        <Field
          label={
            Object.entries(initialValues).length === 0 &&
            initialValues.constructor === Object
              ? null
              : "Last Name"
          }
          name="last_name"
          component={TextInput}
          type="text"
          placeholder="Last Name"
        />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: 3 }}>
            <Field
              label={
                Object.entries(initialValues).length === 0 &&
                initialValues.constructor === Object
                  ? null
                  : "Email Address"
              }
              name="email_address"
              component={TextInput}
              type="text"
              placeholder="Email Address"
            />
          </div>
          <div
            style={{
              flex: 1,
              marginTop: "20px"
            }}
          >
            {currentFormValues.email_address ? (
              currentFormValues.email_address.includes("@") ? (
                <GravatarImage
                  email={currentFormValues.email_address}
                  name={`${currentFormValues.first_name} ${currentFormValues.last_name}`}
                ></GravatarImage>
              ) : null
            ) : null}
          </div>
        </div>
        <Field
          label={
            Object.entries(initialValues).length === 0 &&
            initialValues.constructor === Object
              ? null
              : "Phone Number"
          }
          name="phone"
          component={TextInput}
          type="text"
          placeholder="Phone Number"
        />
        <Field
          label={
            Object.entries(initialValues).length === 0 &&
            initialValues.constructor === Object
              ? null
              : "Active"
          }
          name="active"
          component={SelectInput}
          options={Object.values(active)}
          placeholder="Is staff member Active?"
        ></Field>
        <Field
          label={
            Object.entries(initialValues).length === 0 &&
            initialValues.constructor === Object
              ? null
              : "Roster User Rights"
          }
          name="roster_role"
          component={SelectInput}
          options={Object.values(rosterRoles)}
          placeholder="Roster User Rights"
        ></Field>
        <Field
          label={
            Object.entries(initialValues).length === 0 &&
            initialValues.constructor === Object
              ? null
              : "Type of staff"
          }
          name="position"
          component={SelectInput}
          options={Object.values(positions)}
          placeholder="Type of staff"
        ></Field>
        <Field
          label={
            Object.entries(initialValues).length === 0 &&
            initialValues.constructor === Object
              ? null
              : "Password"
          }
          name="password"
          component={TextInput}
          type="password"
          placeholder="Password"
        ></Field>
        <br />
        <Button type="submit" label="submit">
          Submit
        </Button>{" "}
        <Button type="button" variant="danger" onClick={onHide} label="submit">
          Cancel
        </Button>
      </Form>
    );
  }
}

const validate = formValues => {
  const errors = {};
  const requiredFields = [
    "first_name",
    "last_name",
    "email_address",
    "active",
    "roster_role",
    "position"
  ];
  requiredFields.forEach(field => {
    if (!formValues[field]) {
      errors[field] = "Required";
    }
  });
  if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email_address)
  ) {
    errors.email_address = "Invalid email address";
  }

  return errors;
};

const formSelector = formValueSelector("addToStaffList");

const mapStateToProps = createStructuredSelector({
  active: selectActive,
  positions: selectPositions,
  rosterRoles: selectRosterRoles,
  currentFormValues: state =>
    formSelector(state, "email_address", "first_name", "last_name")
});

const mapDispatchToProps = dispatch => ({
  addStaffMemberStartAsync: formValues =>
    dispatch(addStaffMemberStartAsync(formValues)),
  updateStaffMemberAsync: formValues =>
    dispatch(updateStaffMemberAsync(formValues))
});

const formWrapper = reduxForm({
  form: "addToStaffList",
  validate
})(StaffListForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(formWrapper);
