import React from "react";
import ReactDOM from "react-dom";

import Modal from "react-bootstrap/Modal";
import ActivityTypeForm from "../../forms/activity-type-form/activity-type-form-component";

const ActivityTypeListModal = props => {
  const { activity } = props;
  return ReactDOM.createPortal(
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {activity.name ? "Edit" : "Add"} Activity Type
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ActivityTypeForm onHide={props.onHide} initialValues={activity} />
      </Modal.Body>
    </Modal>,
    document.querySelector("#modal")
  );
};

export default ActivityTypeListModal;
