import { listDataActionTypes } from './list-data.types';
import { setMessage, setErrorMessage } from '../message/message.actions';
import { updateDefaultPage } from '../user/user.actions';
import roster from '../../apis/roster';
import _ from 'lodash';

export const setRefreshFalse = () => ({
  type: listDataActionTypes.SET_REFRESH_FALSE,
});

export const fetchListDataStart = () => ({
  type: listDataActionTypes.FETCH_LIST_DATA_START,
});

export const fetchListDataSuccess = (data) => (dispatch) => {
  console.log(data);
  dispatch({
    type: listDataActionTypes.FETCH_LIST_DATA_SUCCESS,
    payload: data,
  });
};

export const fetchListDataFailure = (errorMessage) => (dispatch) => {
  dispatch({
    type: listDataActionTypes.FETCH_LIST_DATA_FAILURE,
  });
  dispatch(setErrorMessage(errorMessage));
};

export const addStaffMemberStart = () => ({
  type: listDataActionTypes.ADD_STAFF_MEMBER_START,
});

export const addStaffMemberSuccess = (data) => ({
  type: listDataActionTypes.ADD_STAFF_MEMBER_SUCCESS,
  payload: data,
});

export const addStaffMemberFailure = (errorMessage) => (dispatch) => {
  dispatch({
    type: listDataActionTypes.ADD_STAFF_MEMBER_FAILURE,
  });
  dispatch(setErrorMessage(errorMessage));
};

export const addActivityTypeStart = () => ({
  type: listDataActionTypes.ADD_ACTIVITY_TYPE_START,
});

export const addActivityTypeSuccess = (data) => ({
  type: listDataActionTypes.ADD_ACTIVITY_TYPE_SUCCESS,
  payload: data,
});

export const addActivityTypeFailure = (errorMessage) => (dispatch) => {
  dispatch({
    type: listDataActionTypes.ADD_ACTIVITY_TYPE_FAILURE,
  });
  dispatch(setErrorMessage(errorMessage));
};

export const addActivityRoleStart = () => ({
  type: listDataActionTypes.ADD_ACTIVITY_ROLE_START,
});

export const addActivityRoleSuccess = (data) => ({
  type: listDataActionTypes.ADD_ACTIVITY_ROLE_SUCCESS,
  payload: data,
});

export const addActivityRoleFailure = (errorMessage) => (dispatch) => {
  dispatch({
    type: listDataActionTypes.ADD_ACTIVITY_ROLE_FAILURE,
  });
  dispatch(setErrorMessage(errorMessage));
};

export const addClientStart = () => ({
  type: listDataActionTypes.ADD_CLIENT_START,
});

export const addClientSuccess = (data) => ({
  type: listDataActionTypes.ADD_CLIENT_SUCCESS,
  payload: data,
});

export const addClientFailure = (errorMessage) => (dispatch) => {
  dispatch({
    type: listDataActionTypes.ADD_CLIENT_FAILURE,
  });
  dispatch(setErrorMessage(errorMessage));
};

export const addKitStart = () => ({
  type: listDataActionTypes.ADD_KIT_START,
});

export const addKitSuccess = (data) => ({
  type: listDataActionTypes.ADD_KIT_SUCCESS,
  payload: data,
});

export const addKitFailure = (errorMessage) => (dispatch) => {
  dispatch({
    type: listDataActionTypes.ADD_KIT_FAILURE,
  });
  dispatch(setErrorMessage(errorMessage));
};

export const addLicenseStart = () => ({
  type: listDataActionTypes.ADD_LICENSE_START,
});

export const addLicenseSuccess = (data) => ({
  type: listDataActionTypes.ADD_LICENSE_SUCCESS,
  payload: data,
});

export const addLicenseFailure = (errorMessage) => (dispatch) => {
  dispatch({
    type: listDataActionTypes.ADD_LICENSE_FAILURE,
  });
  dispatch(setErrorMessage(errorMessage));
};

export const addTruckStart = () => ({
  type: listDataActionTypes.ADD_TRUCK_START,
});

export const addTruckSuccess = (data) => ({
  type: listDataActionTypes.ADD_TRUCK_SUCCESS,
  payload: data,
});

export const addTruckFailure = (errorMessage) => (dispatch) => {
  dispatch({
    type: listDataActionTypes.ADD_TRUCK_FAILURE,
  });
  dispatch(setErrorMessage(errorMessage));
};

export const addVenueStart = () => ({
  type: listDataActionTypes.ADD_VENUE_START,
});

export const addVenueSuccess = (data) => ({
  type: listDataActionTypes.ADD_VENUE_SUCCESS,
  payload: data,
});

export const addVenueFailure = (errorMessage) => (dispatch) => {
  dispatch({
    type: listDataActionTypes.ADD_VENUE_FAILURE,
  });
  dispatch(setErrorMessage(errorMessage));
};

export const changeCurrentDate = (date) => ({
  type: listDataActionTypes.CHANGE_CURRENT_DATE,
  payload: date,
});

export const fetchTogsDataStart = () => ({
  type: listDataActionTypes.GET_ALL_TOGS_START,
});

export const fetchTogsDataSSuccess = (data) => ({
  type: listDataActionTypes.GET_ALL_TOGS_SUCCESS,
  payload: data,
});

export const fetchTogsDataSFailure = (errorMessage) => (dispatch) => {
  dispatch({
    type: listDataActionTypes.GET_ALL_TOGS_FAILURE,
  });
  dispatch(setErrorMessage(errorMessage));
};

export const fetchCompanyStaff = (companyId) => {
  console.log(companyId);
  roster.get(`/companyStaff/${companyId}`).then((res) => {
    console.log(res);
    // dispatch(fetchListDataSuccess(res.data.response.staff));
  });
};

export const fetchListDataStartAsync = (companyId) => (dispatch) => {
  dispatch(fetchListDataStart());
  roster
    .get(`/setup/${companyId}`)
    .then((res) => {
      dispatch(fetchListDataSuccess(res.data.response.setupData));
    })
    .catch((error) =>
      dispatch(fetchListDataFailure(error.response.data.message)),
    );
};

export const addStaffMemberStartAsync = (data) => (dispatch, getState) => {
  dispatch(addStaffMemberStart());
  const { currentUser } = getState().user;
  const { staff } = getState().listData;
  const mappedStaff = Object.values(staff);
  data.companies_id = currentUser.companyId;
  roster
    .post('/people', data)
    .then((res) => {
      const { person_id, staff_id } = res.data.response;

      mappedStaff.push({
        active: data.active,
        companies_id: data.companies_id,
        email_address: data.email_address,
        first_name: data.first_name,
        last_name: data.last_name,
        person_id: person_id,
        phone: data.phone,
        position: data.position,
        roster_role: data.roster_role,
        staff_id: staff_id,
      });
      dispatch(addStaffMemberSuccess(_.mapKeys(mappedStaff, 'staff_id')));
      dispatch(setMessage(res.data.response.message));
    })

    .catch((error) =>
      dispatch(fetchListDataFailure(error.response.data.message)),
    );
};

export const updateStaffMemberAsync = (formValues) => (dispatch, getState) => {
  dispatch(addStaffMemberStart());

  const { currentUser } = getState().user;
  const { staff } = getState().listData;
  const mappedStaff = Object.values(staff);
  if (formValues.staff_id === currentUser.companyStaffId) {
    dispatch(updateDefaultPage(formValues.default_page));
  }
  formValues.companies_id = currentUser.companyId;
  formValues.active = Number(formValues.active);
  mappedStaff.push(formValues);
  roster
    .put(`/people/${formValues.person_id}`, formValues)
    .then((res) => {
      dispatch(addStaffMemberSuccess(_.mapKeys(mappedStaff, 'staff_id')));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) =>
      dispatch(fetchListDataFailure(error.response.data.message)),
    );
};

export const addActivityTypeAsync = (formValues) => (dispatch, getState) => {
  dispatch(addActivityTypeStart());
  const { activityTypes } = getState().listData;
  roster
    .post('/companyActivityTypes', formValues)
    .then((res) => {
      const id = res.data.response.id;
      activityTypes[id] = {
        ...formValues,
        id: id,
        default_client: formValues.default_client || null,
        default_venue: formValues.default_venue || null,
        default_truck: formValues.default_truck || null,
      };
      dispatch(addActivityTypeSuccess(activityTypes));
      dispatch(setMessage(res.data.response.message));
    })

    .catch((error) =>
      dispatch(addActivityTypeFailure(error.response.data.message)),
    );
};

export const updateActivityTypeAsync = (formValues) => (dispatch, getState) => {
  dispatch(addActivityTypeStart());
  const { activityTypes } = getState().listData;
  activityTypes[formValues.id] = formValues;
  roster
    .put(`/companyActivityTypes/${formValues.id}`, formValues)
    .then((res) => {
      dispatch(addActivityTypeSuccess(activityTypes));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) =>
      dispatch(addActivityTypeFailure(error.response.data.message)),
    );
};

export const deleteActivityTypeAsync = (typeId) => (dispatch, getState) => {
  dispatch(addActivityTypeStart());
  const { activityTypes } = getState().listData;
  const newActivitiesTypes = Object.values(activityTypes).filter((type) => {
    return type.id !== Number(typeId);
  });

  roster
    .delete(`/companyActivityTypes/${typeId}`)
    .then((res) => {
      dispatch(addActivityTypeSuccess(newActivitiesTypes));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) =>
      dispatch(addActivityTypeFailure(error.response.data.message)),
    );
};

export const addActivityRoleAsync = (formValues) => (dispatch, getState) => {
  dispatch(addActivityRoleStart());
  const { roles } = getState().listData;
  roster
    .post('/companyStaffRoles', formValues)
    .then((res) => {
      const id = res.data.response.id;
      roles[id] = {
        ...formValues,
        id: id,
      };
      dispatch(addActivityRoleSuccess(roles));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) =>
      dispatch(addActivityRoleFailure(error.response.data.message)),
    );
};

export const updateActivityRoleAsync = (formValues) => (dispatch, getState) => {
  dispatch(addActivityRoleStart());
  const { roles } = getState().listData;
  roles[formValues.id] = formValues;
  roster
    .put(`/companyStaffRoles/${formValues.id}`, formValues)
    .then((res) => {
      dispatch(addActivityRoleSuccess(roles));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) =>
      dispatch(addActivityRoleFailure(error.response.data.message)),
    );
};

export const deleteActivityRoleAsync = (typeId) => (dispatch, getState) => {
  dispatch(addActivityRoleStart());
  const { roles } = getState().listData;

  roster
    .delete(`/companyStaffRoles/${typeId}`)
    .then((res) => {
      dispatch(addActivityRoleSuccess(_.omit(roles, typeId)));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) =>
      dispatch(addActivityRoleFailure(error.response.data.message)),
    );
};

export const addClientAsync = (formValues) => (dispatch, getState) => {
  dispatch(addClientStart());
  const { clients } = getState().listData;
  roster
    .post('/clients', formValues)
    .then((res) => {
      const { id } = res.data.response;

      clients[id] = {
        ...formValues,
        id: id,
      };
      dispatch(addClientSuccess(clients));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) => dispatch(addClientFailure(error.response.data.message)));
};

export const updateClientAsync = (formValues) => (dispatch, getState) => {
  dispatch(addClientStart());
  const { clients } = getState().listData;
  clients[formValues.id] = formValues;
  roster
    .put(`/clients/${formValues.id}`, formValues)
    .then((res) => {
      dispatch(addClientSuccess(clients));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) =>
      dispatch(addActivityRoleFailure(error.response.data.message)),
    );
};

export const deleteClientAsync = (typeId, companyId) => (
  dispatch,
  getState,
) => {
  dispatch(addClientStart());
  const { clients } = getState().listData;
  roster
    .delete(`/clients/${typeId}/${companyId}`)
    .then((res) => {
      dispatch(addClientSuccess(_.omit(clients, typeId)));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) =>
      dispatch(addActivityRoleFailure(error.response.data.message)),
    );
};

export const addKitAsync = (formValues) => (dispatch, getState) => {
  dispatch(addKitStart());
  const { kits } = getState().listData;
  roster
    .post('/kits', formValues)
    .then((res) => {
      const { id } = res.data.response;

      kits[id] = {
        ...formValues,
        id: id,
      };
      dispatch(addKitSuccess(kits));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) => dispatch(addKitFailure(error.response.data.message)));
};

export const updateKitAsync = (formValues) => (dispatch, getState) => {
  dispatch(addKitStart());
  const { kits } = getState().listData;
  kits[formValues.id] = formValues;
  roster
    .put(`/kits/${formValues.id}`, formValues)
    .then((res) => {
      dispatch(addKitSuccess(kits));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) => dispatch(addKitFailure(error.response.data.message)));
};

export const deleteKitAsync = (kitId, companyId) => (dispatch, getState) => {
  dispatch(addKitStart());
  const { kits } = getState().listData;
  roster
    .delete(`/kits/${kitId}`)
    .then((res) => {
      dispatch(addKitSuccess(_.omit(kits, kitId)));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) => dispatch(addKitFailure(error.response.data.message)));
};

export const addLicenseAsync = (formValues) => (dispatch, getState) => {
  dispatch(addLicenseStart());
  const { licenses } = getState().listData;
  roster
    .post('/licenses', formValues)
    .then((res) => {
      const { id } = res.data.response;

      licenses[id] = {
        ...formValues,
        id: id,
      };
      dispatch(addLicenseSuccess(licenses));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) => dispatch(addLicenseFailure(error.response.data.message)));
};

export const updateLicenseAsync = (formValues) => (dispatch, getState) => {
  dispatch(addLicenseStart());
  const { licenses } = getState().listData;
  licenses[formValues.id] = formValues;
  roster
    .put(`/licenses/${formValues.id}`, formValues)
    .then((res) => {
      dispatch(addLicenseSuccess(licenses));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) => dispatch(addLicenseFailure(error.response.data.message)));
};

export const deleteLicenseAsync = (licenseId, companyId) => (
  dispatch,
  getState,
) => {
  dispatch(addLicenseStart());
  const { licenses } = getState().listData;
  roster
    .delete(`/licenses/${licenseId}`)
    .then((res) => {
      dispatch(addLicenseSuccess(_.omit(licenses, licenseId)));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) => dispatch(addLicenseFailure(error.response.data.message)));
};

export const addTruckAsync = (formValues) => (dispatch, getState) => {
  dispatch(addTruckStart());
  const { trucks } = getState().listData;
  roster
    .post('/trucks', formValues)
    .then((res) => {
      const { id } = res.data.response;

      trucks[id] = {
        ...formValues,
        id: id,
      };
      dispatch(addTruckSuccess(trucks));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) => dispatch(addTruckFailure(error.response.data.message)));
};

export const updateTruckAsync = (formValues) => (dispatch, getState) => {
  dispatch(addTruckStart());
  const { trucks } = getState().listData;
  trucks[formValues.id] = formValues;
  roster
    .put(`/trucks/${formValues.id}`, formValues)
    .then((res) => {
      dispatch(addTruckSuccess(trucks));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) => dispatch(addTruckFailure(error.response.data.message)));
};

export const deleteTruckAsync = (licenseId, companyId) => (
  dispatch,
  getState,
) => {
  dispatch(addTruckStart());
  const { trucks } = getState().listData;
  roster
    .delete(`/trucks/${licenseId}`)
    .then((res) => {
      dispatch(addTruckSuccess(_.omit(trucks, licenseId)));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) => dispatch(addTruckFailure(error.response.data.message)));
};

export const addVenueAsync = (formValues) => (dispatch, getState) => {
  dispatch(addVenueStart());
  const { venues } = getState().listData;
  roster
    .post('/venues', formValues)
    .then((res) => {
      const { id } = res.data.response;

      venues[id] = {
        ...formValues,
        id: id,
      };
      dispatch(addVenueSuccess(venues));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) => dispatch(addVenueFailure(error.response.data.message)));
};

export const updateVenueAsync = (formValues) => (dispatch, getState) => {
  dispatch(addVenueStart());
  const { venues } = getState().listData;
  venues[formValues.id] = formValues;
  roster
    .put(`/venues/${formValues.id}`, formValues)
    .then((res) => {
      dispatch(addVenueSuccess(venues));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) => dispatch(addVenueFailure(error.response.data.message)));
};

export const deleteVenueAsync = (venueId, companyId) => (
  dispatch,
  getState,
) => {
  dispatch(addVenueStart());
  const { venues } = getState().listData;
  roster
    .delete(`/venues/${venueId}`)
    .then((res) => {
      dispatch(addVenueSuccess(_.omit(venues, venueId)));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) => dispatch(addVenueFailure(error.response.data.message)));
};

export const fetchAllTogsAsync = () => (dispatch) => {
  dispatch(fetchTogsDataStart());

  roster
    .get('/togs')
    .then((res) => {
      dispatch(fetchTogsDataSSuccess(res.data.response.togs));
    })
    .catch((error) =>
      dispatch(fetchTogsDataSFailure(error.response.data.message)),
    );
};

export const updateTogAsync = (id, staff, time, name) => (
  dispatch,
  getState,
) => {
  dispatch(fetchTogsDataStart());
  const { togs } = getState().listData;
  const newTogs = togs;
  newTogs[id].in_use_by = staff;
  newTogs[id].in_use_to = time;

  roster
    .post(`/togs/${id}`, { id, staff, time, name })
    .then((res) => {
      dispatch(fetchTogsDataSSuccess(newTogs));
      dispatch(setMessage(res.data.response.message));
    })
    .catch((error) =>
      dispatch(fetchTogsDataSFailure(error.response.data.message)),
    );
};

export const updateStaff = (companyId) => (dispatch, getState) => {
  dispatch(fetchListDataStart);
  roster
    .get(`/setup/${companyId}/staff`)
    .then((res) => {
      dispatch(fetchListDataSuccess(res.data.response.setupData));
    })
    .catch((error) =>
      dispatch(fetchListDataFailure(error.response.data.message)),
    );
};
