import React, { Component } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import uuid from 'uuid';

import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

import { updateFiles } from '../../redux/edit-activity/edit-activity.actions';
import { selectLocalFiles } from '../../redux/edit-activity/edit-activity.selectors';
import { selectToken, selectIsAdmin } from '../../redux/user/user.selectors';
import { refreshAccessToken } from '../../redux/user/user.actions';

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
);

class UploadPond extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      files: this.props.localFiles,
    };
  }

  componentDidUpdate(prevProps) {
    const { localFiles } = this.props;
    if (localFiles !== prevProps.localFiles) {
      this.setState({ files: this.props.localFiles });
    }
  }

  componentDidMount() {
    this.setState({ files: this.props.localFiles });
  }

  render() {
    let baseURL = process.env.REACT_APP_DEV_API_URL; // "http://192.168.48.131:5000/api/";
    if (process.env.NODE_ENV === 'production') {
      baseURL = process.env.REACT_APP_PROD_API_URL; // "https://roster.nz.ae.live/api";
    }
    const { activity, updateFiles, admin, token } = this.props;

    return (
      <div className="App">
        {/* Pass FilePond properties as attributes */}
        <FilePond
          ref={ref => (this.pond = ref)}
          files={this.state.files}
          allowMultiple={true}
          maxFiles={3}
          allowReplace="true"
          server={{
            url: `${baseURL}upload/${uuid.v4()}/${
              activity.id
            }/${token}/${admin}`,
            process: { headers: { authorization: token, isadmin: admin } },
          }}
          acceptedFileTypes={[
            'image/png',
            'image/jpeg',
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/msword',
          ]}
          labelFileTypeNotAllowed={
            'Invalid file type, JPG, PNG, DOC, DOCX or PDF only'
          }
          maxFileSize="5MB"
          labelMaxFileSizeExceeded="Max file size is 5MB"
          onupdatefiles={fileItems => {
            const mappedFileItems = fileItems.map(fileItem => fileItem.file);

            updateFiles(mappedFileItems);
            this.setState({
              files: mappedFileItems,
            });
          }}
        ></FilePond>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  localFiles: selectLocalFiles,
  token: selectToken,
  admin: selectIsAdmin,
});

const mapDispatchToProps = dispatch => ({
  updateFiles: fileItems => dispatch(updateFiles(fileItems)),
  refreshAccessToken: () => dispatch(refreshAccessToken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadPond);
