import React, { Component, Fragment } from "react";
import { ContextMenuTrigger, ContextMenu, MenuItem } from "react-contextmenu";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import uuid from "uuid";
import { withRouter } from "react-router-dom";
import { faCopy, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./context-menu.scss";

import Activity from "../activity/activity.component";
import Icon from "../icon/icon.component";
import GenericModal from "../modals/generic/generic-modal.component";

import { selectIsAdmin } from "../../redux/user/user.selectors";
import {
  deleteActivity,
  deleteSingleDateFromSequenceActivity
} from "../../redux/roster/roster.actions";

class HeaderContextMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      genericModalShow: false,
      genericSequenceModalShow: false,
      deleteId: null,
      deleteDate: null
    };
  }

  handleCopyActivityClick = (e, data) => {
    const { history } = this.props;
    const { activityId } = data;
    history.push(`/copy_activity/${activityId}`);
  };

  handleEditActivityClick = (e, data) => {
    const { history } = this.props;
    const { activityId } = data;
    history.push(`/edit_activity/${activityId}`);
  };

  handleDeleteActivityClick = (e, data) => {
    const { activityId, sequence, date } = data;
    if (sequence === 1) {
      this.setState({
        genericSequenceModalShow: true,
        deleteId: activityId,
        deleteDate: date
      });
    } else {
      this.setState({ genericModalShow: true, deleteId: activityId });
    }
  };

  handleConfirmDeleteActivityClick = () => {
    this.setState({ genericModalShow: false });
    const { deleteActivity } = this.props;
    const { deleteId } = this.state;
    deleteActivity(deleteId);
    this.setState({ deleteId: null });
  };

  deleteDateFromSequence = () => {
    const { deleteId, deleteDate } = this.state;
    const { deleteSingleDateFromSequenceActivity } = this.props;
    deleteSingleDateFromSequenceActivity(deleteId, deleteDate);
  };

  render() {
    const { activity, isAdmin } = this.props;

    const menuId = uuid.v4();

    const deleteActivityModalData = {
      title: "Hold your horses!!",
      text:
        "You're about to delete an activity, are you sure you want to do that?",
      buttons: [
        {
          variant: "primary",
          text: "Yes",
          onClick: () => this.handleConfirmDeleteActivityClick()
        }
      ]
    };

    const deleteSequenceActivityModalData = {
      title: "Hold your horses!!",
      text: `This activity is part of a sequence, would you like to remove this date from the sequence, or delete the whole activity?`,

      buttons: [
        {
          variant: "primary",
          text: "This date only",
          onClick: () => this.deleteDateFromSequence()
        },
        {
          variant: "secondary",
          text: "The whole sequence",
          onClick: () => this.handleConfirmDeleteActivityClick()
        }
      ]
    };

    return (
      <Fragment>
        {!isAdmin ? (
          <Activity key={uuid.v4()} activity={activity} activityType="header" />
        ) : (
          <Fragment>
            <ContextMenuTrigger id={menuId} key={uuid.v4()}>
              <Activity
                key={uuid.v4()}
                activity={activity}
                activityType="header"
              />
            </ContextMenuTrigger>
            <ContextMenu id={menuId} key={uuid.v4()}>
              <MenuItem
                key={uuid.v4()}
                onClick={this.handleEditActivityClick}
                data={{ activityId: activity.id }}
              >
                <Icon icon={faEdit} /> Edit Activity
              </MenuItem>

              <MenuItem
                key={uuid.v4()}
                onClick={this.handleCopyActivityClick}
                data={{ activityId: activity.id }}
              >
                <Icon icon={faCopy} /> Copy Activity
              </MenuItem>
              <MenuItem
                key={uuid.v4()}
                onClick={this.handleDeleteActivityClick}
                data={{
                  activityId: activity.id,
                  sequence: activity.sequence,
                  date: activity.date
                }}
              >
                <Icon icon={faTrash} /> Delete Activity
              </MenuItem>
            </ContextMenu>
          </Fragment>
        )}
        <GenericModal
          show={this.state.genericModalShow}
          onHide={() => this.setState({ genericModalShow: false })}
          data={deleteActivityModalData}
        ></GenericModal>
        <GenericModal
          show={this.state.genericSequenceModalShow}
          onHide={() => this.setState({ genericSequenceModalShow: false })}
          data={deleteSequenceActivityModalData}
        ></GenericModal>
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isAdmin: selectIsAdmin
});

const mapDispatchToProps = dispatch => ({
  deleteActivity: deleteId => dispatch(deleteActivity(deleteId)),
  deleteSingleDateFromSequenceActivity: (activityId, date) =>
    dispatch(deleteSingleDateFromSequenceActivity(activityId, date))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HeaderContextMenu)
);
