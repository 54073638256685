import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getShortFormattedDate } from "../../utils/date.utils";
import { createStructuredSelector } from "reselect";
import "./header.styles.scss";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
// import Alert from "react-bootstrap/Alert";
import { ReactComponent as Logo } from "../../assets/logo_red.svg";

import {
  selectCurrentUser,
  selectIsAdmin,
  // selectConfirmed,
  // selectEmailAddress,
  selectCompanyStaffId
} from "../../redux/user/user.selectors";
import {
  signOutAsync,
  generateConfirmEmailToken
} from "../../redux/user/user.actions";

class Header extends Component {
  constructor() {
    super();
    this.state = { isOpen: false };
  }

  handleSignOutClick = () => {
    const { signOut } = this.props;
    signOut();
  };

  render() {
    const {
      currentUser,
      isAdmin,
      // isConfirmed,
      // generateConfirmEmailToken,
      // emailAddress,
      companyStaffId
    } = this.props;

    return (
      <Fragment>
        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="/">
            <div style={{ width: "100px", verticalAlign: "middle" }}>
              <Logo />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {currentUser.id ? (
              <Fragment>
                <Nav className="mr-auto">
                  <Nav.Link
                    active
                    href={`/roster/${getShortFormattedDate(new Date())}/${
                      currentUser.companyId
                    }`}
                  >
                    Roster
                  </Nav.Link>
                  <Nav.Link
                    active
                    href={`/dashboard/${companyStaffId}/${getShortFormattedDate(
                      new Date()
                    )}`}
                  >
                    Dashboard
                  </Nav.Link>
                  <Nav.Link active href={`/togs`}>
                    Tog Bookings
                  </Nav.Link>
                  {isAdmin ? (
                    <Fragment>
                      <Nav.Link active href="/staff_list">
                        Staff List
                      </Nav.Link>
                      <Nav.Link active href="/activity_types">
                        Activity Types
                      </Nav.Link>
                      <Nav.Link active href="/activity_roles">
                        Activity Roles
                      </Nav.Link>
                      <Nav.Link active href="/clients_list">
                        Clients
                      </Nav.Link>
                      <Nav.Link active href="/kits_list">
                        Kits
                      </Nav.Link>
                      <Nav.Link active href="/licenses_list">
                        Licenses
                      </Nav.Link>
                      <Nav.Link active href="/trucks_list">
                        Trucks
                      </Nav.Link>
                      <Nav.Link active href="/venues_list">
                        Venues
                      </Nav.Link>
                      <Nav.Link active href="/reports">
                        Reports
                      </Nav.Link>
                    </Fragment>
                  ) : null}
                </Nav>
                <Button onClick={this.handleSignOutClick}>Sign Out</Button>
              </Fragment>
            ) : null}
          </Navbar.Collapse>
        </Navbar>
        <br />

        <br />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  signOut: () => dispatch(signOutAsync()),
  generateConfirmEmailToken: () => dispatch(generateConfirmEmailToken())
});

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  isAdmin: selectIsAdmin,
  // isConfirmed: selectConfirmed,
  // emailAddress: selectEmailAddress,
  companyStaffId: selectCompanyStaffId
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
