import React, { Component } from "react";

import ClientsTable from "../../components/tables/clients-table/clients-table.components";

class ClientsListPage extends Component {
  render() {
    return <ClientsTable />;
  }
}

export default ClientsListPage;
