import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import { faPhone, faAt } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

import Icon from "../../icon/icon.component";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import TextInput from "../../form-inputs/text-input.component";
import TextInputWithIcon from "../../form-inputs/text-input-with-icon.component";
import SelectInput from "../../form-inputs/select-input.component";
import GravatarImage from "../../gravatar/gravatar.component";

import {
  selectIsAdmin,
  selectCurrentUser
} from "../../../redux/user/user.selectors";
import { updateStaffMemberAsync } from "../../../redux/list-data/list-data.actions";
import { selectDefaultPages } from "../../../redux/list-data/list-data.selectors";
import {
  selectMessage,
  selectErrorMessage
} from "../../../redux/message/message.selectors";
import { clearMessage } from "../../../redux/message/message.actions";

class DashboardPersonForm extends Component {
  onFormSubmit = formValues => {
    const { updateStaffMemberAsync } = this.props;
    updateStaffMemberAsync(formValues);
  };

  componentDidUpdate() {
    const { message, errorMessage, clearMessage } = this.props;
    if (message) {
      toast.success(message);
      clearMessage();
    }
    if (errorMessage) {
      toast.error(errorMessage);
      clearMessage();
    }
  }
  render() {
    const {
      handleSubmit,
      isAdmin,
      currentUser,
      defaultPages,
      match: { params },
      initialValues
    } = this.props;
    const { company_staff_id } = params;

    const disabled =
      isAdmin || currentUser.companyStaffId === Number(company_staff_id)
        ? false
        : true;

    return (
      <Form onSubmit={handleSubmit(this.onFormSubmit)}>
        <Form.Row>
          <Col xs={12} md={2}>
            <Field
              disabled={disabled}
              label="First Name"
              name="first_name"
              component={TextInput}
              type="text"
              placeholder="First Name"
            />
          </Col>
          <Col xs={12} md={2}>
            <Field
              disabled={disabled}
              label="Last Name"
              name="last_name"
              component={TextInput}
              type="text"
              placeholder="Last Name"
            />
          </Col>
          <Col xs={12} md={2}>
            <Field
              disabled={disabled}
              label="Phone Number"
              name="phone"
              component={TextInputWithIcon}
              type="text"
              placeholder="Phone Number"
              icon={<Icon icon={faPhone} />}
            />
          </Col>
          <Col xs={12} md={2}>
            <Field
              disabled={disabled}
              label="Email Address"
              name="email_address"
              component={TextInputWithIcon}
              type="email"
              placeholder="Email Address"
              icon={<Icon icon={faAt} />}
            />
          </Col>
          <Col xs={12} md={2}>
            <Field
              disabled={disabled}
              label="Default Page"
              name="default_page"
              component={SelectInput}
              placeholder="Default Page"
              options={Object.values(defaultPages)}
            />
          </Col>
          <Col xs={12} md={1}>
            <GravatarImage
              email={initialValues.email_address}
              name={`${initialValues.first_name} ${initialValues.last_name}`}
            ></GravatarImage>
            <div className="">
              To change your profile image, please go to{" "}
              <a href="http://www.gravatar.com" target="new">
                gravatar.com
              </a>
            </div>
          </Col>
          {isAdmin ||
          currentUser.companyStaffId === Number(company_staff_id) ? (
            <Col xs={12} md={2}>
              <Button type="submit" label="submit">
                Submit
              </Button>
            </Col>
          ) : null}
        </Form.Row>
      </Form>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isAdmin: selectIsAdmin,
  currentUser: selectCurrentUser,
  defaultPages: selectDefaultPages,
  message: selectMessage,
  errorMessage: selectErrorMessage
});

const mapDispatchToProps = dispatch => ({
  updateStaffMemberAsync: formValues =>
    dispatch(updateStaffMemberAsync(formValues)),
  clearMessage: () => dispatch(clearMessage())
});

const formWrapper = reduxForm({
  form: "dashboardPerson"
})(DashboardPersonForm);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(formWrapper)
);
