export const hexToRgb = hex => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16)
      }
    : null;
};

export const determineActivityTextColour = bgColour => {
  let r = parseInt(bgColour.substr(0, 2), 16);
  let g = parseInt(bgColour.substr(2, 2), 16);
  let b = parseInt(bgColour.substr(4, 2), 16);
  let textColour = (r * 299 + g * 587 + b * 114) / 1000;
  return textColour >= 128 ? "#000000" : "#FFFFFF";
};
