import { SettingsActionTypes } from "./settings.types";

export const setRosterHeaderVisible = bool => ({
  type: SettingsActionTypes.SET_ROSTER_HEADER_VISIBLE,
  payload: bool
});

export const setUnusedRowsVisible = bool => ({
  type: SettingsActionTypes.SET_UNUSED_VISIBLE,
  payload: bool
});

export const setSelectedDate = date => ({
  type: SettingsActionTypes.UPDATE_CURRENT_DATE,
  payload: date
});
