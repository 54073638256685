import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import TextInput from "../../form-inputs/text-input.component";

import {
  selectErrorMessage,
  selectMessage
} from "../../../redux/user/user.selectors";

class ForgotPasswordForm extends Component {
  render() {
    const { handleSubmit, message, errorMessage } = this.props;

    return (
      <Fragment>
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        {message && (
          <Alert variant="warning">
            {message}. <Alert.Link href="/signin">Click here</Alert.Link> to
            login
          </Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <Field
            name="email_address"
            component={TextInput}
            type="text"
            placeholder="Email"
          />

          <Button type="submit" label="submit">
            Submit
          </Button>
        </Form>
      </Fragment>
    );
  }
}

const validate = formValues => {
  const errors = {};
  const requiredFields = ["email"];
  requiredFields.forEach(field => {
    if (!formValues[field]) {
      errors[field] = "Required";
    }
  });
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
    errors.email = "Invalid email address";
  }

  return errors;
};

ForgotPasswordForm = reduxForm({
  form: "forgotPassword",
  validate
})(ForgotPasswordForm);

const mapStateToProps = createStructuredSelector({
  message: selectMessage,
  errorMessage: selectErrorMessage
});

export default connect(mapStateToProps)(ForgotPasswordForm);
