import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import { createStructuredSelector } from "reselect";
import { toast } from "react-toastify";

import ForgotPasswordForm from "../../components/forms/forgot-password-form/forgot-password-form.component";

import { requestRestPassword } from "../../redux/user/user.actions";
import {
  selectErrorMessage,
  selectMessage
} from "../../redux/message/message.selectors";
import { clearMessage } from "../../redux/message/message.actions";

class ForgotPasswordPage extends Component {
  submit = values => {
    const { requestRestPassword } = this.props;
    requestRestPassword(values);
  };
  componentDidUpdate() {
    const { message, errorMessage, clearMessage } = this.props;
    if (message) {
      toast.success(message);
      clearMessage();
    }

    if (errorMessage) {
      toast.error(errorMessage);
      clearMessage();
    }
  }

  render() {
    return (
      <Container>
        <h1>Forgot Password</h1>
        <ForgotPasswordForm onSubmit={this.submit} />
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  requestRestPassword: formValues => dispatch(requestRestPassword(formValues)),
  clearMessage: () => dispatch(clearMessage())
});

const mapStateToProps = createStructuredSelector({
  message: selectMessage,
  errorMessage: selectErrorMessage
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordPage);
