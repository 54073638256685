import React, { Component } from "react";
import uuid from "uuid";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import _ from "lodash";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import { selectStaff } from "../../redux/list-data/list-data.selectors";

class SelectStaffInput extends Component {
  render() {
    const {
      input,
      label,
      meta,
      placeholder,
      disabled,
      staff,
      currentstaff
    } = this.props;
    const errorClass = meta.touched && meta.error ? "is-invalid" : "";
    const labelClass = meta.touched && meta.error ? "text-danger" : "";
    const staffAlreadyAssigned = [];
    if (currentstaff) {
      Object.values(currentstaff).map(staffMember =>
        staffAlreadyAssigned.push(staffMember.company_staff_id)
      );
    }
    const filteredStaff = _.orderBy(
      _.filter(Object.values(staff), s => {
        return !_.includes(staffAlreadyAssigned, s.staff_id);
      }),
      "first_name"
    );

    return (
      <Form.Group as={Col} controlId={input.name}>
        <Form.Label className={labelClass}>{label}</Form.Label>

        <Form.Control
          {...input}
          as="select"
          className={errorClass}
          disabled={disabled}
        >
          <option>{placeholder}</option>
          {filteredStaff.map(option => {
            return (
              <option key={uuid.v4()} value={option.staff_id}>
                {option.first_name} {option.last_name}
              </option>
            );
          })}
        </Form.Control>

        {meta.touched &&
          (meta.error && <span className="text-danger">{meta.error}</span>)}
      </Form.Group>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  staff: selectStaff
});

export default connect(
  mapStateToProps,
  {}
)(SelectStaffInput);
