import React, { Fragment } from "react";
import { connect } from "react-redux";
import { ReactComponent as Pdf } from "../../assets/pdf.svg";
import { ReactComponent as Doc } from "../../assets/doc.svg";
import { ReactComponent as Png } from "../../assets/png.svg";
import { ReactComponent as Jpg } from "../../assets/jpg.svg";

import Card from "react-bootstrap/Card";

import { downloadFile } from "../../redux/edit-activity/edit-activity.actions";

const UploadedFile = ({ file, downloadFile }) => {
  const SetSVG = () => {
    const { fileExtension } = file;
    if (fileExtension === "pdf") {
      return <Pdf width="100" height="50" />;
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      return <Doc width="100" height="50" />;
    } else if (fileExtension === "png") {
      return <Png width="100" height="50" />;
    } else if (fileExtension === "jpg") {
      return <Jpg width="100" height="50" />;
    }
  };

  const handleDownloadFileClick = () => {
    downloadFile(file);
  };

  return (
    <Fragment>
      <Card
        value={file}
        onClick={handleDownloadFileClick}
        className="text-primary"
        style={{
          border: "none",
          alignItems: "center",
          alignContent: "center",
          textAlign: "center",
          cursor: "pointer"
        }}
      >
        <br />
        <SetSVG />
        {file.full_file_name}
      </Card>
    </Fragment>
  );
};

const mapDispatchToState = dispatch => ({
  downloadFile: file => dispatch(downloadFile(file))
});

export default connect(
  null,
  mapDispatchToState
)(UploadedFile);
