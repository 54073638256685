import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ReactTableContainer from "react-table-container";
import uuid from "uuid";
import _ from "lodash";
import fuzzyFilterFactory from "react-fuzzy-filter";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WithSpinner from "../../spinner/spinner.component";
import VenueListModal from "../../modals/venues-list/venues-List.component";
import GenericModal from "../../modals/generic/generic-modal.component";
import { toast } from "react-toastify";

import {
  selectErrorMessage,
  selectMessage
} from "../../../redux/message/message.selectors";
import { clearMessage } from "../../../redux/message/message.actions";
import {
  selectVenues,
  selectRefresh
} from "../../../redux/list-data/list-data.selectors";
import { selectCompanyId } from "../../../redux/user/user.selectors";
import {
  deleteVenueAsync,
  setRefreshFalse
} from "../../../redux/list-data/list-data.actions";
const { InputFilter, FilterResults } = fuzzyFilterFactory();

class TrucksTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false,
      modalDeleteShow: false,
      venues: [],
      venue: {},
      genericModalShow: false
    };
  }

  componentDidMount() {
    const { venues } = this.props;
    const truckValues = Object.values(venues);
    this.setState({
      venues: _.orderBy(truckValues, ["name"], ["asc"])
    });
  }

  componentDidUpdate() {
    const {
      needsRefresh,
      setRefreshFalse,
      venues,
      message,
      errorMessage,
      clearMessage
    } = this.props;
    const truckValues = Object.values(venues);
    if (needsRefresh) {
      this.setState({
        venues: truckValues
      });
      setRefreshFalse();
    }
    if (message) {
      toast.success(message);
      clearMessage();
    }
    if (errorMessage) {
      toast.error(errorMessage);
      clearMessage();
    }
  }
  modalClose = () => this.setState({ modalShow: false });

  renderActivityRoleModal = () => (
    <VenueListModal
      show={this.state.modalShow}
      onHide={this.modalClose}
      venue={this.state.venue}
    />
  );

  handleDeleteVenueClick = e => {
    this.setState({ genericModalShow: true, deleteId: e.target.value });
  };

  handleConfirmDeleteVenueClick = () => {
    const { deleteVenueAsync } = this.props;
    const { deleteId } = this.state;
    deleteVenueAsync(deleteId);
    this.setState({ genericModalShow: false, deleteId: null });
  };

  render() {
    const { venues } = this.state;

    if (venues.length < 1) {
      return <WithSpinner message="Loading Venues..." />;
    }

    const deleteActivityRoleModalData = {
      title: "Hold your horses!!",
      text:
        "You're about to delete a venue , are you sure you want to do that?",

      buttons: [
        {
          variant: "primary",
          text: "Yes",
          onClick: () => this.handleConfirmDeleteVenueClick()
        }
      ]
    };

    const fuseConfig = {
      caseSensitive: false,
      keys: ["name"]
    };

    return (
      <Fragment>
        <Row>
          <Col md={10}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Search</Form.Label>
              <InputFilter
                debounceTime={200}
                inputProps={{ className: "form-control" }}
              />
              <Form.Text className="text-muted">
                Enter search params above to filter the below list
              </Form.Text>
            </Form.Group>
          </Col>
          <Col style={{ alignItems: "center" }}>
            <br />
            <Button
              variant="primary"
              onClick={() => this.setState({ modalShow: true, venue: {} })}
            >
              Add Venue
            </Button>
          </Col>
        </Row>
        <ReactTableContainer width="100%" height="80vh">
          <Table striped bordered hover responsive="sm">
            <colgroup>
              <col
                span="1"
                className=""
                style={{ backgroundColor: "#FFFFFF" }}
              />
              <col
                span="1"
                className=""
                style={{ backgroundColor: "#FFFFFF" }}
              />
              <col
                span="1"
                className=""
                style={{ backgroundColor: "#FFFFFF" }}
              />
            </colgroup>
            <thead>
              <tr>
                <td>Id</td>
                <td>Name</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              <FilterResults
                items={_.orderBy(venues, ["name"], ["asc"])}
                fuseConfig={fuseConfig}
              >
                {filteredItems => {
                  return filteredItems.map(venue => {
                    return (
                      <tr key={uuid.v4()}>
                        <td>{venue.id}</td>
                        <td>
                          {venue.name}
                          {venue.city.length > 0 ? `, ${venue.city}` : null}
                        </td>
                        <td>
                          <Button
                            variant="primary"
                            onClick={() =>
                              this.setState({
                                modalShow: true,
                                modalDeleteShow: false,
                                venue: venue
                              })
                            }
                          >
                            Edit
                          </Button>{" "}
                          <Button
                            variant="danger"
                            onClick={this.handleDeleteVenueClick}
                            value={venue.id}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  });
                }}
              </FilterResults>
            </tbody>
          </Table>
        </ReactTableContainer>
        {this.renderActivityRoleModal()}
        <GenericModal
          show={this.state.genericModalShow}
          onHide={() => this.setState({ genericModalShow: false })}
          data={deleteActivityRoleModalData}
        ></GenericModal>
      </Fragment>
    );
  }
}

const mapPropsToState = createStructuredSelector({
  venues: selectVenues,
  companyId: selectCompanyId,
  needsRefresh: selectRefresh,
  message: selectMessage,
  errorMessage: selectErrorMessage
});

const mapDispatchToProps = dispatch => ({
  deleteVenueAsync: venueId => dispatch(deleteVenueAsync(venueId)),
  setRefreshFalse: () => dispatch(setRefreshFalse()),
  clearMessage: () => dispatch(clearMessage())
});

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(TrucksTable);
