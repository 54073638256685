import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import uuid from 'uuid';
import { createStructuredSelector } from 'reselect';
import {
  isDateTheSameMonth,
  getYearMonthDay,
  subAMonth,
  addAMonth,
  getShortFormattedDate,
  isItToday,
  getCalendarDayRange,
} from '../../utils/date.utils';
import './dashboard-calendar-styles.scss';
import MediaQuery from 'react-responsive';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Activity from '../activity/activity.component';

import { fetchDashboardActivitiesAsync } from '../../redux/calendar/calendar-actions';
import { selectCalendarActivities } from '../../redux/calendar/calendar-selectors';
import { selectSelectedDate } from '../../redux/settings/settings.selectors';
import { setSelectedDate } from '../../redux/settings/settings.actions';

class DashboardCalendar extends Component {
  componentDidMount() {
    const { fetchDashboardActivitiesAsync, match } = this.props;
    const { company_staff_id, date } = match.params;

    fetchDashboardActivitiesAsync(
      company_staff_id,
      getShortFormattedDate(date),
    );
  }

  subOneMonth = () => {
    const {
      fetchDashboardActivitiesAsync,
      selectedDate,
      match: {
        params: { company_staff_id },
      },
    } = this.props;
    const newDate = subAMonth(selectedDate);
    fetchDashboardActivitiesAsync(company_staff_id, newDate);
  };

  setToday = () => {
    const {
      fetchDashboardActivitiesAsync,
      match: {
        params: { company_staff_id },
      },
    } = this.props;
    const newDate = getShortFormattedDate(new Date());

    fetchDashboardActivitiesAsync(company_staff_id, newDate);
  };

  addOneMonth = () => {
    const {
      fetchDashboardActivitiesAsync,
      selectedDate,
      match: {
        params: { company_staff_id },
      },
    } = this.props;
    const newDate = addAMonth(selectedDate);
    fetchDashboardActivitiesAsync(company_staff_id, newDate);
  };

  render() {
    const dayNames = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];

    const { activities, selectedDate, match } = this.props;

    const calendarDays = getCalendarDayRange(selectedDate);

    const { month, year } = getYearMonthDay(selectedDate);
    return (
      <Fragment>
        <Row>
          <Col />
          <Col md="auto">
            <Button variant="primary" onClick={this.subOneMonth}>
              -M
            </Button>{' '}
            <Button variant="danger" onClick={this.setToday}>
              Today
            </Button>{' '}
            <Button variant="primary" onClick={this.addOneMonth}>
              +M
            </Button>
          </Col>
          <Col />
        </Row>
        <br />
        <MediaQuery maxDeviceWidth={968}>
          {isMobile => (
            <div className="">
              {isMobile ? (
                <div className="calendar-header">
                  <Row>
                    <Col sm={12}>
                      <h1>{month}</h1>
                      <p>{year}</p>
                    </Col>
                  </Row>
                  {calendarDays.length > 0
                    ? calendarDays.map(calendarDay => {
                        const { day } = getYearMonthDay(calendarDay);
                        if (
                          isDateTheSameMonth(calendarDay, selectedDate) === true
                        ) {
                          return (
                            <div
                              className={
                                isDateTheSameMonth(
                                  calendarDay,
                                  selectedDate,
                                ) === true
                                  ? 'day'
                                  : 'day day--disabled'
                              }
                              key={uuid.v4()}
                            >
                              <div
                                className={
                                  isItToday(calendarDay)
                                    ? 'circle-is-today'
                                    : 'circle'
                                }
                              >
                                <div>{day}</div>
                              </div>
                              {activities.length > 0
                                ? activities.map(activity => {
                                    activity.activity_colour = activity.colour;

                                    const activityDate = getShortFormattedDate(
                                      activity.date,
                                    );
                                    const calendarDayDate = getShortFormattedDate(
                                      calendarDay,
                                    );

                                    return activityDate === calendarDayDate ? (
                                      <Activity
                                        key={uuid.v4()}
                                        activity={activity}
                                        activityType="dash"
                                        dashboardId={
                                          match.params.company_staff_id
                                        }
                                      />
                                    ) : null;
                                  })
                                : null}
                            </div>
                          );
                        }
                        return null;
                      })
                    : null}
                </div>
              ) : null}
            </div>
          )}
        </MediaQuery>
        <MediaQuery minDeviceWidth={968}>
          {isDesktop => (
            <div className="">
              {isDesktop ? (
                <div className="calendar-container">
                  <div className="calendar-header">
                    <Row>
                      <Col sm={12}>
                        <h1> {month}</h1>
                        <p>{year}</p>
                      </Col>
                    </Row>
                  </div>
                  <div className="calendar">
                    <Fragment>
                      {dayNames.map(dayName => {
                        return (
                          <span className="day-name" key={uuid.v4()}>
                            {dayName}
                          </span>
                        );
                      })}
                      {calendarDays.length > 0
                        ? calendarDays.map(calendarDay => {
                            const { day } = getYearMonthDay(calendarDay);
                            return (
                              <div
                                className={
                                  isDateTheSameMonth(
                                    calendarDay,
                                    selectedDate,
                                  ) === true
                                    ? isItToday(calendarDay)
                                      ? 'day is-today'
                                      : 'day'
                                    : 'day day--disabled'
                                }
                                key={uuid.v4()}
                              >
                                <div
                                  className={
                                    isItToday(calendarDay)
                                      ? 'circle-is-today'
                                      : 'circle'
                                  }
                                >
                                  <div>{day}</div>
                                </div>

                                {activities.length > 0
                                  ? activities.map(activity => {
                                      activity.activity_colour =
                                        activity.colour;

                                      const activityDate = getShortFormattedDate(
                                        activity.date,
                                      );
                                      const calendarDayDate = getShortFormattedDate(
                                        calendarDay,
                                      );

                                      return activityDate ===
                                        calendarDayDate ? (
                                        <Activity
                                          key={uuid.v4()}
                                          activity={activity}
                                          activityType="dash"
                                          dashboardId={
                                            match.params.company_staff_id
                                          }
                                        />
                                      ) : null;
                                    })
                                  : null}
                              </div>
                            );
                          })
                        : null}
                    </Fragment>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </MediaQuery>
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  activities: selectCalendarActivities,
  selectedDate: selectSelectedDate,
});

const mapDispatchToProps = dispatch => ({
  fetchDashboardActivitiesAsync: (company_staff_id, selectedDate) =>
    dispatch(fetchDashboardActivitiesAsync(company_staff_id, selectedDate)),
  setSelectedDate: date => dispatch(setSelectedDate(date)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardCalendar),
);
