import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import uuid from 'uuid';
import history from '../../history';
import {
  getStartOfWeek,
  isDateInTheSameWeek,
  subAMonth,
  subAWeek,
  addAWeek,
  addAMonth,
  getShortFormattedDate,
  getFullFormattedDate,
} from '../../utils/date.utils';
import _ from 'lodash';

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import WithSpinner from '../spinner/spinner.component';
import HeaderContextMenu from '../roster-header-context-menu/roster-header-context-menu.component';

import {
  selectRosterDateRange,
  selectRosterActivities,
  selectIsRosterFetching,
} from '../../redux/roster/roster.selectors';
import {
  setDateRange,
  fetchRosterDataSinglePersonAsync,
} from '../../redux/roster/roster.actions';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { selectStaff } from '../../redux/list-data/list-data.selectors';

class RosterTableMobile extends Component {
  constructor() {
    super();
    this.state = { selectedUser: null, fullStaffList: false };
  }
  componentDidMount() {
    const {
      setDateRange,
      match,
      currentUser,
      fetchRosterDataSinglePersonAsync,
    } = this.props;
    this.setState({ selectedUser: currentUser.companyStaffId });
    const { selectedDate } = match.params;
    setDateRange(selectedDate);
    fetchRosterDataSinglePersonAsync(
      getStartOfWeek(selectedDate),
      currentUser.id,
    );
  }

  handleDateChange = (e) => {
    const {
      match: { params },
      fetchRosterDataSinglePersonAsync,
    } = this.props;
    const { selectedDate } = params;
    const { selectedUser } = this.state;
    const pickedDate = e.target.value;
    if (!isDateInTheSameWeek(pickedDate, selectedDate)) {
      history.push(`/roster/${pickedDate}/${selectedUser}`);
      fetchRosterDataSinglePersonAsync(
        getStartOfWeek(pickedDate),
        selectedUser,
      );
    }
  };

  handleDateButtonChange = (e) => {
    const {
      fetchRosterDataSinglePersonAsync,
      currentUser,
      match: {
        params: { selectedDate },
      },
    } = this.props;

    const dateType = e.target.innerHTML;

    let newDate = '';
    if (dateType === '+M') {
      newDate = addAMonth(selectedDate);
    } else if (dateType === '+W') {
      newDate = addAWeek(selectedDate);
    } else if (dateType === '-M') {
      newDate = subAMonth(selectedDate);
    } else if (dateType === '-W') {
      newDate = subAWeek(selectedDate);
    } else {
      newDate = getShortFormattedDate(new Date());
    }
    history.push(`/roster/${newDate}/${currentUser.companyId}`);
    fetchRosterDataSinglePersonAsync(getStartOfWeek(newDate), currentUser.id);
  };

  onStaffChange = (e) => {
    const value = e.currentTarget.value;

    const { fetchRosterDataSinglePersonAsync, match, staff } = this.props;
    const { person_id } = staff[value];

    fetchRosterDataSinglePersonAsync(
      getStartOfWeek(match.params.selectedDate),
      person_id,
    );
    this.setState({ selectedUser: Number(value) });
  };

  showAllJobs = () => {
    const { fetchRosterDataSinglePersonAsync, match } = this.props;
    const person_id = 0;
    fetchRosterDataSinglePersonAsync(
      getStartOfWeek(match.params.selectedDate),
      person_id,
    );
    this.setState({ selectedUser: Number(person_id) });
  };

  render() {
    const { selectedUser, fullStaffList } = this.state;
    const {
      dateRange,
      staff,
      rosterActivities,
      match: { params },
      isFetching,
    } = this.props;
    const mappedActivities = Object.values(rosterActivities);
    const { selectedDate } = params;

    if (isFetching) {
      return <WithSpinner message='Loading Roster...' />;
    }

    const staffValues = _.orderBy(
      Object.values(staff),
      ['first_name', 'last_name'],
      ['asc', 'asc'],
    );

    const lastName = selectedUser ? staff[selectedUser].last_name : null;
    const lastNameString = selectedUser
      ? lastName[lastName.length - 1] === 's'
        ? `${lastName}' Week`
        : `${lastName}'s Week`
      : null;
    return (
      <Fragment>
        <Row>
          <Col onClick={this.showAllJobs}>
            <Button variant='primary'>Show all jobs for week</Button>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Row>
              <Col>
                <Form.Control
                  type='date'
                  placeholder='Large text'
                  value={selectedDate}
                  onChange={this.handleDateChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ paddingTop: '5px' }}>
                  <Button
                    variant='primary'
                    onClick={this.handleDateButtonChange}
                  >
                    -M
                  </Button>{' '}
                  <Button
                    variant='danger'
                    onClick={this.handleDateButtonChange}
                  >
                    -W
                  </Button>{' '}
                  <Button
                    variant='danger'
                    onClick={this.handleDateButtonChange}
                  >
                    +W
                  </Button>{' '}
                  <Button
                    variant='primary'
                    onClick={this.handleDateButtonChange}
                  >
                    +M
                  </Button>{' '}
                  <Button variant='info' onClick={this.handleDateButtonChange}>
                    Today
                  </Button>
                </div>
              </Col>
            </Row>
            {!fullStaffList ? (
              <Fragment>
                <br />
                <Row>
                  <Col>
                    <Form.Control
                      as='select'
                      value={selectedUser ? selectedUser : undefined}
                      onChange={this.onStaffChange}
                    >
                      <option>Choose Staff Member...</option>
                      {staffValues.map((staffMember) => (
                        <option key={uuid.v4()} value={staffMember.staff_id}>
                          {staffMember.first_name} {staffMember.last_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
              </Fragment>
            ) : null}
          </Col>
        </Row>
        <br />
        <br />
        <h3>
          {selectedUser
            ? `${staff[selectedUser].first_name} ${lastNameString}`
            : null}
        </h3>
        <Table>
          <tbody>
            {dateRange.map((date, index) => {
              const headerExemptActionTypes =
                selectedUser === 0
                  ? [10000, 10002, 10013, 10015, 10016, 10025]
                  : [];
              return (
                <Fragment key={uuid.v4()}>
                  <tr key={uuid.v4()}>
                    <th width='12.5%' key={uuid.v4()} align='center'>
                      {getFullFormattedDate(date) ===
                      getFullFormattedDate(new Date()) ? (
                        <span key={uuid.v4()} className='text-success'>
                          {getFullFormattedDate(date)}
                        </span>
                      ) : (
                        <span>{getFullFormattedDate(date)}</span>
                      )}
                    </th>
                  </tr>
                  <tr key={uuid.v4()}>
                    <td key={uuid.v4()}>
                      {mappedActivities.map((activity) => {
                        if (
                          getShortFormattedDate(activity.date) ===
                            getShortFormattedDate(date) &&
                          !_.includes(
                            headerExemptActionTypes,
                            activity.company_activity_type,
                          )
                        ) {
                          return (
                            <HeaderContextMenu
                              activity={activity}
                              key={uuid.v4()}
                              selectedDate={selectedDate}
                              type={this.props.type}
                            />
                          );
                        } else {
                          return null;
                        }
                      })}
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setDateRange: (date) => dispatch(setDateRange(date)),
  fetchRosterDataSinglePersonAsync: (selectedDate, userId) =>
    dispatch(fetchRosterDataSinglePersonAsync(selectedDate, userId)),
});

const mapStateToProps = createStructuredSelector({
  dateRange: selectRosterDateRange,
  rosterActivities: selectRosterActivities,
  isFetching: selectIsRosterFetching,
  currentUser: selectCurrentUser,
  staff: selectStaff,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RosterTableMobile),
);
