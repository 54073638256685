import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ReactTableContainer from "react-table-container";
import uuid from "uuid";
import _ from "lodash";
import fuzzyFilterFactory from "react-fuzzy-filter";
import { toast } from "react-toastify";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GravatarImage from "../../gravatar/gravatar.component";
import WithSpinner from "../../spinner/spinner.component";
import StaffListModal from "../../modals/staff-list/staff-list.component";

import {
  selectErrorMessage,
  selectMessage
} from "../../../redux/message/message.selectors";
import { clearMessage } from "../../../redux/message/message.actions";
import {
  selectStaff,
  selectRefresh
} from "../../../redux/list-data/list-data.selectors";
import { setRefreshFalse } from "../../../redux/list-data/list-data.actions";
const { InputFilter, FilterResults } = fuzzyFilterFactory();

class StaffTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false,
      modalDeleteShow: false,
      staffMember: {},
      editModalShow: false,
      staff: []
    };
  }

  modalClose = () => this.setState({ modalShow: false });

  renderStaffModal = () => (
    <StaffListModal
      show={this.state.modalShow}
      onHide={this.modalClose}
      staff={this.state.staffMember}
    />
  );

  componentDidMount() {
    const { staff } = this.props;
    const staffValues = Object.values(staff);
    this.setState({ staff: staffValues });
  }

  componentDidUpdate() {
    const {
      needsRefresh,
      setRefreshFalse,
      staff,
      message,
      errorMessage,
      clearMessage
    } = this.props;
    const staffValues = Object.values(staff);
    if (needsRefresh) {
      this.setState({
        staff: staffValues
      });
      setRefreshFalse();
    }
    if (message) {
      toast.success(message);
      clearMessage();
    }
    if (errorMessage) {
      toast.error(errorMessage);
      clearMessage();
    }
  }

  handleDeleteActivityClick = (e, data) => {
    const { activityId } = data;
    this.setState({ genericModalShow: true, deleteId: activityId });
  };

  render() {
    const fuseConfig = {
      caseSensitive: false,
      keys: ["first_name", "last_name"]
    };
    const { staff } = this.state;
    if (staff.length < 1) {
      return <WithSpinner message="Loading Staff List..." />;
    }

    return (
      <Fragment>
        <Row>
          <Col md={10}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Search</Form.Label>
              <InputFilter
                debounceTime={200}
                inputProps={{ className: "form-control" }}
              />
              <Form.Text className="text-muted">
                Enter search params above to filter the below list
              </Form.Text>
            </Form.Group>
          </Col>
          <Col style={{ alignItems: "center" }}>
            <br />
            <Button
              variant="primary"
              onClick={() =>
                this.setState({ modalShow: true, staffMember: {} })
              }
            >
              Add Staff
            </Button>
          </Col>
        </Row>
        <ReactTableContainer width="100%" height="80vh">
          <Table striped bordered hover responsive="sm">
            <colgroup>
              <col
                span="1"
                className=""
                style={{ backgroundColor: "#FFFFFF" }}
              />
              <col
                span="1"
                className=""
                style={{ backgroundColor: "#FFFFFF" }}
              />
              <col
                span="1"
                className=""
                style={{ backgroundColor: "#FFFFFF" }}
              />
            </colgroup>
            <thead>
              <tr>
                <td>Id</td>
                <td>Name</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              <FilterResults
                items={_.orderBy(
                  staff,
                  ["active", "first_name"],
                  ["desc", "asc"]
                )}
                fuseConfig={fuseConfig}
              >
                {filteredItems => {
                  return filteredItems.map(filteredItem => (
                    <tr key={uuid.v4()}>
                      <td>{filteredItem.staff_id}</td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row"
                          }}
                        >
                          <div style={{ flex: 0.5 }}>
                            {filteredItem.email_address.includes("@") ? (
                              <GravatarImage
                                email={filteredItem.email_address}
                                name={`${filteredItem.first_name} ${filteredItem.last_name}`}
                              ></GravatarImage>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            style={{
                              flex: 2.5,
                              textDecoration: !filteredItem.active
                                ? "line-through"
                                : null
                            }}
                          >
                            {filteredItem.first_name} {filteredItem.last_name}
                          </div>
                        </div>
                      </td>
                      <td>
                        <Button
                          variant="primary"
                          onClick={() =>
                            this.setState({
                              modalShow: true,
                              modalDeleteShow: false,
                              staffMember: filteredItem
                            })
                          }
                        >
                          Edit
                        </Button>
                      </td>
                    </tr>
                  ));
                }}
              </FilterResults>
            </tbody>
          </Table>
        </ReactTableContainer>
        {this.renderStaffModal()}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  staff: selectStaff,
  needsRefresh: selectRefresh,
  message: selectMessage,
  errorMessage: selectErrorMessage
});

const mapDispatchToProps = dispatch => ({
  setRefreshFalse: () => dispatch(setRefreshFalse()),
  clearMessage: () => dispatch(clearMessage())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StaffTable);
