import React from "react";
import ReactDOM from "react-dom";

import Modal from "react-bootstrap/Modal";
import ActivityRolesForm from "../../forms/activity-role-form/activity-role-form.component";

const ActivityRolesListModal = props => {
  const { role } = props;
  return ReactDOM.createPortal(
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {role.name ? "Edit" : "Add"} Activity Role
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ActivityRolesForm onHide={props.onHide} initialValues={role} />
      </Modal.Body>
    </Modal>,
    document.querySelector("#modal")
  );
};

export default ActivityRolesListModal;
