import { createSelector } from "reselect";

const selectActivityToEdit = state => state.activityToEdit;

export const selectActivityToEditStaff = createSelector(
  [selectActivityToEdit],
  activityToEdit => activityToEdit.staff
);

export const selectEditActivity = createSelector(
  [selectActivityToEdit],
  activityToEdit => activityToEdit
);

export const selectIsActivityConfirmed = createSelector(
  [selectEditActivity],
  activityToEdit => !!activityToEdit.confirmed
);

export const selectMessage = createSelector(
  [selectActivityToEdit],
  activityToEdit => activityToEdit.message
);

export const selectErrorMessage = createSelector(
  [selectActivityToEdit],
  activityToEdit => activityToEdit.errorMessage
);

export const selectFiles = createSelector(
  [selectActivityToEdit],
  activityToEdit => activityToEdit.files
);

export const selectLocalFiles = createSelector(
  [selectActivityToEdit],
  activityToEdit => activityToEdit.localFiles
);
