import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// import persistor from "./redux/store";
import { LastLocationProvider } from "react-router-last-location";

import { store, persistor } from "./redux/store";

import "./index.css";
import history from "./history";
import App from "./App";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <LastLocationProvider>
          <App />
        </LastLocationProvider>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
