import React, { Component } from "react";

import KitsTable from "../../components/tables/kits-table/kits-table.components";

class KitsListPage extends Component {
  render() {
    return <KitsTable />;
  }
}

export default KitsListPage;
