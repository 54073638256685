import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";

const TextInputWithIcon = ({
  input,
  type,
  label,
  meta,
  placeholder,
  required,
  disabled,
  step,
  icon
}) => {
  const errorClass = meta.touched && meta.error ? "is-invalid" : "";
  const labelClass = meta.touched && meta.error ? "text-danger" : "";

  return (
    <Form.Group as={Col} md="12">
      <Form.Label className={labelClass}>{label}</Form.Label>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text id="inputGroupPrepend">{icon}</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          {...input}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          aria-describedby="inputGroupPrepend"
          className={errorClass}
        />
        <Form.Control.Feedback type="invalid">
          Please choose a username.
        </Form.Control.Feedback>
      </InputGroup>

      {meta.touched &&
        (meta.error && <span className="text-danger">{meta.error}</span>)}
    </Form.Group>
  );
};

export default TextInputWithIcon;
