import React from "react";
import ReactDOM from "react-dom";

import Modal from "react-bootstrap/Modal";
import TrucksForm from "../../forms/truck-form/truck-form.component";

const TruckListModal = props => {
  const { truck } = props;
  return ReactDOM.createPortal(
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {truck.name ? "Edit" : "Add"} Truck
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TrucksForm onHide={props.onHide} initialValues={truck} />
      </Modal.Body>
    </Modal>,
    document.querySelector("#modal")
  );
};

export default TruckListModal;
