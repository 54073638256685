import React, { Component } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import { withRouter } from "react-router-dom";
import ResetPasswordForm from "../../components/forms/reset-password-form/reset-password-form.component";
import { toast } from "react-toastify";

import { resetPassword } from "../../redux/user/user.actions";
import {
  selectErrorMessage,
  selectMessage
} from "../../redux/message/message.selectors";
import { clearMessage } from "../../redux/message/message.actions";

class ResetPasswordPage extends Component {
  submit = values => {
    const {
      resetPassword,
      match: { params }
    } = this.props;
    const { token } = params;
    resetPassword(values, token);
  };

  componentDidUpdate() {
    const { message, errorMessage, clearMessage } = this.props;
    if (message) {
      toast.success(message);
      clearMessage();
    }

    if (errorMessage) {
      toast.error(errorMessage);
      clearMessage();
    }
  }

  render() {
    return (
      <Container>
        <h3>Reset Password</h3>
        <ResetPasswordForm onSubmit={this.submit} />
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  resetPassword: (formValues, token) =>
    dispatch(resetPassword(formValues, token)),
  clearMessage: () => dispatch(clearMessage())
});

const mapStateToProps = createStructuredSelector({
  message: selectMessage,
  errorMessage: selectErrorMessage
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResetPasswordPage)
);
