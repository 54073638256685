import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { toast } from "react-toastify";

import {
  selectErrorMessage,
  selectMessage
} from "../../redux/message/message.selectors";
import { clearMessage } from "../../redux/message/message.actions";
import { selectConfirmed } from "../../redux/user/user.selectors";
import { validateAndConfirmEmail } from "../../redux/user/user.actions";

class ConfirmEmailPage extends Component {
  componentDidMount() {
    const {
      validateAndConfirmEmail,
      match: { params }
    } = this.props;
    validateAndConfirmEmail(params.token);
  }

  componentDidUpdate() {
    const { message, errorMessage, clearMessage } = this.props;
    if (message) {
      toast.success(message);
      clearMessage();
    }
    if (errorMessage) {
      toast.error(errorMessage);
      clearMessage();
    }
  }

  render() {
    const { isConfirmed } = this.props;

    if (!isConfirmed) {
      return <div className=""></div>;
    } else {
      return <Redirect to="/" />;
    }
  }
}

const mapDispatchToProps = dispatch => ({
  validateAndConfirmEmail: token => dispatch(validateAndConfirmEmail(token)),
  clearMessage: () => dispatch(clearMessage())
});

const mapStateToProps = createStructuredSelector({
  isConfirmed: selectConfirmed,
  message: selectMessage,
  errorMessage: selectErrorMessage
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmEmailPage);
