import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import TextInput from "../../form-inputs/text-input.component";
import SelectInput from "../../form-inputs/select-input.component";

import { selectCompanyId } from "../../../redux/user/user.selectors";
import { selectKits } from "../../../redux/list-data/list-data.selectors";
import {
  addTruckAsync,
  updateTruckAsync
} from "../../../redux/list-data/list-data.actions";

class TrucksForm extends Component {
  onSubmit = formValues => {
    const {
      addTruckAsync,
      updateTruckAsync,
      onHide,
      companyId,
      initialValues
    } = this.props;

    formValues.company_id = companyId;

    if (!initialValues.name) {
      addTruckAsync(formValues);
    } else {
      updateTruckAsync(formValues);
    }
    onHide();
  };

  render() {
    const { handleSubmit, onHide, kits, initialValues } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="name"
          label={initialValues.name ? "name" : null}
          component={TextInput}
          type="text"
          placeholder="Name"
        />
        <Field
          name="notes"
          label={initialValues.name ? "notes" : null}
          component={TextInput}
          type="text"
          placeholder="Notes"
        />
        <Field
          name="default_kit"
          label={initialValues.name ? "Default Kit" : null}
          component={SelectInput}
          options={Object.values(kits)}
          placeholder="Default License"
        />
        <Button type="submit" label="submit">
          Submit
        </Button>{" "}
        <Button type="button" variant="danger" onClick={onHide} label="submit">
          Cancel
        </Button>
      </Form>
    );
  }
}

const validate = formValues => {
  const errors = {};
  const requiredFields = ["name"];
  requiredFields.forEach(field => {
    if (!formValues[field]) {
      errors[field] = "Required";
    }
  });

  return errors;
};

const mapStateToProps = createStructuredSelector({
  companyId: selectCompanyId,
  kits: selectKits
});

const mapDispatchToProps = dispatch => ({
  addTruckAsync: formValues => dispatch(addTruckAsync(formValues)),
  updateTruckAsync: formValues => dispatch(updateTruckAsync(formValues))
});

const formWrapper = reduxForm({
  form: "addToKitList",
  validate
})(TrucksForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(formWrapper);
