import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import TextInput from "../../form-inputs/text-input.component";

import { selectCompanyId } from "../../../redux/user/user.selectors";
import {
  addLicenseAsync,
  updateLicenseAsync
} from "../../../redux/list-data/list-data.actions";

class LicenseForm extends Component {
  onSubmit = formValues => {
    const {
      addLicenseAsync,
      updateLicenseAsync,
      onHide,
      companyId,
      initialValues
    } = this.props;

    formValues.company_id = companyId;
    formValues.default_license = formValues.default_license
      ? formValues.default_license
      : 0;

    if (!initialValues.name) {
      addLicenseAsync(formValues);
    } else {
      updateLicenseAsync(formValues);
    }
    onHide();
  };

  render() {
    const { handleSubmit, onHide, initialValues } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="name"
          label={initialValues.name ? "name" : null}
          component={TextInput}
          type="text"
          placeholder="License Name"
        />
        <Button type="submit" label="submit">
          Submit
        </Button>{" "}
        <Button type="button" variant="danger" onClick={onHide} label="submit">
          Cancel
        </Button>
      </Form>
    );
  }
}

const validate = formValues => {
  const errors = {};
  const requiredFields = ["name"];
  requiredFields.forEach(field => {
    if (!formValues[field]) {
      errors[field] = "Required";
    }
  });

  return errors;
};

const mapStateToProps = createStructuredSelector({
  companyId: selectCompanyId
});

const mapDispatchToProps = dispatch => ({
  addLicenseAsync: formValues => dispatch(addLicenseAsync(formValues)),
  updateLicenseAsync: formValues => dispatch(updateLicenseAsync(formValues))
});

const formWrapper = reduxForm({
  form: "addToKitList",
  validate
})(LicenseForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(formWrapper);
