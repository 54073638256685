import React from "react";
import ReactDOM from "react-dom";

import Modal from "react-bootstrap/Modal";
import VenueForm from "../../forms/venue-form/venue-form.component";

const TruckListModal = props => {
  const { venue } = props;
  return ReactDOM.createPortal(
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {venue.name ? "Edit" : "Add"} Venue
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VenueForm onHide={props.onHide} initialValues={venue} />
      </Modal.Body>
    </Modal>,
    document.querySelector("#modal")
  );
};

export default TruckListModal;
