import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import uuid from "uuid";

const SelectVenueInput = ({
  input,
  type,
  label,
  meta,
  placeholder,
  required,
  options,
  disabled
}) => {
  const errorClass = meta.touched && meta.error ? "is-invalid" : "";
  const labelClass = meta.touched && meta.error ? "text-danger" : "";

  return (
    <Form.Group as={Col} controlId={input.name}>
      <Form.Label className={labelClass}>{label}</Form.Label>
      <Form.Control
        {...input}
        as="select"
        className={errorClass}
        disabled={disabled}
      >
        <option>{placeholder}</option>
        {options.map(option => {
          return (
            <option key={uuid.v4()} value={option.id}>
              {option.city !== ""
                ? `${option.name}, ${option.city}`
                : option.name}
            </option>
          );
        })}
      </Form.Control>
      {meta.touched &&
        (meta.error && <span className="text-danger">{meta.error}</span>)}
    </Form.Group>
  );
};

export default SelectVenueInput;
