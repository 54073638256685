import { createSelector } from "reselect";

const selectRoster = state => state.roster;

export const selectRosterDateRange = createSelector(
  [selectRoster],
  roster => roster.dateRange
);

export const selectRosterActivities = createSelector(
  [selectRoster],
  roster => roster.activities
);

export const selectRosterStaff = createSelector(
  [selectRoster],
  roster => roster.staff
);

export const selectActiveStaff = createSelector(
  [selectRoster],
  roster => roster.activeStaff
);

export const selectIsRosterFetching = createSelector(
  [selectRoster],
  roster => roster.isFetching
);

export const selectIsRosterLoaded = createSelector([selectRoster], roster => {
  return !!roster.activities;
});

export const selectIsRosterHeaderVisible = createSelector(
  [selectRoster],
  roster => roster.headerVisible
);
