import React, { Component, Fragment } from "react";
import { createStructuredSelector } from "reselect";
import { Link, withRouter } from "react-router-dom";
import { isAfter, format, parseISO } from "date-fns";
import uuid from "uuid";
import { connect } from "react-redux";
import _ from "lodash";
import {
  hexToRgb,
  determineActivityTextColour
} from "../../utils/colour.utils";

import { StyledSection } from "./activity.styles";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Badge from "react-bootstrap/Badge";

import { selectCurrentUser } from "../../redux/user/user.selectors";

import { faLink } from "@fortawesome/free-solid-svg-icons";

import Icon from "../icon/icon.component";

class Activity extends Component {
  handleAddActivity = (date, id) => {
    const { history } = this.props;
    const activityDate = format(date, "YYYY-MM-DD");
    history.push(`/add_activity/${activityDate}/${id}`);
  };

  handleCopyActivity = id => {
    const { history } = this.props;
    history.push(`/copy_activity/${id}`);
  };

  renderSection(personid, activity, activityType) {
    const {
      note,
      name,
      truck_name,
      activity_colour,
      update_time,
      short_name,
      sequence,
      venue_name,
      confirmedStaff,
      company_activity_type
    } = activity;
    const headerExemptActionTypes = [10000, 10002, 10013, 10015, 10016, 10025];
    const rgbColour = hexToRgb(activity_colour);

    const sectionStyle = {
      background: `rgba(${rgbColour.red}, ${rgbColour.green}, ${rgbColour.blue}, 0.7)`,
      color: `${determineActivityTextColour(activity_colour)}`,
      borderColor: isAfter(
        parseISO(update_time),
        parseISO(this.props.user.lastLogout)
      )
        ? "#FF0000"
        : `#${activity_colour}`,
      textDecoration:
        activityType === "header"
          ? ""
          : activityType === "roster" && !_.includes(confirmedStaff, personid)
          ? "line-through"
          : ""
    };

    return (
      <StyledSection style={sectionStyle} key={uuid.v4()}>
        <Badge
          pill
          variant="danger"
          style={{
            bottom: "-5px",
            right: "0",
            position: "absolute",
            fontSize: ".8em"
          }}
        >
          {short_name}
        </Badge>
        {sequence ? <Icon icon={faLink} /> : ""}
        {sequence ? " " : ""}
        {note && note.length > 1 ? (
          <Fragment>
            <img src="/postit.png" alt="note" />
            <br />
          </Fragment>
        ) : null}
        {name}
        {_.includes(headerExemptActionTypes, company_activity_type) ? null : (
          <br />
        )}
        {_.includes(headerExemptActionTypes, company_activity_type)
          ? null
          : venue_name}
        <br />
        {truck_name !== "N/A" ? truck_name : null}
      </StyledSection>
    );
  }

  render() {
    const { personid, activity, activityType, dashboardId } = this.props;
    let dashId = dashboardId;
    if (!dashboardId) {
      dashId = 0;
    }
    const { id, note } = activity;
    return (
      <Fragment>
        {note && note.length > 1 ? (
          <OverlayTrigger
            key={uuid.v4()}
            placement="top"
            overlay={
              <Popover id={`popover-positioned-${id}`}>
                <Popover.Title>Just so you know...</Popover.Title>
                <Popover.Content>{note}</Popover.Content>
              </Popover>
            }
          >
            <Link
              to={`/edit_activity/${id}/${activityType}/${dashId}`}
              style={{ textDecoration: "none" }}
              key={uuid.v4()}
            >
              {this.renderSection(personid, activity, activityType)}
            </Link>
          </OverlayTrigger>
        ) : (
          <Link
            to={`/edit_activity/${id}/${activityType}/${dashId}`}
            style={{ textDecoration: "none" }}
            key={uuid.v4()}
          >
            {this.renderSection(personid, activity, activityType)}
          </Link>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser
});

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Activity)
);
