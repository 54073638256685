import { createSelector } from "reselect";

const selectMessages = state => state.messages;

export const selectMessage = createSelector(
  [selectMessages],
  messages => messages.message
);

export const selectErrorMessage = createSelector(
  [selectMessages],
  messages => messages.errorMessage
);
