import { createSelector } from "reselect";

const selectSettings = state => state.settings;

export const selectIsRosterHeaderVisible = createSelector(
  [selectSettings],
  settings => settings.headerVisible
);

export const isUnusedRoweVisible = createSelector(
  [selectSettings],
  settings => settings.hideEmpty
);

export const selectSelectedDate = createSelector(
  [selectSettings],
  settings => settings.selectedDate
);
