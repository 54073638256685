import { utcToZonedTime, format } from 'date-fns-tz';
import {
  endOfWeek,
  startOfWeek,
  parseISO,
  eachDayOfInterval,
  isSameMonth,
  subMonths,
  addMonths,
  isToday,
  isSameWeek,
  subWeeks,
  addWeeks,
  startOfMonth,
  endOfMonth,
  isBefore,
  addSeconds,
} from 'date-fns';

// base timezone
// detects users timezone e.g Pacific/Auckland
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const timeFromSeconds = seconds => {
  console.log(seconds);

  var helperDate = addSeconds(new Date(2014, 6, 10, 0, 0, 0), seconds);
  return format(helperDate, 'HH:mm');
};

/**
 * Get Week of dates
 * @param {String} date any date in a calendar week
 * @returns the array of dates within the specified time interval.
 */
export const getWeekOfDates = date => {
  const weekStart = startOfWeek(parseISO(date), { weekStartsOn: 1 });
  const weekEnd = endOfWeek(parseISO(date), { weekStartsOn: 1 });
  return eachDayOfInterval({ start: weekStart, end: weekEnd });
};

/**
 * Get a range of dates
 * @param {String} startDate the first date in the range
 * @param {String} endDate the last date in the range
 * @returns the array of dates within the specified time interval.
 */
export const getDateRange = (startDate, endDate) => {
  const rangeZonedDate = utcToZonedTime(startDate, timeZone);
  const rangeZonedEnd = utcToZonedTime(endDate, timeZone);
  return eachDayOfInterval({
    start: rangeZonedDate,
    end: rangeZonedEnd,
  });
};

/**
 * Get a formatted date in a specified format
 * @param {String} dateString the date to format
 * @returns Return the formatted date string in the "EE dd/MM/yyyy" format
 */
export const getFullFormattedDate = dateString => {
  const date = new Date(dateString);

  const pattern = 'EE dd/MM/yyyy';
  return format(date, pattern);
};

/**
 * Get a formatted date in a specified format
 * @param {String} dateString the date to format
 * @returns Return the formatted date string in the "dd/MM/yyyy" format
 */
export const getShortFormattedPrettyDate = dateString => {
  const date = new Date(dateString);

  const pattern = 'dd/MM/yyyy';
  return format(date, pattern);
};

/**
 * Get a formatted date in a specified format
 * @param {String} dateString the date to format
 * @returns Return the formatted date string in the "yyyy-MM-dd" format
 */
export const getShortFormattedDate = dateString => {
  const date = new Date(dateString);

  const pattern = 'yyyy-MM-dd';
  return format(date, pattern);
};

/**
 * Get a formatted date that is the beginning of the calendar week of the supplied date
 * First day of week is MONDAY
 * @param {String} dateString the date to format
 * @returns Return the formatted date string of the first day of the week in the "yyyy-MM-dd" format
 */
export const getStartOfWeek = dateString => {
  const date = new Date(dateString);

  const pattern = 'yyyy-MM-dd';
  return format(startOfWeek(date, { weekStartsOn: 1 }), pattern);
};

/**
 * Get a formatted date that is the end of the calendar week of the supplied date
 * First day of week is MONDAY
 * @param {String} dateString the date to format
 * @returns Return the formatted date string of the last day of the week in the "yyyy-MM-dd" format
 */
export const getEndOfWeek = dateString => {
  const date = new Date(dateString);

  const pattern = 'yyyy-MM-dd';
  return format(endOfWeek(date, { weekStartsOn: 1 }), pattern);
};

/**
 * Get a formatted timestamp of now
 * @returns a formatted date and time in ihe "yyyy-MM-dd hh:mm:ss" format
 */
export const getUpdateTimeStamp = () => {
  const currentDateTime = new Date();

  const zonedDate = utcToZonedTime(currentDateTime, timeZone);

  const pattern = 'yyyy-MM-dd hh:mm:ss';
  return format(endOfWeek(zonedDate, { weekStartsOn: 1 }), pattern, {
    timeZone,
  });
};

/**
 * Compares two dates to see if they are in the same calendar month
 * @param {String} dateString the date to test
 * @param {String} compareDateString the date to compare against
 * @returns {boolean}
 */
export const isDateTheSameMonth = (dateString, compareDateString) => {
  const date = new Date(dateString);
  const compareDate = new Date(compareDateString);

  const zonedDate = utcToZonedTime(date, timeZone);
  const zonedCompareDate = utcToZonedTime(compareDate, timeZone);

  return isSameMonth(zonedDate, zonedCompareDate);
};

/**
 * Gets the year, month and day of a supplied date
 * @param {String} dateString the supplied date
 * @returns {Object} year, month and day formatted correctly
 */
export const getYearMonthDay = dateString => {
  const date = new Date(dateString);

  const zonedDate = utcToZonedTime(date, timeZone);

  return {
    year: format(zonedDate, 'yyyy'),
    month: format(zonedDate, 'LLLL'),
    day: format(zonedDate, '	dd'),
  };
};

/**
 * Gets the year, month and day of a supplied date
 * @param {String} dateString the supplied date
 * @returns {Object} year, month and day formatted correctly
 */
export const getYearMonthDayUnFormatted = dateString => {
  const date = new Date(dateString);

  const zonedDate = utcToZonedTime(date, timeZone);

  return {
    year: format(zonedDate, 'yyyy'),
    month: format(zonedDate, 'MM'),
    day: format(zonedDate, '	dd'),
  };
};

/**
 * subtracts a calendar month from a supplied date
 * @param {String} dateString the supplied date
 * @returns {date} a date formatted as "yyyy-MM-dd" one month less than
 * the one supplied
 */
export const subAMonth = dateString => {
  const date = new Date(dateString);

  const zonedDate = utcToZonedTime(date, timeZone);
  const pattern = 'yyyy-MM-dd';
  return format(subMonths(zonedDate, 1), pattern);
};

/**
 * adds a calendar month to a supplied date
 * @param {String} dateString the supplied date
 * @returns {date} a date formatted as "yyyy-MM-dd" one month more than
 * the one supplied
 */
export const addAMonth = dateString => {
  const date = new Date(dateString);

  const zonedDate = utcToZonedTime(date, timeZone);
  const pattern = 'yyyy-MM-dd';
  return format(addMonths(zonedDate, 1), pattern);
};

/**
 * subtracts a calendar week to a supplied date
 * @param {String} dateString the supplied date
 * @returns {date} a date formatted as "yyyy-MM-dd" one week less than
 * the one supplied
 */
export const subAWeek = dateString => {
  const date = new Date(dateString);

  const zonedDate = utcToZonedTime(date, timeZone);
  const pattern = 'yyyy-MM-dd';
  return format(subWeeks(zonedDate, 1), pattern);
};

/**
 * adds a calendar week to a supplied date
 * @param {String} dateString the supplied date
 * @returns {date} a date formatted as "yyyy-MM-dd" one week more than
 * the one supplied
 */
export const addAWeek = dateString => {
  const date = new Date(dateString);

  const zonedDate = utcToZonedTime(date, timeZone);
  const pattern = 'yyyy-MM-dd';
  return format(addWeeks(zonedDate, 1), pattern);
};

/**
 * Checks to see if a supplied date is today
 * @param {String} dateString the supplied date
 * @returns {bool}
 */
export const isItToday = dateString => {
  const date = new Date(dateString);

  const zonedDate = utcToZonedTime(date, timeZone);
  return isToday(zonedDate);
};

/**
 * Compares two dates to see if they are in the same calendar week
 * @param {String} dateString the date to test
 * @param {String} compareDateString the date to compare against
 * @returns {boolean}
 */
export const isDateInTheSameWeek = (selectedDate, compareDate) => {
  const zonedDate = utcToZonedTime(selectedDate, timeZone);
  const zonedCompareDate = utcToZonedTime(compareDate, timeZone);

  return isSameWeek(zonedDate, zonedCompareDate);
};

/**
 * Get a formatted date that is the beginning of the calendar month of the supplied date
 * @param {String} dateString the date to format
 * @returns Return the formatted date string of the first day of the week in the "yyyy-MM-dd" format
 */
const getStartOfMonth = dateString => {
  const date = new Date(dateString);

  const pattern = 'yyyy-MM-dd';
  return format(startOfMonth(date), pattern);
};
/**
 * Get a formatted date that is the end of the calendar month of the supplied date
 * First day of week is MONDAY
 * @param {String} dateString the date to format
 * @returns Return the formatted date string of the last day of the week in the "yyyy-MM-dd" format
 */
const getEndOfMonth = dateString => {
  const date = new Date(dateString);

  const pattern = 'yyyy-MM-dd';
  return format(endOfMonth(date), pattern);
};

export const getCalendarDayRange = selectedDate => {
  const monthStart = getStartOfMonth(selectedDate);
  const startDate = getStartOfWeek(monthStart);

  const monthEnd = getEndOfMonth(selectedDate);
  const endDate = getEndOfWeek(monthEnd);
  const dateRange = eachDayOfInterval({
    start: new Date(startDate),
    end: new Date(endDate),
  });
  const pattern = 'yyyy-MM-dd';
  const dates = [];
  dateRange.forEach(date => {
    dates.push(format(date, pattern));
  });

  return dates;
};

/**
 * Compares two dates to see if one is before the other
 * @param {String} dateString the date to test
 * @param {String} compareDateString the date to compare against
 * @returns {boolean}
 */
export const isDateBefore = (selectedDate, compareDate) => {
  const zonedDate = utcToZonedTime(selectedDate, timeZone);
  const zonedCompareDate = utcToZonedTime(compareDate, timeZone);

  return isBefore(zonedDate, zonedCompareDate);
};
