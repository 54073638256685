export const prepareActivityForSaving = activity => {
  const staffList = [];
  activity.confirmedStaff = [];

  activity.staff.forEach(staffMember => {
    staffList.push(staffMember.company_staff_id);
    if (staffMember.confirmed) {
      activity.confirmedStaff.push(staffMember.company_staff_id);
    }
  });
  activity.staff = staffList;
  return activity;
};
