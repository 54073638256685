import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import ReactTableContainer from 'react-table-container';
import { createStructuredSelector } from 'reselect';
import uuid from 'uuid';
import history from '../../history';
import _ from 'lodash';
import {
  getStartOfWeek,
  isDateInTheSameWeek,
  subAMonth,
  subAWeek,
  addAWeek,
  addAMonth,
  getShortFormattedDate,
  getFullFormattedDate,
} from '../../utils/date.utils';
import fuzzyFilterFactory from 'react-fuzzy-filter';
import { toast } from 'react-toastify';

import DayContextMenu from '../roster-day-context-menu/roster-day-context-menu';
import ActivityContextMenu from '../roster-activity-context-menu/roster-activity-context-menu';
import Table from 'react-bootstrap/Table';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import HeaderContextMenu from '../roster-header-context-menu/roster-header-context-menu.component';
import WithSpinner from '../spinner/spinner.component';

import {
  selectRosterDateRange,
  selectRosterStaff,
  selectRosterActivities,
  selectIsRosterFetching,
  selectActiveStaff,
} from '../../redux/roster/roster.selectors';
import {
  selectIsRosterHeaderVisible,
  isUnusedRoweVisible,
} from '../../redux/settings/settings.selectors';
import {
  selectMessage,
  selectErrorMessage,
} from '../../redux/message/message.selectors';
import {
  setDateRange,
  fetchRosterDataAsync,
} from '../../redux/roster/roster.actions';
import {
  setRosterHeaderVisible,
  setUnusedRowsVisible,
} from '../../redux/settings/settings.actions';
import { clearMessage } from '../../redux/message/message.actions';
import {
  selectCurrentUser,
  selectIsAdmin,
} from '../../redux/user/user.selectors';
import {
  selectActivityTypes,
  selectClients,
  selectIListDataFetching,
} from '../../redux/list-data/list-data.selectors';
import {
  selectErrorMessage as selectEditErrorMessage,
  selectMessage as selectEditMessage,
} from '../../redux/edit-activity/edit-activity.selectors';
const { InputFilter, FilterResults } = fuzzyFilterFactory();

class RosterTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      client: 0,
      type: 0,
    };
  }

  generateDoilSpan = dil_length => {
    let doilText = dil_length === 1 ? 'doil remaining' : 'doils remaining';
    return (
      <span style={{ fontSize: '10px' }}>
        {dil_length} {doilText}
      </span>
    );
  };

  handleRosterHeaderVisibleClick = () => {
    const { isHeaderVisible, setHeaderVisible } = this.props;
    setHeaderVisible(!isHeaderVisible);
  };

  handleRosterHideEmptyClick = () => {
    const { hideEmpty, setUnusedRowsVisible } = this.props;
    setUnusedRowsVisible(!hideEmpty);
  };

  componentDidMount() {
    const {
      setDateRange,
      match,
      currentUser,
      fetchRosterDataAsync,
    } = this.props;

    const { selectedDate } = match.params;
    setDateRange(selectedDate);
    fetchRosterDataAsync(
      getStartOfWeek(selectedDate),
      currentUser.companyId,
      currentUser.companyStaffId,
    );
  }

  componentDidUpdate = () => {
    const { message, clearMessage, errorMessage } = this.props;
    if (message) {
      toast.success(message);
      clearMessage();
    }

    if (errorMessage) {
      toast.success(errorMessage);
      clearMessage();
    }
  };

  handleDateChange = e => {
    const {
      currentUser,
      match: { params },
      fetchRosterDataAsync,
    } = this.props;
    const { selectedDate } = params;
    const pickedDate = e.target.value;
    if (!isDateInTheSameWeek(pickedDate, selectedDate)) {
      history.push(`/roster/${pickedDate}/${currentUser.companyId}`);
      fetchRosterDataAsync(
        getStartOfWeek(pickedDate),
        currentUser.companyId,
        currentUser.companyStaffId,
      );
    }
  };

  handleDateButtonChange = e => {
    const {
      fetchRosterDataAsync,
      currentUser,
      match: {
        params: { selectedDate },
      },
    } = this.props;

    const dateType = e.target.innerHTML;

    let newDate = '';
    if (dateType === '+M') {
      newDate = addAMonth(selectedDate);
    } else if (dateType === '+W') {
      newDate = addAWeek(selectedDate);
    } else if (dateType === '-M') {
      newDate = subAMonth(selectedDate);
    } else if (dateType === '-W') {
      newDate = subAWeek(selectedDate);
    } else {
      newDate = getShortFormattedDate(new Date());
    }
    history.push(`/roster/${newDate}/${currentUser.companyId}`);
    fetchRosterDataAsync(
      getStartOfWeek(newDate),
      currentUser.companyId,
      currentUser.companyStaffId,
    );
  };

  changeActivityType = e => {
    this.setState({ type: Number(e.currentTarget.value) });
  };

  changeClient = e => {
    this.setState({ client: Number(e.currentTarget.value) });
  };

  render() {
    const {
      dateRange,
      staff,
      rosterActivities,
      match: { params },
      isFetching,
      isHeaderVisible,
      activityTypes,
      clients,
      listsFetching,
      isAdmin,
      currentUser,
      hideEmpty,
      activeStaff,
    } = this.props;

    const { client, type } = this.state;

    const { selectedDate } = params;

    const mappedStaff = Object.values(staff);
    const filteredMappedStaff = _.filter(mappedStaff, staff =>
      activeStaff.includes(staff.person_id),
    );

    const mappedActivities = Object.values(rosterActivities);
    const clientFiltered = _.filter(mappedActivities, activity => {
      if (client !== 0) {
        return activity.client === client;
      } else {
        return rosterActivities;
      }
    });

    const typeFiltered = _.filter(clientFiltered, activity => {
      if (type !== 0) {
        return activity.company_activity_type === type;
      } else {
        return clientFiltered;
      }
    });

    const activities = _.mapKeys(typeFiltered, 'hashedId');

    const staffToUse = hideEmpty ? filteredMappedStaff : mappedStaff;

    const staffValues = _.orderBy(
      staffToUse,
      ['priority', 'position', 'first_name'],
      ['desc', 'asc', 'asc'],
    );

    if (isFetching) {
      return <WithSpinner message="Loading Roster..." />;
    }

    if (listsFetching) {
      return <WithSpinner message="Populating Lists..." />;
    }

    const fuseConfig = {
      caseSensitive: false,
      keys: ['first_name', 'last_name'],
    };

    return (
      <Fragment>
        <Row>
          <Col>
            <Row>
              <Col>
                <Form.Control
                  type="date"
                  placeholder="Large text"
                  value={params.selectedDate}
                  onChange={this.handleDateChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ paddingTop: '5px' }}>
                  <Button
                    variant="primary"
                    onClick={this.handleDateButtonChange}
                  >
                    -M
                  </Button>{' '}
                  <Button
                    variant="danger"
                    onClick={this.handleDateButtonChange}
                  >
                    -W
                  </Button>{' '}
                  <Button
                    variant="danger"
                    onClick={this.handleDateButtonChange}
                  >
                    +W
                  </Button>{' '}
                  <Button
                    variant="primary"
                    onClick={this.handleDateButtonChange}
                  >
                    +M
                  </Button>{' '}
                  <Button variant="info" onClick={this.handleDateButtonChange}>
                    Today
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={3}>
            <Form.Control
              as="select"
              value={this.state.type}
              onChange={this.changeActivityType}
            >
              <option value="0">All</option>
              {Object.values(activityTypes).map(activityType => {
                return (
                  <option key={uuid.v4()} value={activityType.id}>
                    {activityType.name}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
          <Col md={3}>
            <Form.Control
              as="select"
              value={this.state.client}
              onChange={this.changeClient}
            >
              <option value="0">All</option>
              {Object.values(clients).map(client => {
                return (
                  <option key={uuid.v4()} value={client.id}>
                    {client.name}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
          <Col md={3}>
            <Form.Group controlId="formBasicEmail">
              <InputFilter
                debounceTime={200}
                inputProps={{
                  className: 'form-control',
                  placeholder: 'Search...',
                }}
              />
              <Form.Text className="text-muted">
                Enter search params above to filter the below staff list
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <br />
        <br />
        <ReactTableContainer
          width="100%"
          height="80vh"
          style={{ scrollBehavior: 'smooth' }}
        >
          <Table bordered hover id="rosterTable">
            <colgroup>
              {dateRange
                ? dateRange.map(date => {
                    return (
                      <col
                        key={uuid.v4()}
                        span="1"
                        className=""
                        style={{ backgroundColor: '#FFFFFF' }}
                      />
                    );
                  })
                : null}
              <col
                key={uuid.v4()}
                span="1"
                className=""
                style={{ backgroundColor: '#FFFFFF' }}
              />
            </colgroup>
            <thead>
              <tr color="active">
                <th width="12.5%">
                  <ButtonGroup>
                    <Button
                      size="sm"
                      onClick={this.handleRosterHeaderVisibleClick}
                    >
                      {isHeaderVisible ? 'Hide Header' : 'Show Header'}
                    </Button>
                    <br />
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={this.handleRosterHideEmptyClick}
                    >
                      {hideEmpty ? 'Show Empty Rows' : 'Hide Empty Rows'}
                    </Button>
                  </ButtonGroup>
                </th>
                {dateRange
                  ? dateRange.map(date => {
                      return (
                        <th width="12.5%" key={uuid.v4()} align="center">
                          {getFullFormattedDate(date) ===
                          getFullFormattedDate(new Date()) ? (
                            <span key={uuid.v4()} className="text-success">
                              {getFullFormattedDate(date)}
                            </span>
                          ) : (
                            <span>{getFullFormattedDate(date)}</span>
                          )}
                        </th>
                      );
                    })
                  : null}
              </tr>
            </thead>
            <tbody>
              {isHeaderVisible ? (
                <tr>
                  <td />
                  {dateRange
                    ? dateRange.map((day, index) => {
                        const headerExemptActionTypes = [
                          10000,
                          10002,
                          10013,
                          10015,
                          10016,
                          10025,
                        ];
                        const tdStyle = index > 4 ? '#c0ffb9' : '';
                        return (
                          <td
                            key={uuid.v4()}
                            style={{ backgroundColor: tdStyle }}
                          >
                            {Object.values(activities).map(activity => {
                              if (
                                getShortFormattedDate(activity.date) ===
                                  getShortFormattedDate(day) &&
                                !_.includes(
                                  headerExemptActionTypes,
                                  activity.company_activity_type,
                                )
                              ) {
                                return (
                                  <HeaderContextMenu
                                    activity={activity}
                                    key={uuid.v4()}
                                    selectedDate={selectedDate}
                                    type={this.props.type}
                                  />
                                );
                              } else {
                                return null;
                              }
                            })}
                          </td>
                        );
                      })
                    : null}
                </tr>
              ) : null}

              {staffValues.length > 0 ? (
                <FilterResults
                  items={_.orderBy(
                    staffToUse,
                    ['priority', 'position', 'first_name'],
                    ['desc', 'asc', 'asc'],
                  )}
                  fuseConfig={fuseConfig}
                >
                  {filteredItems => {
                    return filteredItems.map(staffMember => (
                      <tr key={uuid.v4()}>
                        <td>
                          <Link
                            to={`/dashboard/${
                              staffMember.company_staffId
                            }/${getShortFormattedDate(dateRange[0])}`}
                          >
                            {staffMember.first_name} {staffMember.last_name}
                          </Link>
                          <br />
                          {(isAdmin && Number(staffMember.position) === 1) ||
                          (staffMember.company_staffId ===
                            currentUser.companyStaffId &&
                            Number(staffMember.position) === 1)
                            ? this.generateDoilSpan(staffMember.dil_length)
                            : null}
                        </td>
                        {dateRange
                          ? dateRange.map((day, index) => {
                              const tdStyle = index > 4 ? '#c0ffb9' : '';
                              const activitiesForDay = _.filter(
                                activities,
                                activity => {
                                  return (
                                    getShortFormattedDate(activity.date) ===
                                    getShortFormattedDate(day)
                                  );
                                },
                              );

                              return (
                                <td
                                  key={uuid.v4()}
                                  style={{ backgroundColor: tdStyle }}
                                >
                                  <DayContextMenu
                                    key={uuid.v4()}
                                    selectedDate={selectedDate}
                                    staff={staffMember.company_staffId}
                                    date={getShortFormattedDate(day)}
                                  />
                                  {activitiesForDay.map(activity =>
                                    activity.staff.includes(
                                      staffMember.person_id,
                                    ) ? (
                                      <ActivityContextMenu
                                        activity={activity}
                                        key={uuid.v4()}
                                        date={activity.date}
                                        staff={staff}
                                        person={staffMember.person_id}
                                        selectedDate={selectedDate}
                                      />
                                    ) : (
                                      ''
                                    ),
                                  )}
                                </td>
                              );
                            })
                          : null}
                      </tr>
                    ));
                  }}
                </FilterResults>
              ) : null}
            </tbody>
          </Table>
        </ReactTableContainer>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setHeaderVisible: bool => dispatch(setRosterHeaderVisible(bool)),
  setUnusedRowsVisible: bool => dispatch(setUnusedRowsVisible(bool)),
  setDateRange: date => dispatch(setDateRange(date)),
  fetchRosterDataAsync: (selectedDate, companyId, clientId, typeId, userId) =>
    dispatch(
      fetchRosterDataAsync(selectedDate, companyId, clientId, typeId, userId),
    ),
  clearMessage: () => dispatch(clearMessage()),
});

const mapStateToProps = createStructuredSelector({
  dateRange: selectRosterDateRange,
  staff: selectRosterStaff,
  rosterActivities: selectRosterActivities,
  isFetching: selectIsRosterFetching,
  isHeaderVisible: selectIsRosterHeaderVisible,
  currentUser: selectCurrentUser,
  activityTypes: selectActivityTypes,
  clients: selectClients,
  message: selectMessage,
  errorMessage: selectErrorMessage,
  editMessage: selectEditMessage,
  editErrorMessage: selectEditErrorMessage,
  listsFetching: selectIListDataFetching,
  isAdmin: selectIsAdmin,
  hideEmpty: isUnusedRoweVisible,
  activeStaff: selectActiveStaff,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RosterTable),
);
