export const listDataActionTypes = {
  FETCH_LIST_DATA_START: "FETCH_LIST_DATA_START",
  FETCH_LIST_DATA_SUCCESS: "FETCH_LIST_DATA_SUCCESS",
  FETCH_LIST_DATA_FAILURE: "FETCH_LIST_DATA_FAILURE",
  CHANGE_CURRENT_DATE: "CHANGE_CURRENT_DATE",
  SET_REFRESH_FALSE: "SET_REFRESH_FALSE",
  ADD_STAFF_MEMBER_START: "ADD_STAFF_MEMBER_START",
  ADD_STAFF_MEMBER_SUCCESS: "ADD_STAFF_MEMBER_SUCCESS",
  ADD_STAFF_MEMBER_FAILURE: "ADD_STAFF_MEMBER_FAILURE",
  ADD_ACTIVITY_TYPE_START: "ADD_ACTIVITY_TYPE_START",
  ADD_ACTIVITY_TYPE_SUCCESS: "ADD_ACTIVITY_TYPE_SUCCESS",
  ADD_ACTIVITY_TYPE_FAILURE: "ADD_ACTIVITY_TYPE_FAILURE",
  ADD_ACTIVITY_ROLE_START: "ADD_ACTIVITY_ROLE_START",
  ADD_ACTIVITY_ROLE_SUCCESS: "ADD_ACTIVITY_ROLE_SUCCESS",
  ADD_ACTIVITY_ROLE_FAILURE: "ADD_ACTIVITY_ROLE_FAILURE",
  ADD_CLIENT_START: "ADD_CLIENT_START",
  ADD_CLIENT_SUCCESS: "ADD_CLIENT_SUCCESS",
  ADD_CLIENT_FAILURE: "ADD_CLIENT_FAILURE",
  ADD_KIT_START: "ADD_KIT_START",
  ADD_KIT_SUCCESS: "ADD_KIT_SUCCESS",
  ADD_KIT_FAILURE: "ADD_KIT_FAILURE",
  ADD_LICENSE_START: "ADD_LICENSE_START",
  ADD_LICENSE_SUCCESS: "ADD_LICENSE_SUCCESS",
  ADD_LICENSE_FAILURE: "ADD_LICENSE_FAILURE",
  ADD_TRUCK_START: "ADD_TRUCK_START",
  ADD_TRUCK_SUCCESS: "ADD_TRUCK_SUCCESS",
  ADD_TRUCK_FAILURE: "ADD_TRUCK_FAILURE",
  ADD_VENUE_START: "ADD_VENUE_START",
  ADD_VENUE_SUCCESS: "ADD_VENUE_SUCCESS",
  ADD_VENUE_FAILURE: "ADD_VENUE_FAILURE",
  GET_ALL_TOGS_START: "GET_ALL_TOGS_START",
  GET_ALL_TOGS_SUCCESS: "GET_ALL_TOGS_SUCCESS",
  GET_ALL_TOGS_FAILURE: "GET_ALL_TOGS_FAILURE"
};
