import { rosterActionTypes } from './roster.types';

const INITIAL_STATE = {
  dateRange: [],
  activities: [],
  staff: [],
  activeStaff: [],
  isFetching: false,
  headerVisible: true,
};

const rosterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case rosterActionTypes.SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
      };

    case rosterActionTypes.FETCH_ROSTER_START:
      return { ...state, isFetching: true };

    case rosterActionTypes.FETCH_ROSTER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        activities: action.payload.activities,
        staff: action.payload.staff,
        activeStaff: action.payload.activeStaff || [],
      };

    case rosterActionTypes.FETCH_ROSTER_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case rosterActionTypes.SET_ROSTER_HEADER_VISIBLE:
      return { ...state, headerVisible: action.payload };

    case rosterActionTypes.SAVE_ACTIVITY:
      return {
        ...state,
        activities: {
          ...state.activities,
          [action.payload.hashedDate]: action.payload,
        },
      };

    case rosterActionTypes.DELETE_ACTIVITY:
      return { ...state, activities: action.payload, isFetching: false };

    case rosterActionTypes.DELETE_STAFF_FROM_ACTIVITY:
      return { ...state, activities: action.payload, isFetching: false };

    default:
      return state;
  }
};

export default rosterReducer;
