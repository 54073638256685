import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import uuid from "uuid";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ActivityStaffCard from "../../components/activity-staff-card/activity-staff-card.component";
import ActivityForm from "../../components/forms/activity-form/activity-form.component";
import ActivityStaffModal from "../../components/modals/activity-staff/activity-staff-modal.component";

import { fetchEditActivityStartAsync } from "../../redux/edit-activity/edit-activity.actions";
import {
  selectActivityToEditStaff,
  selectEditActivity
} from "../../redux/edit-activity/edit-activity.selectors";

class CopyActivityPage extends Component {
  constructor() {
    super();
    this.state = {
      modalShow: false
    };
  }

  componentDidMount = () => {
    const {
      fetchEditActivityStartAsync,
      match: { params }
    } = this.props;

    fetchEditActivityStartAsync(params.activityId);
  };

  render() {
    const { staff, activity } = this.props;
    const mappedStaff = Object.values(staff);

    let modalClose = () => this.setState({ modalShow: false });

    return (
      <Container>
        <Row>
          <Col>
            <h2>Copy an Activity</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <ActivityForm initialValues={activity}></ActivityForm>
          </Col>
        </Row>
        <br />
        <Button
          onClick={() => this.setState({ modalShow: true })}
          variant="dark"
        >
          Add Staff
        </Button>
        <br />
        <Row>
          <Col>
            <br />
            <div className="card-columns">
              {mappedStaff.map(staffmember => (
                <ActivityStaffCard
                  key={uuid.v4()}
                  staffmember={staffmember}
                ></ActivityStaffCard>
              ))}
            </div>
          </Col>
        </Row>
        {mappedStaff.length < 2 ? (
          <Alert ley={uuid.v4()} variant="light">
            *There has to be a minimum of 1 staff member assigned to an
            activity.
          </Alert>
        ) : null}
        <ActivityStaffModal
          show={this.state.modalShow}
          onHide={modalClose}
          staff={this.props.staff}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchEditActivityStartAsync: activityId =>
    dispatch(fetchEditActivityStartAsync(activityId))
});

const mapStateToProps = createStructuredSelector({
  staff: selectActivityToEditStaff,
  activity: selectEditActivity
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CopyActivityPage)
);
