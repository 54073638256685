import React from "react";
import { NavLink } from "react-router-dom";

import { MainDiv, FofDiv, FofH1 } from "./404-page.styles";

const FourOhFour = ({ history }) => {
  return (
    <MainDiv>
      <FofDiv>
        <FofH1>Error 404</FofH1>
        <br />

        <NavLink to="/"> Go Home </NavLink>
      </FofDiv>
    </MainDiv>
  );
};

export default FourOhFour;
