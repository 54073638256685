import { messageTypes } from "./message.types";

export const setMessage = message => ({
  type: messageTypes.SET_MESSAGE,
  payload: message
});

export const setErrorMessage = message => ({
  type: messageTypes.SET_ERROR_MESSAGE,
  payload: message
});

export const clearMessage = () => ({
  type: messageTypes.CLEAR_MESSAGES
});
