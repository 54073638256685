import { editActivityActionTypes } from './edit-activity-types';
import { saveActivityAsync } from '../roster/roster.actions';
import fileDownload from 'js-file-download';
import { setMessage, setErrorMessage } from '../message/message.actions';
import roster from '../../apis/roster';
import rosterDownload from '../../apis/rosterDownload';
import _ from 'lodash';
import {
  getUpdateTimeStamp,
  getShortFormattedDate,
  isDateBefore,
} from '../../utils/date.utils';
import {} from '../../utils/date.utils';
import history from '../../history';

export const fetchEditActivityStart = () => ({
  type: editActivityActionTypes.FETCH_EDIT_ACTIVITY_START,
});

export const fetchEditActivitySuccess = (data) => ({
  type: editActivityActionTypes.FETCH_EDIT_ACTIVITY_SUCCESS,
  payload: data,
});

export const fetchEditActivityFailure = (errorMessage) => (dispatch) => {
  dispatch({
    type: editActivityActionTypes.FETCH_EDIT_ACTIVITY_FAILURE,
  });
  dispatch(setErrorMessage(errorMessage));
};

export const updateActivityStart = () => ({
  type: editActivityActionTypes.UPDATE_ACTIVITY_START,
});

export const updateActivitySuccess = (activityId, activityData, message) => (
  dispatch,
) => {
  dispatch({
    type: editActivityActionTypes.UPDATE_ACTIVITY_SUCCESS,
    payload: { activityId: activityId, data: activityData },
  });
  dispatch(setMessage(message));
};

export const deleteActivityStaffMember = (staff) => ({
  type: editActivityActionTypes.DELETE_ACTIVITY_STAFF_MEMBER,
  payload: staff,
});

export const replaceActivityStaffMember = (staff) => ({
  type: editActivityActionTypes.REPLACE_ACTIVITY_STAFF_MEMBER,
  payload: staff,
});

export const handleError = (errorMessage) => ({
  type: editActivityActionTypes.HANDLE_ERROR,
  payload: errorMessage,
});

export const setAllStaffDilLength = (dilLength) => (dispatch, getState) => {
  const { activityToEdit } = getState();
  const newStaff = _.forEach(
    activityToEdit.staff,
    (staff) => (staff.dil_length = dilLength),
  );
  dispatch({
    type: editActivityActionTypes.UPDATE_ALL_STAFF_DIL_LENGTH,
    payload: newStaff,
  });
};

export const setAllStaffRole = (role) => (dispatch, getState) => {
  const { activityToEdit } = getState();
  const newStaff = _.forEach(
    activityToEdit.staff,
    (staff) => (staff.role = role),
  );
  dispatch({
    type: editActivityActionTypes.UPDATE_ALL_STAFF_ROLE,
    payload: newStaff,
  });
};

export const addStaffToActivity = (staffMember) => (dispatch, getState) => {
  const { activityToEdit } = getState();
  const newStaff = activityToEdit.staff;
  newStaff[staffMember.company_staff_id] = staffMember;

  dispatch({
    type: editActivityActionTypes.ADD_STAFF_MEMBER_TO_ACTIVITY,
    payload: newStaff,
  });
};

export const toggleConfirmStaffMember = (staffId, bool) => (
  dispatch,
  getState,
) => {
  const { activityToEdit } = getState();
  const newStaff = activityToEdit.staff;

  newStaff[staffId].confirmed = !newStaff[staffId].confirmed;

  dispatch({
    type: editActivityActionTypes.TOGGLE_STAFF_CONFIRMED,
    payload: newStaff,
  });
};

export const toggleConfirmActivity = (bool) => (dispatch, getState) => {
  const { activityToEdit } = getState();
  dispatch({
    type: editActivityActionTypes.TOGGLE_ACTIVITY_CONFIRMED,
    payload: !activityToEdit.confirmed,
  });
};

export const fetchEditActivityStartAsync = (activityId) => (
  dispatch,
  getState,
) => {
  const {
    user: {
      currentUser: { token },
    },
  } = getState();
  dispatch(fetchEditActivityStart());
  // set header for Authorization
  roster.defaults.headers.Authorization = token;

  roster
    .get(`/activitiesForWeek/edit/${activityId}`)
    .then((res) => {
      dispatch(fetchEditActivitySuccess(res.data.response.activityData));
    })
    .catch((error) =>
      dispatch(fetchEditActivityFailure(error.response.data.message)),
    );
};

export const deleteActivityStaffMemberStart = (staffId) => (
  dispatch,
  getState,
) => {
  const { activityToEdit } = getState();

  const newStaff = _.omit(activityToEdit.staff, [staffId]);
  dispatch(deleteActivityStaffMember(newStaff));
};

export const replaceActivityStaffMemberStart = (staffId, newStaffMember) => (
  dispatch,
  getState,
) => {
  const { activityToEdit } = getState();
  const { company_staff_id } = newStaffMember;
  const newStaff = activityToEdit.staff;
  const filteredStaff = _.omit(newStaff, [staffId]);
  filteredStaff[company_staff_id] = newStaffMember;

  dispatch(replaceActivityStaffMember(filteredStaff));
};

export const updateActivityAsync = (activityId, activityData) => (
  dispatch,
  getState,
) => {
  dispatch(updateActivityStart());
  const { user } = getState();
  activityData.company_id = user.currentUser.companyId;

  activityData.update_time = getUpdateTimeStamp();
  activityData.sequence = isDateBefore(
    activityData.startDate,
    activityData.endDate,
  )
    ? 1
    : 0;
  const redirectString = `/roster/${activityData.startDate}/${user.currentUser.companyId}`;

  const allStaffPruned = [];

  activityData.staff.forEach((staff) => {
    let isConfirmed = 0;
    if (staff.confirmed === true) {
      isConfirmed = 1;
    }

    const staffMember = {
      company_staff_id: staff.company_staff_id,
      role: staff.role,
      overtime: staff.overtime,
      discount: staff.discount,
      dil_length: staff.dil_length,
      confirmed: isConfirmed,
    };
    allStaffPruned.push(staffMember);
  });

  activityData.staffPruned = allStaffPruned;

  roster
    .put(`/activitiesForWeek/${activityId}`, activityData)

    .then(dispatch(saveActivityAsync(activityData)))
    .then(
      dispatch(
        updateActivitySuccess(
          activityId,
          activityData,
          `Activity ${activityId} has been updated`,
        ),
      ),
    )

    .then(() => history.push(redirectString))
    .catch((error) =>
      dispatch(fetchEditActivityFailure(error.response.data.message)),
    );
};

export const addActivityAsync = (activityData) => (dispatch, getState) => {
  dispatch(updateActivityStart());

  const { user, activityToEdit } = getState();

  activityData.company_id = user.currentUser.companyId;

  activityData.sequence = isDateBefore(
    activityData.startDate,
    activityData.endDate,
  )
    ? 1
    : 0;

  activityData.confirmed = activityToEdit.confirmed ? 1 : 0;
  activityData.note = activityData.note || '';
  activityData.update_time = getUpdateTimeStamp();

  const redirectString = `/roster/${activityData.startDate}/${user.currentUser.companyId}`;

  const allStaffPruned = [];

  activityData.staff.forEach((staff) => {
    const isConfirmed = staff.confirmed === false ? 0 : 1;
    const staffMember = {
      company_staff_id: staff.company_staff_id,
      role: staff.role,
      overtime: staff.overtime,
      discount: staff.discount,
      dil_length: staff.dil_length,
      confirmed: isConfirmed,
    };
    allStaffPruned.push(staffMember);
  });

  roster
    .post(`/activitiesForWeek/`, activityData)
    .then((response) => {
      const activityId = response.data.response.insertId;
      dispatch(saveActivityAsync(activityData));
      dispatch(
        updateActivitySuccess(activityId, activityData, `Activity added`),
      );
    })
    .then(() => history.push(redirectString))
    .catch((error) =>
      dispatch(fetchEditActivityFailure(error.response.data.message)),
    );
};

export const setupStaffForAddActivity = (staffId, activityDate) => (
  dispatch,
  getState,
) => {
  const {
    user: { currentUser },
    listData: { staff, roles },
  } = getState();

  console.log(currentUser);
  let selectedRoles =
    currentUser.companyId !== 3 ? roles : Object.values(roles);
  console.log(selectedRoles);
  dispatch({
    type: editActivityActionTypes.SETUP_ADD_ACTIVITY,
    payload: {
      startDate: getShortFormattedDate(activityDate),
      endDate: getShortFormattedDate(activityDate),
      staff: {
        [staffId]: {
          company_staff_id: staffId,
          role: currentUser.companyId !== 3 ? 4 : selectedRoles[0].id,
          overtime: 0,
          discount: 0,
          dil_length: 0,
          confirmed: false,
          first_name: staff[staffId].first_name,
          last_name: staff[staffId].last_name,
          email_address: staff[staffId].email_address,
          role_name:
            currentUser.companyId !== 3 ? roles[4].name : selectedRoles[0].name,
        },
      },
    },
  });
};

export const updateFiles = (fileItems) => (dispatch, getState) => {
  const {
    activityToEdit: { files },
  } = getState();

  // set header for Authorization
  const fileNames = [];
  fileItems.forEach((file) => fileNames.push(file.name));
  const deletedFile = _.filter(Object.values(files), (file) => {
    return !fileNames.includes(file.full_file_name);
  });

  const newFiles = _.remove(Object.values(files), (file) => {
    return fileNames.includes(file.full_file_name);
  });

  // console.log(roster.defaults.headers);
  if (deletedFile.length > 0) {
    roster
      .delete(`/upload/${deletedFile[0].id}`)
      .then((res) => {
        dispatch({
          type: editActivityActionTypes.UPDATE_FILES,
          payload: _.mapKeys(newFiles, 'id'),
        });
        dispatch(setMessage(res.data.response.message));
      })
      .catch((error) => dispatch(setErrorMessage(error.response.data.message)));
  }
};

export const downloadFile = (file) => (dispatch, getState) => {
  const { id, fileExtension, full_file_name } = file;

  rosterDownload
    .get(`/downloads/${id}.${fileExtension}`)
    .then((res) => fileDownload(res.data, `${full_file_name}.${fileExtension}`))
    .catch((error) => dispatch(setErrorMessage(error.response.data.message)));
};
