import React, { Component } from "react";

import ActivityTypesTable from "../../components/tables/activity-types-table/activity-types-table.component";

class ActivityTypesListPage extends Component {
  constructor() {
    super();
    this.state = { modalShow: false };
  }

  render() {
    return <ActivityTypesTable />;
  }
}

export default ActivityTypesListPage;
