import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router-dom";
import _ from "lodash";
// import history from "../../../history";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import TextInput from "../../form-inputs/text-input.component";
import SelectInput from "../../form-inputs/select-input.component";
import SelectVenueInput from "../../form-inputs/select-venue-input";
import TextareaInput from "../../form-inputs/textarea-input.component";

import {
  setAllStaffDilLength,
  setAllStaffRole,
  toggleConfirmActivity,
  updateActivityAsync,
  addActivityAsync
} from "../../../redux/edit-activity/edit-activity.actions";
import {
  selectIsActivityConfirmed,
  selectActivityToEditStaff
} from "../../../redux/edit-activity/edit-activity.selectors";
import { selectIsAdmin } from "../../../redux/user/user.selectors";
import {
  selectActivityTypes,
  selectClients,
  selectVenues,
  selectKits,
  selectLicenses,
  selectTrucks
} from "../../../redux/list-data/list-data.selectors";
import { setSelectedDate } from "../../../redux/settings/settings.actions";

class ActivityForm extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      isDirty: false,
      history: []
    };
  }

  onsubmit = formValues => {
    const {
      activityStaff,
      updateActivityAsync,
      initialValues,
      addActivityAsync,
      match
    } = this.props;

    formValues.staff = Object.values(activityStaff);
    if (!initialValues.id || match.path.includes("copy")) {
      addActivityAsync(formValues);
    } else {
      updateActivityAsync(initialValues.id, formValues);
    }
  };

  goBack() {
    const {
      history,
      match: {
        params: { from, dashId }
      },
      currentFormValues
    } = this.props;

    if (from === "dash") {
      history.push(`/dashboard/${dashId}/${currentFormValues.startDate}`);
    } else {
      history.goBack();
    }
  }

  handleDateChange = dateType => {
    const { change, currentFormValues } = this.props;
    const { startDate, endDate } = currentFormValues;

    if (dateType === "start") {
      if (!endDate) {
        change("endDate", startDate);
      }
    } else {
      if (!startDate) {
        change("startDate", endDate);
      }
    }
  };

  handleTypeChange = value => {
    const {
      venues,
      activityTypes,
      setAllStaffDilLength,
      trucks,
      kits,
      change
    } = this.props;

    const filteredVenue = _.filter(venues, venue => {
      return venue.name === activityTypes[value].name;
    });
    const name = activityTypes[value].name;
    const client = activityTypes[value].default_client || null;
    const truck = activityTypes[value].default_truck || null;

    change("name", name);

    if (filteredVenue.length !== 0) {
      change("venue", filteredVenue[0].id);
    } else {
      change("venue", activityTypes[value].default_venue);
    }

    change("client", client);
    change("truck", truck);
    if (truck) {
      const defaultKit = trucks[truck].default_kit || null;
      const defaultLicense = kits[defaultKit].default_license || null;
      change("kit", defaultKit);
      change("license", defaultLicense);
    }
    setAllStaffDilLength(activityTypes[value].dil_length);
    this.setState({ isDirty: true });
  };

  handleTruckChange = value => {
    const { trucks, kits, change } = this.props;

    const defaultKit = trucks[value].default_kit;
    const defaultLicense = kits[defaultKit].default_license;

    change("license", defaultLicense);
    change("kit", defaultKit);
  };

  render() {
    const {
      handleSubmit,
      isAdmin,
      activityTypes,
      clients,
      venues,
      kits,
      licenses,
      trucks,
      isActivityConfirmed,
      toggleConfirmActivity,
      initialValues
    } = this.props;

    if (initialValues.note === "null") {
      initialValues.note = "";
    }
    return (
      <Fragment>
        <Form onSubmit={handleSubmit(this.onsubmit)}>
          <Form.Row>
            <Col xs={12} md={6}>
              <Field
                disabled={!isAdmin}
                name="company_activity_type"
                label="Type"
                component={SelectInput}
                options={Object.values(activityTypes)}
                onChange={event => this.handleTypeChange(event.target.value)}
              />
            </Col>
            <Col xs={12} md={6}>
              <Field
                disabled={!isAdmin}
                name="client"
                label="Client"
                component={SelectInput}
                options={Object.values(clients)}
                onChange={() => this.setState({ isDirty: true })}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={12} md={12}>
              <Field
                disabled={!isAdmin}
                type="text"
                name="name"
                label="Name"
                component={TextInput}
                onChange={() => this.setState({ isDirty: true })}
              />
            </Col>
          </Form.Row>

          <Form.Row>
            <Col xs={12} md={4}>
              <Field
                disabled={!isAdmin}
                type="date"
                name="startDate"
                label="Start Date"
                component={TextInput}
                onChange={this.handleDateChange("start")}
              />
            </Col>
            <Col xs={12} md={4}>
              <Field
                disabled={!isAdmin}
                type="date"
                name="endDate"
                label="End Date"
                component={TextInput}
                onChange={this.handleDateChange("end")}
              />
            </Col>
            <Col xs={12} md={4}>
              <Field
                disabled={!isAdmin}
                name="venue"
                label="Venue"
                component={SelectVenueInput}
                options={Object.values(venues)}
                onChange={() => this.setState({ isDirty: true })}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={12} md={4}>
              <Field
                disabled={!isAdmin}
                name="truck"
                label="Truck"
                component={SelectInput}
                options={Object.values(trucks)}
                onChange={event => this.handleTruckChange(event.target.value)}
              />
            </Col>
            <Col xs={12} md={4}>
              <Field
                disabled={!isAdmin}
                name="license"
                label="License"
                component={SelectInput}
                options={Object.values(licenses)}
                onChange={() => this.setState({ isDirty: true })}
              />
            </Col>
            <Col xs={12} md={4}>
              <Field
                disabled={!isAdmin}
                name="kit"
                label="Kit"
                component={SelectInput}
                options={Object.values(kits)}
                onChange={() => this.setState({ isDirty: true })}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Field
                disabled={!isAdmin}
                name="note"
                label="Notes"
                component={TextareaInput}
                onChange={() => this.setState({ isDirty: true })}
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Button
                disabled={!isAdmin}
                name="confirmed"
                variant={isActivityConfirmed ? "success" : "outline-success"}
                onClick={toggleConfirmActivity}
              >
                {isActivityConfirmed ? "Confirmed" : "Confirm"}
              </Button>
            </Col>
            <Col>
              {isAdmin ? (
                <Fragment>
                  <Button type="submit">Submit</Button>{" "}
                  <Button type="button" variant="danger" onClick={this.goBack}>
                    Cancel
                  </Button>
                </Fragment>
              ) : (
                <Button onClick={this.goBack}>Close</Button>
              )}
            </Col>
          </Form.Row>
          <br />

          <Form.Row></Form.Row>
        </Form>
      </Fragment>
    );
  }
}

const formSelector = formValueSelector("activityForm");

const validate = formValues => {
  const errors = {};
  const requiredFields = [
    "name",
    "company_activity_type",
    "client",
    "startDate",
    "endDate",
    "venue",
    "truck",
    "license",
    "kit"
  ];
  requiredFields.forEach(field => {
    if (!formValues[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

const mapStateToProps = createStructuredSelector({
  isAdmin: selectIsAdmin,
  activityTypes: selectActivityTypes,
  clients: selectClients,
  venues: selectVenues,
  kits: selectKits,
  licenses: selectLicenses,
  trucks: selectTrucks,
  activityStaff: selectActivityToEditStaff,
  isActivityConfirmed: selectIsActivityConfirmed,
  currentFormValues: state =>
    formSelector(
      state,
      "name",
      "note",
      "company_activity_type",
      "client",
      "startDate",
      "endDate",
      "venue",
      "truck",
      "license",
      "kit",
      "confirmed"
    )
});

const mapDispatchToProps = dispatch => ({
  setAllStaffDilLength: dilLength => dispatch(setAllStaffDilLength(dilLength)),
  setAllStaffRole: role => dispatch(setAllStaffRole(role)),
  toggleConfirmActivity: () => dispatch(toggleConfirmActivity()),
  updateActivityAsync: (activityId, activityData) =>
    dispatch(updateActivityAsync(activityId, activityData)),
  addActivityAsync: activityData => dispatch(addActivityAsync(activityData)),
  setSelectedDate: date => dispatch(setSelectedDate(date))
});

const formWrapper = reduxForm({
  form: "activityForm",
  enableReinitialize: true,
  touchOnChange: true,
  validate
})(ActivityForm);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(formWrapper)
);
