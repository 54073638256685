export const editActivityActionTypes = {
  FETCH_EDIT_ACTIVITY_START: "FETCH_EDIT_ACTIVITY_START",
  FETCH_EDIT_ACTIVITY_SUCCESS: "FETCH_EDIT_ACTIVITY_SUCCESS",
  FETCH_EDIT_ACTIVITY_FAILURE: "FETCH_EDIT_ACTIVITY_FAILURE",
  DELETE_ACTIVITY_STAFF_MEMBER: "DELETE_ACTIVITY_STAFF_MEMBER",
  HANDLE_ERROR: "HANDLE_ERROR",
  TOGGLE_STAFF_CONFIRMED: "TOGGLE_STAFF_CONFIRMED",
  UPDATE_ALL_STAFF_DIL_LENGTH: "UPDATE_ALL_STAFF_DIL_LENGTH",
  UPDATE_ALL_STAFF_ROLE: "UPDATE_ALL_STAFF_ROLE",
  TOGGLE_ACTIVITY_CONFIRMED: "TOGGLE_ACTIVITY_CONFIRMED",
  ADD_STAFF_MEMBER_TO_ACTIVITY: "ADD_STAFF_MEMBER_TO_ACTIVITY",
  SAVE_ACTIVITY_START: "SAVE_ACTIVITY_START",
  SAVE_ACTIVITY_SUCCESS: "SAVE_ACTIVITY_SUCCESS",
  SAVE_ACTIVITY_ERROR: "SAVE_ACTIVITY_ERROR",
  UPDATE_ACTIVITY_START: "UPDATE_ACTIVITY_START",
  UPDATE_ACTIVITY_SUCCESS: "UPDATE_ACTIVITY_SUCCESS",
  UPDATE_ACTIVITY_FAILURE: "UPDATE_ACTIVITY_FAILURE",
  SETUP_ADD_ACTIVITY: "SETUP_ADD_ACTIVITY",
  REPLACE_ACTIVITY_STAFF_MEMBER: "REPLACE_ACTIVITY_STAFF_MEMBER",
  SET_MESSAGE: "SET_MESSAGE",
  CLEAR_MESSAGE: "CLEAR_MESSAGE",
  UPDATE_FILES: "UPDATE_FILES"
};
