import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import TextInput from "../../form-inputs/text-input.component";

import { selectCompanyId } from "../../../redux/user/user.selectors";
import {
  addClientAsync,
  updateClientAsync
} from "../../../redux/list-data/list-data.actions";

class ClientsForm extends Component {
  onSubmit = formValues => {
    const {
      addClientAsync,
      updateClientAsync,
      onHide,
      companyId,
      initialValues
    } = this.props;
    formValues.company_id = companyId;
    if (!initialValues.name) {
      addClientAsync(formValues);
    } else {
      updateClientAsync(formValues);
    }
    onHide();
  };

  render() {
    const { handleSubmit, onHide, initialValues } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="name"
          component={TextInput}
          type="text"
          label={initialValues.name ? "Client Name" : null}
          placeholder="Client Name"
        />
        <Field
          name="location"
          component={TextInput}
          type="text"
          label={initialValues.name ? "Location" : null}
          placeholder="Location"
        />
        <Field
          name="short_name"
          component={TextInput}
          type="text"
          label={initialValues.name ? "Short Name" : null}
          placeholder="Short Name"
        />
        <Button type="submit" label="submit">
          Submit
        </Button>{" "}
        <Button type="button" variant="danger" onClick={onHide} label="submit">
          Cancel
        </Button>
      </Form>
    );
  }
}

const validate = formValues => {
  const errors = {};
  const requiredFields = ["name"];
  requiredFields.forEach(field => {
    if (!formValues[field]) {
      errors[field] = "Required";
    }
  });

  return errors;
};

const mapStateToProps = createStructuredSelector({
  companyId: selectCompanyId
});

const mapDispatchToProps = dispatch => ({
  addClientAsync: formValues => dispatch(addClientAsync(formValues)),
  updateClientAsync: formValues => dispatch(updateClientAsync(formValues))
});

const formWrapper = reduxForm({
  form: "addToRolesList",
  validate
})(ClientsForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(formWrapper);
