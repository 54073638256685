import React from "react";
import Gravatar, { GravatarOptions } from "react-awesome-gravatar";
import Image from "react-bootstrap/Image";

const GravatarImage = ({ email, name }) => {
  const options: GravatarOptions = {
    size: 50
  };

  return (
    <Gravatar email={email} options={options}>
      {url => <Image src={url} alt={name} roundedCircle />}
    </Gravatar>
  );
};

export default GravatarImage;
