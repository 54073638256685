import React from "react";
import uuid from "uuid";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const GenericModal = props => {
  const { title, text, buttons } = props.data;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>
      <Modal.Footer>
        {buttons.map(button => (
          <Button
            key={uuid.v4()}
            variant={button.variant}
            onClick={button.onClick}
          >
            {button.text}
          </Button>
        ))}
        <Button variant="danger" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GenericModal;
