import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ReactTableContainer from "react-table-container";
import uuid from "uuid";
import _ from "lodash";
import fuzzyFilterFactory from "react-fuzzy-filter";
import {
  hexToRgb,
  determineActivityTextColour
} from "../../../utils/colour.utils";
import { toast } from "react-toastify";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WithSpinner from "../../spinner/spinner.component";
import ActivityTypeListModal from "../../modals/activity-types-list/activity-types-list.component";
import GenericModal from "../../modals/generic/generic-modal.component";
import { StyledSection } from "../../activity/activity.styles";

import {
  selectErrorMessage,
  selectMessage
} from "../../../redux/message/message.selectors";
import { clearMessage } from "../../../redux/message/message.actions";
import {
  selectActivityTypes,
  selectRefresh
} from "../../../redux/list-data/list-data.selectors";
import {
  deleteActivityTypeAsync,
  setRefreshFalse
} from "../../../redux/list-data/list-data.actions";
const { InputFilter, FilterResults } = fuzzyFilterFactory();

class ActivityTypesTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false,
      modalDeleteShow: false,
      activityTypes: [],
      activity: { dil_length: 0 },
      deleteId: null
    };
  }

  modalClose = () => this.setState({ modalShow: false });

  renderActivityTypesModal = () => (
    <ActivityTypeListModal
      show={this.state.modalShow}
      onHide={this.modalClose}
      activity={this.state.activity}
    />
  );

  componentDidMount() {
    const { activityTypes } = this.props;
    const activityTypeValues = Object.values(activityTypes);
    this.setState({
      activityTypes: activityTypeValues
    });
  }

  componentDidUpdate() {
    const {
      needsRefresh,
      setRefreshFalse,
      activityTypes,
      message,
      errorMessage,
      clearMessage
    } = this.props;
    const activityTypeValues = Object.values(activityTypes);
    if (needsRefresh) {
      this.setState({
        activityTypes: activityTypeValues
      });
      setRefreshFalse();
    }
    if (message) {
      toast.success(message);
      clearMessage();
    }
    if (errorMessage) {
      toast.error(errorMessage);
      clearMessage();
    }
  }

  handleDeleteActivityTypeClick = e => {
    this.setState({ genericModalShow: true, deleteId: e.target.value });
  };

  handleConfirmDeleteActivityTypeClick = () => {
    this.setState({ genericModalShow: false, deleteId: null });
    const { deleteActivityTypeAsync } = this.props;
    const { deleteId } = this.state;
    deleteActivityTypeAsync(deleteId);
  };

  render() {
    const { activityTypes } = this.state;

    if (activityTypes.length < 1) {
      return <WithSpinner message="Loading Activity Types..." />;
    }

    const deleteActivityModalData = {
      title: "Hold your horses!!",
      text:
        "You're about to delete an activity type, are you sure you want to do that?",

      buttons: [
        {
          variant: "primary",
          text: "Yes",
          onClick: () => this.handleConfirmDeleteActivityTypeClick()
        }
      ]
    };
    const fuseConfig = {
      caseSensitive: false,
      keys: ["name"]
    };

    return (
      <Fragment>
        <Row>
          <Col md={10}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Search</Form.Label>
              <InputFilter
                debounceTime={200}
                inputProps={{ className: "form-control" }}
              />
              <Form.Text className="text-muted">
                Enter search params above to filter the below list
              </Form.Text>
            </Form.Group>
          </Col>
          <Col style={{ alignItems: "center" }}>
            <br />
            <Button
              variant="primary"
              onClick={() =>
                this.setState({ modalShow: true, activity: { dil_length: 0 } })
              }
            >
              Add Activity Type
            </Button>
          </Col>
        </Row>
        <ReactTableContainer width="100%" height="80vh">
          <Table striped bordered hover responsive="sm">
            <colgroup>
              <col
                span="1"
                className=""
                style={{ backgroundColor: "#FFFFFF" }}
              />
              <col
                span="1"
                className=""
                style={{ backgroundColor: "#FFFFFF" }}
              />
              <col
                span="1"
                className=""
                style={{ backgroundColor: "#FFFFFF" }}
              />
            </colgroup>
            <thead>
              <tr>
                <td>Id</td>
                <td>Name</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              <FilterResults
                items={_.orderBy(activityTypes, ["name"], ["asc"])}
                fuseConfig={fuseConfig}
              >
                {filteredItems => {
                  return filteredItems.map(activity => {
                    const rgbColour = hexToRgb(activity.colour);

                    const sectionStyle = {
                      background: `rgba(${rgbColour.red}, ${rgbColour.green}, ${rgbColour.blue}, 0.7)`,
                      color: `${determineActivityTextColour(activity.colour)}`,
                      borderColor: `#${activity.colour}`
                    };

                    return (
                      <tr key={uuid.v4()}>
                        <td>{activity.id}</td>
                        <td>
                          <StyledSection style={sectionStyle} key={uuid.v4()}>
                            {activity.name}
                          </StyledSection>
                        </td>
                        <td>
                          <Button
                            variant="primary"
                            onClick={() =>
                              this.setState({
                                modalShow: true,
                                modalDeleteShow: false,
                                activity: activity
                              })
                            }
                          >
                            Edit
                          </Button>{" "}
                          <Button
                            variant="danger"
                            onClick={this.handleDeleteActivityTypeClick}
                            value={activity.id}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  });
                }}
              </FilterResults>
            </tbody>
          </Table>
        </ReactTableContainer>

        {this.renderActivityTypesModal()}
        <GenericModal
          show={this.state.genericModalShow}
          onHide={() => this.setState({ genericModalShow: false })}
          data={deleteActivityModalData}
        ></GenericModal>
      </Fragment>
    );
  }
}

const mapPropsToState = createStructuredSelector({
  activityTypes: selectActivityTypes,
  needsRefresh: selectRefresh,
  message: selectMessage,
  errorMessage: selectErrorMessage
});

const mapDispatchToProps = dispatch => ({
  deleteActivityTypeAsync: typeId => dispatch(deleteActivityTypeAsync(typeId)),
  setRefreshFalse: () => dispatch(setRefreshFalse()),
  clearMessage: () => dispatch(clearMessage())
});

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(ActivityTypesTable);
