import React from "react";
import "./spinner.styles.scss";

const WithSpinner = ({ message }) => {
  return (
    <div id="wrapper">
      <h1>{message}</h1>
    </div>
  );
};

export default WithSpinner;
