import React, { Component } from "react";

import VenuesTable from "../../components/tables/venues-table/venues-table.components";

class VenueListPage extends Component {
  render() {
    return <VenuesTable />;
  }
}

export default VenueListPage;
