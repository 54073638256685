import { calendarActivityTypes } from "./calendar-types";

const INITIAL_STATE = {
  activities: [],
  isFetching: false,
  errorMessage: null
};

const calendarActivityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case calendarActivityTypes.FETCH_CALENDAR_ACTIVITIES_START:
      return { ...state, isFetching: true };

    case calendarActivityTypes.FETCH_CALENDAR_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: action.payload,
        isFetching: false
      };

    case calendarActivityTypes.FETCH_CALENDAR_ACTIVITIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      };

    default:
      return state;
  }
};

export default calendarActivityReducer;
