import { SettingsActionTypes } from "./settings.types";
import { getShortFormattedDate } from "../../utils/date.utils";

const INITIAL_STATE = {
  headerVisible: true,
  hideEmpty: false,
  selectedDate: getShortFormattedDate(new Date())
};

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SettingsActionTypes.SET_ROSTER_HEADER_VISIBLE:
      return { ...state, headerVisible: action.payload };

    case SettingsActionTypes.SET_UNUSED_VISIBLE:
      return { ...state, hideEmpty: action.payload };

    case SettingsActionTypes.UPDATE_CURRENT_DATE:
      return { ...state, selectedDate: action.payload };
    default:
      return state;
  }
};

export default settingsReducer;
