import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import userReducer from "./user/user.reducer";
import rosterReducer from "./roster/roster.reducer";
import listDataReducer from "./list-data/list-data.reducer";
import editActivityReducer from "./edit-activity/edit-activity-reducer";
import calendarActivityReducer from "./calendar/calendar-reducer";
import messageReducer from "./message/message.reducer";
import settingsReducer from "./settings/settings.reducer";

const rootReducer = combineReducers({
  form: formReducer,
  user: userReducer,
  roster: rosterReducer,
  listData: listDataReducer,
  activityToEdit: editActivityReducer,
  calendar: calendarActivityReducer,
  messages: messageReducer,
  settings: settingsReducer
});

export default rootReducer;
