import React, { Component } from "react";

import ActivityRolesTable from "../../components/tables/activity-roles-table/activity-roles-table.component";

class ActivityRolesListPage extends Component {
  constructor() {
    super();
    this.state = { modalShow: false };
  }

  render() {
    return <ActivityRolesTable />;
  }
}

export default ActivityRolesListPage;
