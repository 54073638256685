import React from "react";
import ReactDOM from "react-dom";

import Modal from "react-bootstrap/Modal";
import StaffListForm from "../../forms/activity-staff-from/activity-staff-form.component";

const ActivityStaffModal = props => {
  return ReactDOM.createPortal(
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Staff</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StaffListForm
          currentstaff={props.currentstaff}
          onHide={props.onHide}
        />
      </Modal.Body>
    </Modal>,
    document.querySelector("#modal")
  );
};

export default ActivityStaffModal;
