import React, { Component } from "react";

import StaffTable from "../../components/tables/staff-table/staff-table.component";

class StaffListPage extends Component {
  constructor() {
    super();
    this.state = { modalShow: false };
  }

  render() {
    return <StaffTable />;
  }
}

export default StaffListPage;
