import React from "react";
import ReactDOM from "react-dom";

import Modal from "react-bootstrap/Modal";
import LicenseForm from "../../forms/license-form/license-form.component";

const LicensesListModal = props => {
  const { license } = props;

  return ReactDOM.createPortal(
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {license.name ? "Edit" : "Add"} License
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LicenseForm onHide={props.onHide} initialValues={license} />
      </Modal.Body>
    </Modal>,
    document.querySelector("#modal")
  );
};

export default LicensesListModal;
