import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { NavLink } from "react-router-dom";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import TextInput from "../../form-inputs/text-input.component";

class SignInForm extends Component {
  render() {
    const { handleSubmit } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Field
          name="email"
          component={TextInput}
          type="text"
          placeholder="Username"
        />
        <Field
          name="password"
          component={TextInput}
          type="password"
          placeholder="Password"
        />
        <NavLink to="/forgot_password">Forgot Password</NavLink>
        <br />
        <br />
        <Button type="submit" label="submit">
          Submit
        </Button>
      </Form>
    );
  }
}

const validate = formValues => {
  const errors = {};
  const requiredFields = ["email", "password"];
  requiredFields.forEach(field => {
    if (!formValues[field]) {
      errors[field] = "Required";
    }
  });
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
    errors.email = "Invalid email address";
  }

  return errors;
};

SignInForm = reduxForm({
  form: "signin",
  validate
})(SignInForm);

export default SignInForm;
