import { UserActionTypes } from "./user.types";

const INITIAL_STATE = {
  currentUser: { admin: false },
  isFetching: false
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SIGN_IN_START:
      return {
        ...state,
        isFetching: true
      };

    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentUser: action.payload
      };

    case UserActionTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        isFetching: false
      };

    case UserActionTypes.CONFIRM_EMAIL:
      return { ...state, currentUser: { ...state.currentUser, confirmed: 1 } };

    case UserActionTypes.SET_DEFAULT_PAGE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          defaultPage: Number(action.payload)
        }
      };

    case UserActionTypes.SIGN_OUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default userReducer;
