import React, { Component } from "react";

import LicensesTable from "../../components/tables/license-table/license-table.components";

class LicensesListPage extends Component {
  render() {
    return <LicensesTable />;
  }
}

export default LicensesListPage;
