import { rosterActionTypes } from "./roster.types";

import { setMessage, setErrorMessage } from "../message/message.actions";
import roster from "../../apis/roster";
import * as utils from "./roster.utils";
import {
  getShortFormattedDate,
  getWeekOfDates,
  getYearMonthDayUnFormatted
} from "../../utils/date.utils";
import _ from "lodash";

export const setDateRange = date => dispatch => {
  const dateRange = getWeekOfDates(date);
  dispatch({ type: rosterActionTypes.SET_DATE_RANGE, payload: dateRange });
};

export const fetchRosterDataStart = () => ({
  type: rosterActionTypes.FETCH_ROSTER_START
});

export const fetchRosterDataSuccess = data => ({
  type: rosterActionTypes.FETCH_ROSTER_SUCCESS,
  payload: data
});

export const fetchRosterDataFailure = errorMessage => dispatch => {
  dispatch({
    type: rosterActionTypes.FETCH_ROSTER_FAILURE
  });
  dispatch(setErrorMessage(errorMessage));
};

export const fetchRosterDataAsync = (
  selectedDate,
  companyId,
  userId
) => dispatch => {
  dispatch(fetchRosterDataStart());
  roster
    .get(
      `/activitiesForWeek/${getShortFormattedDate(
        selectedDate
      )}/${companyId}/0/0/${userId}`
    )
    .then(res => {
      dispatch(fetchRosterDataSuccess(res.data.response));
    })
    .catch(error =>
      dispatch(fetchRosterDataFailure(error.response.data.message))
    );
};

export const fetchRosterDataSinglePersonAsync = (
  selectedDate,
  userId
) => dispatch => {
  dispatch(fetchRosterDataStart());
  roster
    .get(
      `/activitiesForWeek/single/${userId}/${getShortFormattedDate(
        selectedDate
      )}`
    )
    .then(res => {
      dispatch(fetchRosterDataSuccess(res.data.response));
    })
    .catch(error =>
      dispatch(fetchRosterDataFailure(error.response.data.message))
    );
};

export const saveActivityAsync = activity => (dispatch, getState) => {
  const strippedDate = activity.startDate.replace(
    // eslint-disable-next-line no-useless-escape
    /[.,\/#!$%\^&\*;:{}=\-_`~()]/g,
    ""
  );
  activity.hashedDate = `${strippedDate}${activity.id}`;

  utils.prepareActivityForSaving(activity);

  dispatch({
    type: rosterActionTypes.SAVE_ACTIVITY,
    payload: activity
  });
};

export const deleteActivity = activityId => (dispatch, getState) => {
  const { activities } = getState().roster;

  const newActivities = _.filter(activities, activity => {
    return activity.id !== activityId;
  });
  dispatch(fetchRosterDataStart());
  roster
    .delete(`/activitiesForWeek/${activityId}`)
    .then(res => {
      dispatch({
        type: rosterActionTypes.DELETE_ACTIVITY,
        payload: newActivities
      });
      dispatch(setMessage(res.data.response.message));
    })

    .catch(error =>
      dispatch(fetchRosterDataFailure(error.response.data.message))
    );
};

export const deleteSingleDateFromSequenceActivity = (activityId, date) => (
  dispatch,
  getState
) => {
  const { activities } = getState().roster;
  const { day, month, year } = getYearMonthDayUnFormatted(date);
  const hashedId = Number(
    `${year.trim()}${month.trim()}${day.trim()}${activityId}`
  );
  const newActivities = _.omit(activities, hashedId);
  dispatch(fetchRosterDataStart());
  roster
    .delete(`/activitiesForWeek/sequence/${activityId}/${date}`)
    .then(res => {
      dispatch({
        type: rosterActionTypes.DELETE_ACTIVITY,
        payload: newActivities
      });
      dispatch(setMessage(res.data.response.message));
    })
    .catch(error =>
      dispatch(fetchRosterDataFailure(error.response.data.message))
    );
};

export const deleteStaffFromRosterActivity = (activityId, staffId) => (
  dispatch,
  getState
) => {
  const { activities } = getState().roster;
  const { staff } = getState().listData;
  const activitiesValues = Object.values(activities);

  const filteredStaff = _.find(staff, s => {
    return s.person_id === staffId;
  });

  activitiesValues.forEach(activity => {
    if (activity.id === activityId) {
      _.remove(activity.staff, s => {
        return s === staffId;
      });
      _.remove(activity.confirmedStaff, s => {
        return s === staffId;
      });
    }
  });

  roster
    .delete(
      `/activityStaff/removeStaff/${activityId}/${filteredStaff.staff_id}`
    )
    .then(res => {
      dispatch({
        type: rosterActionTypes.DELETE_STAFF_FROM_ACTIVITY,
        payload: _.mapKeys(activitiesValues, "hashedId")
      });
      dispatch(setMessage(res.data.response.message));
    })
    .catch(error =>
      dispatch(fetchRosterDataFailure(error.response.data.message))
    );
};
