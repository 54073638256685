import { messageTypes } from "./message.types";

const INITIAL_STATE = {
  errorMessage: undefined,
  message: undefined
};

const messageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case messageTypes.SET_MESSAGE:
      return { ...state, message: action.payload };
    case messageTypes.SET_ERROR_MESSAGE:
      return { ...state, errorMessage: action.payload };
    case messageTypes.CLEAR_MESSAGES:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default messageReducer;
