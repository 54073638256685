import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ReactTableContainer from "react-table-container";
import uuid from "uuid";
import _ from "lodash";
import fuzzyFilterFactory from "react-fuzzy-filter";
import { toast } from "react-toastify";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WithSpinner from "../../spinner/spinner.component";
import KitsListModal from "../../modals/kits-list/kits-list.component";
import GenericModal from "../../modals/generic/generic-modal.component";

import {
  selectErrorMessage,
  selectMessage
} from "../../../redux/message/message.selectors";
import { clearMessage } from "../../../redux/message/message.actions";
import {
  selectKits,
  selectRefresh
} from "../../../redux/list-data/list-data.selectors";
import { selectCompanyId } from "../../../redux/user/user.selectors";
import {
  deleteKitAsync,
  setRefreshFalse
} from "../../../redux/list-data/list-data.actions";
const { InputFilter, FilterResults } = fuzzyFilterFactory();

class ClientsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false,
      modalDeleteShow: false,
      kits: [],
      kit: { default_license: 0 },
      genericModalShow: false
    };
  }

  componentDidMount() {
    const { kits } = this.props;
    const kitValues = Object.values(kits);
    this.setState({
      kits: _.orderBy(kitValues, ["name"], ["asc"])
    });
  }

  componentDidUpdate() {
    const {
      needsRefresh,
      setRefreshFalse,
      kits,
      message,
      errorMessage,
      clearMessage
    } = this.props;
    const kitValues = Object.values(kits);
    if (needsRefresh) {
      this.setState({
        kits: kitValues
      });
      setRefreshFalse();
    }
    if (message) {
      toast.success(message);
      clearMessage();
    }
    if (errorMessage) {
      toast.error(errorMessage);
      clearMessage();
    }
  }

  modalClose = () => this.setState({ modalShow: false });

  renderActivityRoleModal = () => (
    <KitsListModal
      show={this.state.modalShow}
      onHide={this.modalClose}
      kit={this.state.kit}
    />
  );

  handleDeleteKitClick = e => {
    this.setState({ genericModalShow: true, deleteId: e.target.value });
  };

  handleConfirmDeleteKitClick = () => {
    const { deleteKitAsync } = this.props;
    const { deleteId } = this.state;
    deleteKitAsync(deleteId);
    this.setState({ genericModalShow: false, deleteId: null });
  };

  render() {
    const { kits } = this.state;

    if (kits.length < 1) {
      return <WithSpinner message="Loading kits..." />;
    }

    const deleteActivityRoleModalData = {
      title: "Hold your horses!!",
      text: "You're about to delete a kit, are you sure you want to do that?",

      buttons: [
        {
          variant: "primary",
          text: "Yes",
          onClick: () => this.handleConfirmDeleteKitClick()
        }
      ]
    };

    const fuseConfig = {
      caseSensitive: false,
      keys: ["name"]
    };

    return (
      <Fragment>
        <Row>
          <Col md={10}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Search</Form.Label>
              <InputFilter
                debounceTime={200}
                inputProps={{ className: "form-control" }}
              />
              <Form.Text className="text-muted">
                Enter search params above to filter the below list
              </Form.Text>
            </Form.Group>
          </Col>
          <Col style={{ alignItems: "center" }}>
            <br />
            <Button
              variant="primary"
              onClick={() => this.setState({ modalShow: true, kit: {} })}
            >
              Add Kit
            </Button>
          </Col>
        </Row>
        <ReactTableContainer width="100%" height="80vh">
          <Table striped bordered hover responsive="sm">
            <colgroup>
              <col
                span="1"
                className=""
                style={{ backgroundColor: "#FFFFFF" }}
              />
              <col
                span="1"
                className=""
                style={{ backgroundColor: "#FFFFFF" }}
              />
              <col
                span="1"
                className=""
                style={{ backgroundColor: "#FFFFFF" }}
              />
            </colgroup>
            <thead>
              <tr>
                <td>Id</td>
                <td>Name</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              <FilterResults
                items={_.orderBy(kits, ["id"], ["asc"])}
                fuseConfig={fuseConfig}
              >
                {filteredItems => {
                  return filteredItems.map(kit => {
                    return (
                      <tr key={uuid.v4()}>
                        <td>{kit.id}</td>
                        <td> {kit.name}</td>
                        <td>
                          <Button
                            variant="primary"
                            onClick={() =>
                              this.setState({
                                modalShow: true,
                                modalDeleteShow: false,
                                kit: kit
                              })
                            }
                          >
                            Edit
                          </Button>{" "}
                          <Button
                            variant="danger"
                            onClick={this.handleDeleteKitClick}
                            value={kit.id}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  });
                }}
              </FilterResults>
            </tbody>
          </Table>
        </ReactTableContainer>
        {this.renderActivityRoleModal()}
        <GenericModal
          show={this.state.genericModalShow}
          onHide={() => this.setState({ genericModalShow: false })}
          data={deleteActivityRoleModalData}
        ></GenericModal>
      </Fragment>
    );
  }
}

const mapPropsToState = createStructuredSelector({
  kits: selectKits,
  companyId: selectCompanyId,
  needsRefresh: selectRefresh,
  message: selectMessage,
  errorMessage: selectErrorMessage
});

const mapDispatchToProps = dispatch => ({
  deleteKitAsync: typeId => dispatch(deleteKitAsync(typeId)),
  setRefreshFalse: () => dispatch(setRefreshFalse()),
  clearMessage: () => dispatch(clearMessage())
});

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(ClientsTable);
