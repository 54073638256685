import React, { Fragment } from "react";
import { useMediaQuery } from "react-responsive";

import RosterTableContainer from "../../components/roster-table/roster-table.component";
import RosterTableMobile from "../../components/roster-table-mobile/roster-table-mobile.component";

const RosterPage = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)"
  });

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)"
  });

  const isPortrait = useMediaQuery({ orientation: "portrait" });
  const isLandscape = useMediaQuery({ orientation: "landscape" });

  return (
    <Fragment>
      {isDesktopOrLaptop && <RosterTableContainer />}
      {isTabletOrMobileDevice && isPortrait && <RosterTableMobile />}
      {isTabletOrMobileDevice && isLandscape && <RosterTableContainer />}
    </Fragment>
  );
};

export default RosterPage;
