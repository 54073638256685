import { createSelector } from "reselect";

const selectUser = state => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  user => user.currentUser
);

export const selectIsAdmin = createSelector(
  [selectCurrentUser],
  user => user.admin
);

export const selectCompanyId = createSelector(
  [selectCurrentUser],
  user => user.companyId
);

export const selectCompanyStaffId = createSelector(
  [selectCurrentUser],
  user => user.companyStaffId
);

export const selectEmailAddress = createSelector(
  [selectCurrentUser],
  user => user.email
);

export const selectIsCurrentUserFetching = createSelector(
  [selectUser],
  user => user.isFetching
);

export const selectIsCurrentUserLoaded = createSelector(
  [selectUser],
  user => !!user.currentUser
);

export const selectErrorMessage = createSelector(
  [selectUser],
  user => user.errorMessage
);

export const selectMessage = createSelector(
  [selectUser],
  user => user.message
);

export const selectConfirmed = createSelector(
  [selectCurrentUser],
  user => !!user.confirmed
);

export const selectToken = createSelector(
  [selectCurrentUser],
  user => user.token
);
