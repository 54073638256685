import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import GravatarImage from '../gravatar/gravatar.component';
import EditActivityStaffModal from '../modals/edit-activity-staff-modal/edit-activity-staff-modal.component';
import GenericModal from '../modals/generic/generic-modal.component';

import { selectActivityRoles } from '../../redux/list-data/list-data.selectors';
import {
  selectIsAdmin,
  selectCurrentUser,
} from '../../redux/user/user.selectors';
import {
  deleteActivityStaffMemberStart,
  toggleConfirmStaffMember,
} from '../../redux/edit-activity/edit-activity.actions';
import { selectActivityToEditStaff } from '../../redux/edit-activity/edit-activity.selectors';

class ActivityStaffCard extends Component {
  constructor() {
    super();

    this.state = {
      editModalShow: false,
      genericModalShow: false,
    };
  }
  handleDeleteStaffConfirmClick = () => {
    const { staffmember, deleteActivityStaffMemberStart } = this.props;

    this.setState({ genericModalShow: false });
    deleteActivityStaffMemberStart(staffmember.company_staff_id);
  };

  handleDeleteStaffClick = () => {
    this.setState({ genericModalShow: true });
  };

  handleConfirmStaffClick = () => {
    const { staffmember, toggleConfirmStaffMember } = this.props;

    toggleConfirmStaffMember(
      staffmember.company_staff_id,
      !staffmember.confirmed,
    );
  };

  render() {
    const {
      currentUser,
      staffmember,
      activityRoles,
      isAdmin,
      staff,
    } = this.props;
    console.log(activityRoles);

    const deleteActivityModalData = {
      title: 'Hold your horses!!',
      text:
        "You're about to delete a staff member from an activity, are you sure you want to do that?",

      buttons: [
        {
          variant: 'primary',
          text: 'Yes',
          onClick: () => this.handleDeleteStaffConfirmClick(),
        },
      ],
    };

    return (
      <Fragment>
        <Card>
          <Card.Body>
            <Card.Title
              className='card-title'
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <div style={{ flex: 1 }}>
                <GravatarImage
                  email={staffmember.email_address}
                  name={`${staffmember.first_name} ${staffmember.last_name}`}
                ></GravatarImage>
              </div>
              <div style={{ verticalAlign: 'center', flex: 2 }}>
                {`${staffmember.first_name} ${staffmember.last_name}`}
              </div>
            </Card.Title>
            <strong>Role:</strong> {activityRoles[staffmember.role].name}
            {isAdmin ||
            staffmember.company_staff_id === currentUser.companyStaffId ? (
              <ul className='list-group list-group-flush'>
                <li className='list-group-item'>
                  <strong>DIL Length</strong>: {staffmember.dil_length}
                </li>
                {isAdmin ? (
                  <Fragment>
                    <li className='list-group-item'>
                      <strong>O/T:</strong> {staffmember.overtime}
                    </li>
                    <li className='list-group-item'>
                      <strong>Discount:</strong> {staffmember.discount}%
                    </li>
                  </Fragment>
                ) : null}
              </ul>
            ) : null}
          </Card.Body>
          {isAdmin ? (
            <Card.Footer>
              <button
                onClick={this.handleConfirmStaffClick}
                type='button'
                className={
                  !staffmember.confirmed
                    ? 'btn btn-outline-success'
                    : 'btn btn-success'
                }
              >
                {!staffmember.confirmed ? 'Confirm' : 'Confirmed'}
              </button>{' '}
              <Button
                variant='danger'
                onClick={() => this.setState({ editModalShow: true })}
              >
                Edit
              </Button>{' '}
              <Button
                disabled={Object.values(staff).length < 2 ? 'disabled' : ''}
                variant='dark'
                onClick={this.handleDeleteStaffClick}
              >
                Delete
              </Button>
            </Card.Footer>
          ) : null}
        </Card>
        <EditActivityStaffModal
          show={this.state.editModalShow}
          onHide={() => this.setState({ editModalShow: false })}
          staffmember={staffmember}
        ></EditActivityStaffModal>
        <GenericModal
          show={this.state.genericModalShow}
          onHide={() => this.setState({ genericModalShow: false })}
          data={deleteActivityModalData}
        ></GenericModal>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteActivityStaffMemberStart: (staffId) =>
    dispatch(deleteActivityStaffMemberStart(staffId)),
  toggleConfirmStaffMember: (staffId, bool) =>
    dispatch(toggleConfirmStaffMember(staffId, bool)),
});

const mapStateToProps = createStructuredSelector({
  activityRoles: selectActivityRoles,
  isAdmin: selectIsAdmin,
  staff: selectActivityToEditStaff,
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityStaffCard);
