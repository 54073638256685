export const UserActionTypes = {
  SIGN_IN_START: "SIGN_IN_START",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  SIGN_OUT: "SIGN_OUT",
  GET_CURRENT_USER: "GET_CURRENT_USER",
  SET_MESSAGE: "SET_MESSAGE",
  CONFIRM_EMAIL: "CONFIRM_EMAIL",
  SET_DEFAULT_PAGE: "SET_DEFAULT_PAGE"
};
