import styled, { keyframes } from "styled-components";

const type = keyframes`
  from{
    box-shadow: inset -3px 0px 0px #888;
  }
  to{
    box-shadow: inset -3px 0px 0px transparent;
  }`;

export const MainDiv = styled.div`
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
`;

export const FofDiv = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

export const FofH1 = styled.h1`
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: ${type} 0.5s alternate infinite;
`;
