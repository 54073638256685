import React from "react";
import ReactDOM from "react-dom";

import Modal from "react-bootstrap/Modal";
import KitsForm from "../../forms/kit-form/kit-form.component";

const KitsListModal = props => {
  const { kit } = props;

  return ReactDOM.createPortal(
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {kit.name ? "Edit" : "Add"} Kit
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <KitsForm onHide={props.onHide} initialValues={kit} />
      </Modal.Body>
    </Modal>,
    document.querySelector("#modal")
  );
};

export default KitsListModal;
