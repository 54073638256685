import React from "react";
import uuid from "uuid";
import { getFileExtension } from "../../utils/data.utils";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import UploadedFile from "../uploaded-file/uploaded-file.component";

const UploadedFilesFlex = ({ files }) => {
  return (
    <Row>
      {files.map(file => {
        const fileExtension = getFileExtension(file.mime_type);
        file.fileExtension = fileExtension;
        return (
          <Col sm={2} md={3} key={uuid.v4()}>
            <UploadedFile key={uuid.v4()} file={file}></UploadedFile>
          </Col>
        );
      })}
    </Row>
  );
};

export default UploadedFilesFlex;
