import React, { Component } from "react";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { ChromePicker } from "react-color";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import TextInput from "../../form-inputs/text-input.component";
import SelectInput from "../../form-inputs/select-input.component";

import {
  selectActivityTypes,
  selectClients,
  selectTrucks,
  selectVenues
} from "../../../redux/list-data/list-data.selectors";
import { selectCompanyId } from "../../../redux/user/user.selectors";
import {
  addActivityTypeAsync,
  updateActivityTypeAsync
} from "../../../redux/list-data/list-data.actions";

class ActivityTypesForm extends Component {
  constructor() {
    super();
    this.state = {
      pickedColour: "#FFFFFF",
      colourToSave: "FFFFFF",
      dil_length: 0
    };
  }

  componentDidMount() {
    const { initialValues } = this.props;
    change("dil_length", this.state.dil_length);
    this.setState({
      pickedColour: initialValues.colour
        ? `#${initialValues.colour}`
        : "#FFFFFF",
      colourToSave: initialValues.colour ? `${initialValues.colour}` : "FFFFFF"
    });
  }

  handleColourChange = colour => {
    this.setState({
      pickedColour: colour.hex.toUpperCase(),
      colourToSave: colour.hex.replace("#", "").toUpperCase()
    });
  };

  onSubmit = formValues => {
    const { colourToSave } = this.state;
    const {
      addActivityTypeAsync,
      updateActivityTypeAsync,
      onHide,
      companyId,
      initialValues
    } = this.props;

    formValues.colour = colourToSave;
    formValues.company_id = companyId;

    if (!initialValues.name) {
      addActivityTypeAsync(formValues);
    } else {
      updateActivityTypeAsync(formValues);
    }
    onHide();
  };

  render() {
    const {
      handleSubmit,
      onHide,
      venues,
      clients,
      trucks,
      initialValues
    } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="name"
          component={TextInput}
          type="text"
          label={initialValues.name ? "Activity Type Name" : null}
          placeholder="Activity Type Name"
        />
        <Field
          label="DIL Length"
          name="dil_length"
          component={TextInput}
          type="number"
          step="0.25"
        />
        <Field
          name="default_client"
          component={SelectInput}
          options={Object.values(clients)}
          label={initialValues.name ? "Default Client" : null}
          placeholder="Default Client"
        ></Field>
        <Field
          name="default_venue"
          component={SelectInput}
          options={Object.values(venues)}
          label={initialValues.name ? "Default Venue" : null}
          placeholder="Default Venue"
        ></Field>
        <Field
          name="default_truck"
          component={SelectInput}
          options={Object.values(trucks)}
          label={initialValues.name ? "Default Truck" : null}
          placeholder="Default Truck"
        ></Field>
        <br />
        <ChromePicker
          color={this.state.pickedColour}
          onChange={this.handleColourChange}
        />
        <br />
        <Button type="submit" label="submit">
          Submit
        </Button>{" "}
        <Button type="button" variant="danger" onClick={onHide} label="submit">
          Cancel
        </Button>
      </Form>
    );
  }
}

const validate = formValues => {
  const errors = {};
  const requiredFields = ["name"];
  requiredFields.forEach(field => {
    if (!formValues[field]) {
      errors[field] = "Required";
    }
  });

  return errors;
};

const mapStateToProps = createStructuredSelector({
  activityTypes: selectActivityTypes,
  venues: selectVenues,
  trucks: selectTrucks,
  clients: selectClients,
  companyId: selectCompanyId
});

const mapDispatchToProps = dispatch => ({
  addActivityTypeAsync: formValues =>
    dispatch(addActivityTypeAsync(formValues)),
  updateActivityTypeAsync: formValues =>
    dispatch(updateActivityTypeAsync(formValues))
});

const formWrapper = reduxForm({
  form: "addToStaffList",
  validate
})(ActivityTypesForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(formWrapper);
