import { calendarActivityTypes } from "./calendar-types";
import { changeCurrentDate } from "../list-data/list-data.actions";
import { setSelectedDate } from "../settings/settings.actions";
import roster from "../../apis/roster";

export const fetchCalendarActivitiesStart = () => ({
  type: calendarActivityTypes.FETCH_CALENDAR_ACTIVITIES_START
});

export const fetchCalendarActivitiesSuccess = data => ({
  type: calendarActivityTypes.FETCH_CALENDAR_ACTIVITIES_SUCCESS,
  payload: data
});

export const fetchCalendarActivitiesFailure = errorMessage => ({
  type: calendarActivityTypes.FETCH_CALENDAR_ACTIVITIES_FAILURE,
  payload: errorMessage
});

export const fetchDashboardActivitiesAsync = (
  userId,
  selectedDate
) => dispatch => {
  dispatch(fetchCalendarActivitiesStart());
  dispatch(setSelectedDate(selectedDate));
  roster
    .post("/calendar", { userId, selectedDate })
    .then(res => {
      dispatch(fetchCalendarActivitiesSuccess(res.data.response.calendar));
    })
    .then(dispatch(changeCurrentDate(selectedDate)))
    .catch(error =>
      dispatch(fetchCalendarActivitiesFailure(error.response.data.message))
    );
};
