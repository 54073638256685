import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { timeFromSeconds } from '../../../utils/date.utils';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SelectStaffInput from '../../form-inputs/select-staff-input';
import TimePicker from 'react-bootstrap-time-picker';

import { selectStaff } from '../../../redux/list-data/list-data.selectors';
import { updateTogAsync } from '../../../redux/list-data/list-data.actions';

class TogBookingForm extends Component {
  constructor() {
    super();
    this.state = { time: 0 };
  }
  onSubmit = formValues => {
    const { id, in_use_by, name } = formValues;
    const { onHide, updateTog } = this.props;
    const { time } = this.state;
    updateTog(id, in_use_by, time, name);
    onHide();
  };

  componentDidMount() {
    const {
      initialValues: { in_use_to },
    } = this.props;
    if (in_use_to) {
      this.setState({ time: in_use_to });
    }
  }

  handleTimeChange = time => {
    this.setState({ time });
  };

  render() {
    const { handleSubmit, onHide, staff } = this.props;

    const formattedTime = timeFromSeconds(this.state.time);
    return (
      <Form onSubmit={handleSubmit(this.onSubmit)}>
        <Form.Row>
          <Field
            name="in_use_by"
            label="In Use By"
            component={SelectStaffInput}
            options={Object.values(staff)}
            placeholder="Select Staff"
          />
        </Form.Row>
        <Form.Row>
          <TimePicker
            name="in_use_to"
            start="06:00"
            end="21:00"
            onChange={this.handleTimeChange}
            step={30}
            value={formattedTime}
            format={24}
          />
        </Form.Row>
        <br />
        <Button type="submit" label="submit">
          Submit
        </Button>{' '}
        <Button type="button" variant="danger" onClick={onHide} label="submit">
          Cancel
        </Button>
      </Form>
    );
  }
}

const validate = formValues => {
  const errors = {};
  const requiredFields = ['in_use_by'];
  requiredFields.forEach(field => {
    if (!formValues[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
};

const mapStateToProps = createStructuredSelector({
  staff: selectStaff,
});

const mapDispatchToProps = dispatch => ({
  updateTog: (id, in_use_by, in_use_to, name) =>
    dispatch(updateTogAsync(id, in_use_by, in_use_to, name)),
});

const formWrapper = reduxForm({
  form: 'togBookingForm',
  validate,
})(TogBookingForm);

export default connect(mapStateToProps, mapDispatchToProps)(formWrapper);
