import { createSelector } from 'reselect';

const selectListData = (state) => state.listData;

export const selectActivityRoles = createSelector(
  [selectListData],
  (listData) => listData.roles,
);

export const selectActivityTypes = createSelector(
  [selectListData],
  (listData) => listData.activityTypes,
);

export const selectClients = createSelector(
  [selectListData],
  (listData) => listData.clients,
);

export const selectVenues = createSelector(
  [selectListData],
  (listData) => listData.venues,
);

export const selectTrucks = createSelector(
  [selectListData],
  (listData) => listData.trucks,
);

export const selectKits = createSelector(
  [selectListData],
  (listData) => listData.kits,
);

export const selectLicenses = createSelector(
  [selectListData],
  (listData) => listData.licenses,
);

export const selectRoles = createSelector(
  [selectListData],
  (listData) => listData.roles,
);

export const selectStaff = createSelector(
  [selectListData],
  (listData) => listData.staff,
);

export const selectCurrentDate = createSelector(
  [selectListData],
  (listData) => listData.currentDate,
);

export const selectIListDataFetching = createSelector(
  [selectListData],
  (listData) => listData.isFetching,
);

export const selectIsListDataLoaded = createSelector(
  [selectListData],
  (listData) => {
    return (
      Object.entries(listData.staff).length > 0 &&
      listData.staff.constructor === Object
    );
  },
);

export const selectRosterRoles = createSelector(
  [selectListData],
  (listData) => listData.rosterRoles,
);

export const selectPositions = createSelector(
  [selectListData],
  (listData) => listData.positions,
);

export const selectActive = createSelector(
  [selectListData],
  (listData) => listData.active,
);

export const selectRefresh = createSelector(
  [selectListData],
  (listData) => listData.refresh,
);

export const selectDefaultPages = createSelector(
  [selectListData],
  (listData) => listData.defaultPages,
);

export const selectTogs = createSelector(
  [selectListData],
  (listData) => listData.togs,
);
