export const rosterActionTypes = {
  SET_DATE_RANGE: "SIGN_IN_START",
  FETCH_ROSTER_ACTIVITIES: "FETCH_ROSTER_ACTIVITIES",
  FETCH_ROSTER_START: "FETCH_ROSTER_START",
  FETCH_ROSTER_SUCCESS: "FETCH_ROSTER_SUCCESS",
  FETCH_ROSTER_FAILURE: "FETCH_ROSTER_FAILURE",
  SET_ROSTER_HEADER_VISIBLE: "SET_ROSTER_HEADER_VISIBLE",
  SAVE_ACTIVITY: "SAVE_ACTIVITY",
  DELETE_ACTIVITY: "DELETE_ACTIVITY",
  DELETE_STAFF_FROM_ACTIVITY: "DELETE_STAFF_FROM_ACTIVITY",
  SET_MESSAGE: "SET_MESSAGE",
  CLEAR_MESSAGE: "CLEAR_MESSAGE"
};
