import React, { Component } from "react";

import TrucksTable from "../../components/tables/trucks-table/trucks-table.components";
class TrucksListPage extends Component {
  render() {
    return <TrucksTable />;
  }
}

export default TrucksListPage;
