import { UserActionTypes } from './user.types';
import { setMessage, setErrorMessage } from '../message/message.actions';
import roster from '../../apis/roster';
import jwt from 'jsonwebtoken';
import history from '../../history';
import { persistor } from '../store';
import Cookies from 'js-cookie';

export const signOut = () => ({
  type: UserActionTypes.SIGN_OUT,
});

export const signInStart = () => ({
  type: UserActionTypes.SIGN_IN_START,
});

export const signInSuccess = data => dispatch => {
  dispatch({
    type: UserActionTypes.SIGN_IN_SUCCESS,
    payload: data,
  });
};

export const signInFailure = errorMessage => dispatch => {
  dispatch({
    type: UserActionTypes.SIGN_IN_FAILURE,
  });
  dispatch(setErrorMessage(errorMessage));
};

export const confirmUserEmail = () => dispatch => {
  dispatch({
    type: UserActionTypes.CONFIRM_EMAIL,
  });
};

export const updateDefaultPage = defaultPage => dispatch => {
  dispatch({
    type: UserActionTypes.SET_DEFAULT_PAGE,
    payload: defaultPage,
  });
};

export const signInAsync = credentials => {
  return dispatch => {
    dispatch(signInStart());
    roster
      .post('/auth', {
        credentials,
      })
      .then(res => {
        const { token, refreshToken } = res.data.response;
        const decoded = jwt.decode(token, process.env.REACT_APP_JWT_SECRET);
        decoded.token = token;
        decoded.refreshToken = refreshToken;
        dispatch(signInSuccess(decoded));
      })
      .catch(error => {
        dispatch(signInFailure(error.response.data.message));
      });
  };
};

export const signOutAsync = () => (dispatch, getState) => {
  const { refreshToken } = getState().user.currentUser;
  roster.post('/auth/token/reject', { refreshToken: refreshToken });

  dispatch(signOut());
  localStorage.removeItem('persist:root');
  persistor.flush();
  history.push('/');
};

export const requestRestPassword = formValues => {
  return dispatch => {
    const { email_address } = formValues;
    roster
      .post('/auth/reset_password_request', { email_address })
      .then(res => dispatch(setMessage(res.data.response.message)))
      .catch(error => {
        dispatch(signInFailure(error.response.data.message));
      });
  };
};

export const resetPassword = (formValues, token) => {
  return dispatch => {
    const { new_password } = formValues;
    roster
      .put('/auth/reset_password', { new_password, token })
      .then(res => {
        dispatch(setMessage(res.data.response.message));
      })
      .catch(error => dispatch(signInFailure(error.response.data.message)));
  };
};

export const generateConfirmEmailToken = () => (dispatch, getState) => {
  const { currentUser } = getState().user;
  roster
    .post(`/people/generateConfirmToken/${currentUser.id}`)
    .then(res => dispatch(setMessage(res.data.response.message)))
    .catch(error => dispatch(signInFailure(error.response.data.message)));
};

export const validateAndConfirmEmail = token => (dispatch, getState) => {
  roster
    .post('/auth/validate_token', { token: token })
    .then(res => {
      // localStorage.clear("persist:root");
      dispatch(confirmUserEmail());
      dispatch(setMessage(res.data.response.message));
    })
    .catch(error => dispatch(signInFailure(error.response.data.message)));
};

export const refreshAccessToken = () => (dispatch, getState) => {
  const {
    currentUser: { email, refreshToken },
  } = getState().user;
  roster
    .post('/auth/token', { email: email, refreshToken: refreshToken })
    .then(res => console.log(res));
};

export const checkAccessToken = () => (dispatch, getState) => {
  const accessToken = Cookies.get('roster');
  console.log(accessToken);
};
