import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import TextInput from "../../form-inputs/text-input.component";
import SelectStaffInput from "../../form-inputs/select-staff-input";
import SelectRoleInput from "../../form-inputs/select-role-input";

import {
  selectRoles,
  selectStaff
} from "../../../redux/list-data/list-data.selectors";
import {
  addStaffToActivity,
  replaceActivityStaffMemberStart
} from "../../../redux/edit-activity/edit-activity.actions";

class StaffModalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfirmed: false
    };
  }

  componentDidMount() {
    if (this.props.initialValues) {
      this.setState({
        isConfirmed: !!this.props.initialValues.confirmed
      });
    }
  }

  onSubmit = formValues => {
    const {
      onHide,
      staff,
      roles,
      addStaffToActivity,
      replaceActivityStaffMemberStart,
      initialValues
    } = this.props;

    const staffMember = {
      company_staff_id: formValues.company_staff_id,
      role: formValues.role,
      overtime: formValues.overtime ? formValues.overtime : 0,
      discount: formValues.discount ? formValues.discount : 0,
      dil_length: formValues.dil_length ? formValues.dil_length : 0,
      confirmed: this.state.isConfirmed,
      first_name: staff[formValues.company_staff_id].first_name,
      last_name: staff[formValues.company_staff_id].last_name,
      email_address: staff[formValues.company_staff_id].email_address,
      role_name: roles[formValues.role].name
    };

    if (!initialValues) {
      addStaffToActivity(staffMember);
    } else {
      replaceActivityStaffMemberStart(
        initialValues.company_staff_id,
        staffMember
      );
    }
    onHide();
  };

  render() {
    const { handleSubmit, currentstaff, initialValues } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.onSubmit)}>
        <Form.Row>
          <Col>
            <Field
              name="company_staff_id"
              label={initialValues ? "Name" : null}
              placeholder="Select Staff"
              component={SelectStaffInput}
              currentstaff={currentstaff}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Field
              name="role"
              label={initialValues ? "Role" : null}
              placeholder="Select Role"
              component={SelectRoleInput}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Field
              parse={value => Number(value)}
              type="number"
              step="0.25"
              name="dil_length"
              label={initialValues ? "DIL Length" : null}
              placeholder="0"
              component={TextInput}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Field
              parse={value => Number(value)}
              type="number"
              name="discount"
              label={initialValues ? "Discount" : null}
              placeholder="0"
              component={TextInput}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Field
              parse={value => Number(value)}
              type="number"
              name="overtime"
              label={initialValues ? "Overtime" : null}
              step="0.5"
              placeholder="0"
              component={TextInput}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Button
              type="button"
              variant={
                this.state.isConfirmed === true ? "success" : "outline-success"
              }
              onClick={() =>
                this.setState({ isConfirmed: !this.state.isConfirmed })
              }
            >
              {this.state.isConfirmed === true ? "Confirmed" : "Confirm"}
            </Button>
          </Col>
        </Form.Row>
        <br />
        <Form.Row>
          <Col>
            <Button onClick={handleSubmit(this.onSubmit)} variant="primary">
              Update
            </Button>{" "}
            <Button onClick={() => this.props.onHide()} variant="danger">
              Cancel
            </Button>
          </Col>
        </Form.Row>
      </Form>
    );
  }
}

const validate = formValues => {
  const errors = {};

  const requiredFields = ["company_staff_id", "role"];
  requiredFields.forEach(field => {
    if (!formValues[field]) {
      errors[field] = "Required";
    }
    if (formValues["dil_length"] === "") {
      errors["dil_length"] = "Required";
    }
  });
  return errors;
};

const mapStateToProps = createStructuredSelector({
  roles: selectRoles,
  staff: selectStaff
});

const mapDispatchToProps = dispatch => ({
  addStaffToActivity: staffMember => dispatch(addStaffToActivity(staffMember)),
  replaceActivityStaffMemberStart: (oldId, formValues) =>
    dispatch(replaceActivityStaffMemberStart(oldId, formValues))
});

const formWrapper = reduxForm({ form: "staffModalForm", validate })(
  StaffModalForm
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(formWrapper);
