import React from "react";
import ReactDOM from "react-dom";

import Modal from "react-bootstrap/Modal";
import StaffListForm from "../../forms/staff-list-form/staff-list-component";

const StaffListModal = props => {
  const { staff } = props;
  return ReactDOM.createPortal(
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {Object.getOwnPropertyNames(staff).length !== 0 ? "Edit" : "Add"}{" "}
          Staff
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StaffListForm
          currentstaff={props.currentstaff}
          onHide={props.onHide}
          initialValues={staff}
        ></StaffListForm>
      </Modal.Body>
    </Modal>,
    document.querySelector("#modal")
  );
};

export default StaffListModal;
