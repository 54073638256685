import React, { useEffect, Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import DashboardPersonForm from '../../components/forms/dashboard-person/dashboard-person-form.component';
import DashBoardCalendar from '../../components/dashboard-calendar/dashboard-calendar.component';
import WithSpinner from '../../components/spinner/spinner.component';

import { fetchListDataStartAsync } from '../../redux/list-data/list-data.actions';
import { selectStaff } from '../../redux/list-data/list-data.selectors';
import { selectIListDataFetching } from '../../redux/list-data/list-data.selectors';

class DashboardPage extends Component {
  componentDidMount = async () => {
    await this.props.fetchListDataStartAsync(1);
  };

  render() {
    const { match, staff, isLoading, fetchListDataStartAsync } = this.props;

    if (isLoading) {
      return <WithSpinner message='Loading Dashboard...' />;
    } else {
      const staffMember = staff[match.params.company_staff_id];
      return (
        <div className=''>
          <DashboardPersonForm initialValues={staffMember} />
          <br />
          <DashBoardCalendar></DashBoardCalendar>
        </div>
      );
    }
  }
}

const mapStateToProps = createStructuredSelector({
  staff: selectStaff,
  isLoading: selectIListDataFetching,
});

const mapDispatchToProps = (dispatch) => ({
  fetchListDataStartAsync: (companyId) =>
    dispatch(fetchListDataStartAsync(companyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
