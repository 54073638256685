import React, { Component } from "react";
import uuid from "uuid";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import { selectRoles } from "../../redux/list-data/list-data.selectors";

class SelectRoleInput extends Component {
  render() {
    const { input, label, meta, placeholder, roles, disabled } = this.props;
    const errorClass = meta.touched && meta.error ? "is-invalid" : "";
    const labelClass = meta.touched && meta.error ? "text-danger" : "";

    return (
      <Form.Group as={Col} controlId={input.name}>
        <Form.Label className={labelClass}>{label}</Form.Label>
        <Form.Control
          {...input}
          as="select"
          className={errorClass}
          disabled={disabled}
        >
          <option>{placeholder}</option>
          {Object.values(roles).map(option => {
            return (
              <option key={uuid.v4()} value={option.id}>
                {option.name}
              </option>
            );
          })}
        </Form.Control>
        {meta.touched &&
          (meta.error && <span className="text-danger">{meta.error}</span>)}
      </Form.Group>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  roles: selectRoles
});

export default connect(
  mapStateToProps,
  {}
)(SelectRoleInput);
