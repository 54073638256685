import React, { Component, Fragment } from "react";
import { ContextMenuTrigger, ContextMenu, MenuItem } from "react-contextmenu";
import { createStructuredSelector } from "reselect";
import uuid from "uuid";
import { connect } from "react-redux";
import { faSortDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import { getShortFormattedDate } from "../../utils/date.utils";
import history from "../../history";

import { selectIsAdmin } from "../../redux/user/user.selectors";

import Icon from "../icon/icon.component";

class DayContextMenu extends Component {
  handleAddActivityClick = (e, data) => {
    const { date, staff } = data;
    history.push(`/add_activity/${date}/${staff}`);
  };

  render() {
    const { date, staff, isAdmin } = this.props;
    const menuId = uuid.v4();
    return (
      <Fragment>
        {isAdmin ? (
          <Fragment>
            <ContextMenuTrigger id={menuId} key={uuid.v4()}>
              <div className="well">
                <Fragment>
                  <span
                    style={{
                      border: "1px #dbdbdb solid",
                      width: "%100%",
                      display: "block"
                    }}
                  >
                    <Icon icon={faSortDown} style={{ visibility: "hidden" }} />
                  </span>
                </Fragment>
              </div>
            </ContextMenuTrigger>
            <ContextMenu id={menuId} key={uuid.v4()}>
              <MenuItem
                key={uuid.v4()}
                onClick={this.handleAddActivityClick}
                data={{
                  date: getShortFormattedDate(date),
                  staff: staff
                }}
              >
                <Icon icon={faPlus} /> Add Activity
              </MenuItem>
            </ContextMenu>
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isAdmin: selectIsAdmin
});

export default connect(
  mapStateToProps,
  {}
)(DayContextMenu);
