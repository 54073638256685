import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

const TextareaInput = ({
  input,
  type,
  label,
  meta,
  placeholder,
  required,
  disabled
}) => {
  const errorClass = meta.touched && meta.error ? "is-invalid" : "";
  const labelClass = meta.touched && meta.error ? "text-danger" : "";

  return (
    <Form.Group as={Col} md="12">
      <Form.Label className={labelClass}>{label}</Form.Label>

      <Form.Control
        {...input}
        disabled={disabled}
        placeholder={placeholder}
        required={required}
        autoComplete="off"
        className={errorClass}
        as="textarea"
        rows="3"
      />

      {meta.touched &&
        (meta.error && <span className="text-danger">{meta.error}</span>)}
    </Form.Group>
  );
};

export default TextareaInput;
