import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-bootstrap/Modal";

import ActivityStaffForm from "../../forms/activity-staff-from/activity-staff-form.component";

const EditActivityStaffModal = props => {
  const { staffmember } = props;

  return ReactDOM.createPortal(
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit {staffmember.first_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ActivityStaffForm onHide={props.onHide} initialValues={staffmember} />
      </Modal.Body>
    </Modal>,
    document.querySelector("#modal")
  );
};

export default EditActivityStaffModal;
