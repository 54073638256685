export const getFileExtension = fileType => {
  let extension;
  if (fileType === "image/png") {
    extension = "png";
  } else if (fileType === "image/jpeg") {
    extension = "jpg";
  } else if (fileType === "application/pdf") {
    extension = "pdf";
  } else if (
    fileType ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    extension = "docx";
  } else if (fileType === "application/msword") {
    extension = "doc";
  }
  return extension;
};
