import axios from 'axios';

console.log(process.env.NODE_ENV);
const rootData = JSON.parse(localStorage.getItem('persist:root'));
const userData = JSON.parse(rootData.user);
const { admin, token } = userData.currentUser;

let baseURL = process.env.REACT_APP_DEV_API_URL; // "http://192.168.48.131:5000/api/";
if (process.env.NODE_ENV === 'production') {
  baseURL = process.env.REACT_APP_PROD_API_URL; // "https://roster.nz.ae.live/api";
}

// console.log(baseURL);
const defaultOptions = {
  baseURL, //baseURL: "http://192.168.48.131:5000/api/",
  withCredentials: true,
  crossdomain: true,
};

let instance = axios.create(defaultOptions);

// Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
  config.headers.Authorization = token ? token : '';
  return config;
});

if (rootData) {
  // Set admin header for any request
  instance.interceptors.request.use(function (config) {
    config.headers.IsAdmin = admin ? admin : '';
    return config;
  });
}

export default instance;
