import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import uuid from "uuid";

import Container from "react-bootstrap/Container";
import CardColumns from "react-bootstrap/CardColumns";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ActivityStaffCard from "../../components/activity-staff-card/activity-staff-card.component";
import ActivityForm from "../../components/forms/activity-form/activity-form.component";
import ActivityStaffModal from "../../components/modals/activity-staff/activity-staff-modal.component";
import UploadedFilesFlex from "../../components/uploaded-files-flex/uploaded-files-flex.component";
import UploadPond from "../../components/upload-pond/upload-pond.component";

import {
  fetchEditActivityStartAsync,
  updateFiles
} from "../../redux/edit-activity/edit-activity.actions";
import {
  selectActivityToEditStaff,
  selectEditActivity,
  selectFiles
} from "../../redux/edit-activity/edit-activity.selectors";
import { selectIsAdmin } from "../../redux/user/user.selectors";

class EditActivityPage extends Component {
  constructor() {
    super();
    this.state = {
      modalShow: false,
      files: []
    };
  }

  componentDidMount = () => {
    const {
      fetchEditActivityStartAsync,
      match: { params }
    } = this.props;

    fetchEditActivityStartAsync(params.activityId);
  };

  render() {
    const { staff, activity, isAdmin, files } = this.props;
    const mappedStaff = Object.values(staff);
    //const mappedFiles = Object.values(localFiles);

    let modalClose = () => this.setState({ modalShow: false });

    return (
      <Container>
        <Row>
          <Col>
            <h2>Edit Activity - {activity.name}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <ActivityForm initialValues={activity}></ActivityForm>
          </Col>
        </Row>

        <UploadedFilesFlex files={Object.values(files)}></UploadedFilesFlex>

        <br />
        {isAdmin ? <UploadPond activity={activity}></UploadPond> : null}

        <br />
        {isAdmin ? (
          <Button
            onClick={() => this.setState({ modalShow: true })}
            variant="dark"
          >
            Add Staff
          </Button>
        ) : null}
        <br />
        <Row>
          <Col>
            <br />
            <CardColumns>
              {mappedStaff.map(staffmember => (
                <ActivityStaffCard
                  key={uuid.v4()}
                  staffmember={staffmember}
                ></ActivityStaffCard>
              ))}
            </CardColumns>
          </Col>
        </Row>
        {mappedStaff.length < 2 ? (
          <Alert ley={uuid.v4()} variant="light">
            *There has to be a minimum of 1 staff member assigned to an
            activity.
          </Alert>
        ) : null}
        <ActivityStaffModal
          show={this.state.modalShow}
          onHide={modalClose}
          currentstaff={this.props.staff}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchEditActivityStartAsync: activityId =>
    dispatch(fetchEditActivityStartAsync(activityId)),
  updateFiles: fileItems => dispatch(updateFiles(fileItems))
});

const mapStateToProps = createStructuredSelector({
  staff: selectActivityToEditStaff,
  activity: selectEditActivity,
  isAdmin: selectIsAdmin,
  files: selectFiles
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditActivityPage)
);
